import { messaging } from './initFirebase';
import notificationService from '../services/notificationService';
//import { getLinkOrPath } from '../utils/routeUtils';

export const requestNotificationPermission = () => {
  if (!messaging) {
    return null;
  }
  if (!('Notification' in window)) {
    console.log('This browser does not support desktop notification');
  }
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      receivePushToken();
    } else if (
      Notification.permission !== 'denied' ||
      Notification.permission === 'default'
    ) {
      Notification.requestPermission(function (permission) {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          receivePushToken();
        }
      });
    } else {
      console.log('Unable to get permission to notify.');
    }
  });
};

function receivePushToken() {
  if (isTokenSentToServer()) {
    console.log('Token Already Sent');
  } else {
    getPushToken();
  }
}
function getPushToken() {
  if (!messaging) {
    return null;
  }
  messaging
    .getToken()
    .then((currentToken) => {
      if (currentToken) {
        sendTokenToServer(currentToken);
      } else {
        // Show permission request.
        console.log(
          'No Instance ID token available. Request permission to generate one.'
        );
        setTokenSentToServer(false);
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
      setTokenSentToServer(false);
    });
}
function setTokenSentToServer(sent) {
  localStorage.setItem('sentToServer', sent ? '1' : '0');
}
async function sendTokenToServer(currentToken) {
  if (!isTokenSentToServer()) {
    const response = await notificationService.savePushNotificationToken(
      currentToken
    );
    if (response) setTokenSentToServer(true);
  } else {
    console.log(
      "Token already sent to server so won't send it again " +
        'unless it changes'
    );
  }
}
function isTokenSentToServer() {
  return localStorage.getItem('sentToServer') === '1';
}
function onMessageLoad() {
  if (!messaging) {
    return null;
  }
  messaging.onMessage((payload) => {
    console.log('Message received. ', payload);
    const defaultNotificationObj = {
      body: 'SHEROES',
      title: 'SHEROES',
      message: 'Welcome From SHEROES',
      icon:
        'https://img.sheroes.in/img/uploads/sheroespage/logoes/1543554988she-logo-new.png?tr=w-60,h-60,fo-auto',
      tag: 'https://sheroes.com/feed',
      deep_link_url: 'https://sheroes.com/feed',
    };
    const data = payload.data ? payload.data : defaultNotificationObj;
    const title = data.title ? data.title : 'SHEROES';
    const linkUrl = data.deep_link_url;
    if (!title || title === '') {
      return;
    }
    //const path = getLinkOrPath(data.deep_link_url);
    const path = linkUrl || 'https://sheroes.com/feed';

    const notificationOptions = {
      body: data.message ? data.message : 'Welcome to SHEROES',
      icon: data.icon,
      requireInteraction: true,
      tag: path,
    };
    console.log('notificationOptions', notificationOptions);
    var myNotification = new Notification(title, notificationOptions);
    console.log('myNotification:', myNotification);

    myNotification.onclick = function (event) {
      event.preventDefault(); //prevent the browser from focusing the Notification's tab
      window.open(path);
      //event.waitUntil(clients.openWindow(payload.data.deep_link_url));
      myNotification.close();
    };
  });
}

// Callback fired if Instance ID token is updated.
function messageTokeRefresh() {
  if (!messaging) {
    return null;
  }
  messaging.onTokenRefresh(() => {
    messaging
      .getToken()
      .then((refreshedToken) => {
        console.log('Token refreshed.');
        // Indicate that the new Instance ID token has not yet been sent to the
        // app server.
        setTokenSentToServer(false);
        // Send Instance ID token to app server.
        sendTokenToServer(refreshedToken);
      })
      .catch((err) => {
        console.log('Unable to retrieve refreshed token ', err);
        //showToken('Unable to retrieve refreshed token ', err);
      });
  });
}

onMessageLoad();
messageTokeRefresh();

export default {
  getPushToken,
  requestNotificationPermission,
};
