import { lazyPreload } from '../utils/lazyUtil';
const CreatePost = lazyPreload(() => import('../scenes/createpost'));
const PostDetail = lazyPreload(() => import('../scenes/postDetail'));
const ArticleDetail = lazyPreload(() => import('../scenes/articleDetail'));

const routes = [
  {
    path: '/create-post',
    component: CreatePost,
  },
  {
    path: '/edit-post',
    component: CreatePost,
  },
  {
    path: '/communities/:communityNameSlug/:communityId/polls/:postId',
    component: PostDetail,
  },
  {
    path: '/stories/:articleTitleSlug/:articleId',
    component: ArticleDetail,
  },
];
export default routes;
