import React, { useState } from 'react';
import {
  BottomNavigation,
  Box,
  BottomNavigationAction,
} from '@material-ui/core';
import SearchIcon from '../img/fill-19.svg';
import HomeIcon from '../img/home.svg';
import ActiveHomeIcon from '../img/home-active.svg';
import ActiveNotificationsIcon from '../img/notifications-active.svg';
import ActiveSearchIcon from '../img/search-active.svg';
import ActiveMenuIcon from '../img/menu-active.svg';
import CreatePostIcon from '../img/add.svg';
import NotificationsIcon from '../img/notifications.svg';
import ReadNotificationsIcon from '../img/read-notifications.svg';
import MenuIcon from '../img/more.svg';
import { useLoggedInUser } from '../hooks/userHooks';
import AdapterLink from './common/adapterLink';
import { useFetchData } from '../hooks/fetchDataHooks';
import notificationService from '../services/notificationService';

const BottomNavbar = ({ handleBackNavigation, options }) => {
  const [value, setValue] = useState(options.activeTab);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const user = useLoggedInUser();

  const { data: notificationsCount } = useFetchData(
    user && notificationService.getNotificationCount(),
    0
  );

  const menuItems = [
    {
      key: 'feed',
      to: '/',
      activeIcon: ActiveHomeIcon,
      inactiveIcon: HomeIcon,
    },
    {
      key: 'search',
      to: '/search',
      activeIcon: ActiveSearchIcon,
      inactiveIcon: SearchIcon,
    },
    {
      key: 'createpost',
      to: '/create-post',
      activeIcon: CreatePostIcon,
      inactiveIcon: CreatePostIcon,
    },
    {
      key: 'notifications',
      to: '/notifications',
      activeIcon: ActiveNotificationsIcon,
      inactiveIcon:
        notificationsCount > 0 ? NotificationsIcon : ReadNotificationsIcon,
    },
    {
      key: 'menu',
      to: '/menu',
      activeIcon: ActiveMenuIcon,
      inactiveIcon: MenuIcon,
    },
  ];
  return (
    <Box position="fixed" width={1} className="stickToBottom">
      <BottomNavigation value={value} onChange={handleChange} showLabels>
        {menuItems.map(({ key, to, activeIcon, inactiveIcon }) => (
          <BottomNavigationAction
            className="bottomNavigationAction"
            key={key}
            value={key}
            to={to}
            component={AdapterLink}
            icon={
              <img
                src={options.activeTab === key ? activeIcon : inactiveIcon}
                alt=""
              />
            }
          />
        ))}
      </BottomNavigation>
    </Box>
  );
};

export default BottomNavbar;
