import feedStore from './feedStore';
import userStore from './userStore';
import navBarStore from './navBarStore';
import snackBarStore from './snackBarStore';
import dialogStore from './dialogBoxStore';
import spamStore from './spamStore';
import postStore from './postStore';
import communityStore from './communityStore';
import createPostStore from './createPostStore';

const globalState = {
  user: userStore,
  navBar: navBarStore,
  posts: postStore,
  snackBar: snackBarStore,
  dialogBox: dialogStore,
  reportSpam: spamStore,
  feed: feedStore,
  community: communityStore,
  createPost: createPostStore,
};

export default globalState;
