import * as firebase from 'firebase/app';
import 'firebase/messaging';
import { firebaseConfig } from './constants';
const initializedFirebaseApp = firebase.initializeApp(firebaseConfig);
var messaging;

if (firebase.messaging.isSupported()) {
  messaging = initializedFirebaseApp.messaging();
}
export { messaging };
