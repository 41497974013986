import { useState } from 'react';
import { useLastLocation } from 'react-router-last-location';
import { useStoreActions, useStoreState } from 'easy-peasy';
import useReactRouter from 'use-react-router';
import { postToFollowerObj } from '../mappers/communityMapper';
import { useCreateOrEditPostRequest } from '../hooks/createOrEditPostRequest';

function useCreatePost() {
  const { history } = useReactRouter();
  const post =
    history.location.state && history.location.state.postStr
      ? JSON.parse(history.location.state.postStr)
      : '';
  const fromScreen = history.location.state
    ? history.location.state.fromScreen
    : '';
  let {
    buildRequestBody,
    handleUserAnonymity,
    selectedTheme,
    isThemePost,
    validateCreatePost,
    files,
    setErrorMessage,
    createPostAnalyticsTrack,
    handleDeleteItem,
    handleSelectCommunity,
    handleSetPostDescription,
    handleSetUserMention,
    handleSetEditorState,
    renderLinkUrl,
    userMentions,
  } = useCreateOrEditPostRequest(post);
  const isEditablePost = post ? true : false;
  const isSchedulePost = post && post.isSchedulePost ? true : false;
  const lastLocation = useLastLocation();
  const savePost = useStoreActions((actions) => actions.posts.savePost);
  const { setDialogBoxOpen } = useStoreActions((actions) => actions.dialogBox);
  let {
    communityId,
    communityName,
    postDescription,
    postCreatorType,
    deletedImageIds,
    isPostCreatorChange,
    isFromCommunityDetails,
    selectedCommunity,
  } = useStoreState((state) => state.createPost.postObject);
  const {
    setIsThemePost,
    setFiles,
    setDeletedImageIds,
    resetCreatePostObject,
  } = useStoreActions((state) => state.createPost);

  if (post) {
    communityId = post.communityId;
    communityName =
      post.communityId === postToFollowerObj[0].id
        ? postToFollowerObj[0].name
        : post.communityName;
    selectedCommunity = { id: communityId, name: communityName };
  }
  if (post && post.description && !postDescription) {
    postDescription = post.description;
  }
  if (
    post &&
    post.images &&
    files.length === 0 &&
    deletedImageIds.length === 0
  ) {
    files = post.images;
  }

  if (files.length > 0) {
    isThemePost = false;
  }
  const [loading, setLoading] = useState(false);
  postCreatorType =
    !isPostCreatorChange && post ? post.author.name : postCreatorType;

  //submit form
  async function handleSavePost({ scheduleDate }) {
    let createPostRequestBody = buildRequestBody({
      scheduleDate,
      post,
      selectedTheme,
      isThemePost,
      selectedCommunity,
      postCreatorType,
      deletedImageIds,
      userMentions,
    });
    if (!validateCreatePost(selectedCommunity, files, postDescription)) {
      return;
    }
    createPostAnalyticsTrack({
      selectedCommunity,
      postDescription,
      postCreatorType,
      createPostRequestBody,
    });
    setLoading(true);
    await savePost({
      createPostRequestBody,
      files,
      history,
      isEditablePost,
      setErrorMessage,
      lastLocation,
      fromScreen,
    });
    setLoading(false);
    setDialogBoxOpen(false);
    resetCreatePostObject();
  }
  const postObj = {
    communityId,
    communityName,
    selectedCommunity,
    postCreatorType,
    handleUserAnonymity,
    handleSelectCommunity,
    handleSavePost,
    handleSetPostDescription,
    postDescription,
    setFiles,
    files,
    deletedImageIds,
    setDeletedImageIds,
    loading,
    setIsThemePost,
    isThemePost,
    selectedTheme,
    isEditablePost,
    handleDeleteItem,
    isSchedulePost,
    resetCreatePostObject,
    isFromCommunityDetails,
    userMentions,
    handleSetUserMention,
    handleSetEditorState,
    renderLinkUrl,
  };
  return postObj;
}

function useThemePost({ files, selectedTheme, postDescription }) {
  const { checkThemePostStatus, setHandleThemeChange } = useStoreActions(
    (state) => state.createPost
  );
  function handleThemeChange(e, selectedThemeObj) {
    setHandleThemeChange({ e, selectedThemeObj });
    checkThemePostStatus({ files, selectedTheme, postDescription });
  }
  return {
    handleThemeChange,
  };
}
export { useCreatePost, useThemePost };
