import React from 'react';
import { lazyPreload } from '../utils/lazyUtil';

const Notifications = lazyPreload(() => import('../scenes/notifications'));
const FeedScene = lazyPreload(() => import('../scenes/feedScene'));
const Communities = lazyPreload(() => import('../scenes/communities'));
const CommunitiesByCategory = lazyPreload(() =>
  import('../scenes/communitiesByCategory')
);
const MyCommunities = lazyPreload(() => import('../scenes/myCommunities'));
const UserProfile = lazyPreload(() => import('../scenes/userProfile'));
const EditProfile = lazyPreload(() => import('../scenes/editProfile'));
const Search = lazyPreload(() => import('../scenes/search'));

const routes = [
  {
    path: '/feed',
    render: (props) => <FeedScene {...props} setOrderName="Feed" />,
  },
  {
    path: '/communities/interests/:categoryId',
    component: CommunitiesByCategory,
  },
  {
    path: '/notifications',
    component: Notifications,
  },
  {
    path: '/communities',
    component: Communities,
  },
  {
    path: '/my-communities',
    component: MyCommunities,
  },
  {
    path: '/users/:userName/:userId',
    component: UserProfile,
  },
  {
    path: '/champions/:userName/:userId',
    component: UserProfile,
  },
  {
    path: '/users/edit-profile',
    component: EditProfile,
  },
  {
    path: '/search',
    component: Search,
  },
];
export default routes;
