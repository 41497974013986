import { postMap, basePostMap } from './modelMapper';
import { getSlug } from '../utils/textUtil';
import { defaultArticleCategory } from '../utils/constants';
import { getArticleStreamApi, getCategoryUrl } from '../utils/postUtil';

function similarContentMap(data) {
  return data && data.map((list) => postMap(list));
}

function articleMap(doc) {
  const categoryUrlRequest = {
    value: doc.article_category_l,
    label: doc.article_category_name_s,
  };
  const articleObj = {
    charCount: doc.char_count_i,
    title: doc.name,
    tags: doc.tag_names && doc.tag_names.length && doc.tag_names[0],
    articleImageUrl: doc['image_url'],
    createdAtStr: doc.p_crdt,
    modifiedAtStr: doc.p_last_modified_on,
    metaDescription: doc.meta_description_s,
    articleCategoryName: doc.article_category_name_s,
    articleCategoryUrl: getCategoryUrl(categoryUrlRequest),
    articleCategoryDateUrl: getArticleStreamApi(doc.article_category_l),
    ampUrl: getAmpUrl(doc.solr_ignore_deep_link_url),
  };
  return basePostMap(doc, articleObj);
}

function getAmpUrl(link) {
  return link
    .replace('articles', 'amp-articles')
    .replace('sheroes.com', 'web.sheroes.com');
}

function articleCategoryMap(data) {
  return data && data.map((list, index) => articleCategoryItemMap(list, index));
}
function articleCategoryItemMap(data, index) {
  const defaultCategoryCount = defaultArticleCategory.length || 0;
  return {
    title: data.label,
    key: getSlug(data.label),
    tabIndex: index + defaultCategoryCount,
    dataUrl: getArticleStreamApi(data.value),
    categoryUrl: getCategoryUrl(data),
  };
}

export { similarContentMap, articleMap, articleCategoryMap };
