import { action } from 'easy-peasy';
import { getRemoteConfig } from '../services/remoteConfigService';

export default {
  spamReasons: null,
  showSpamModal: false,
  contentType: '',
  docInfo: {
    docId: 0,
    postAuthorId: 0,
    communityId: 0,
  },
  updateSpamState: action(
    (state, { showSpamModal, contentType = '', docInfo }) => {
      state.showSpamModal = showSpamModal;
      state.contentType = contentType;
      state.docInfo = docInfo;
    }
  ),
  fetchSpamReasons: action((state) => {
    const appConfig = getRemoteConfig();
    state.spamReasons = appConfig && appConfig.spam_reasons;
  }),
};
