import { action, thunk } from 'easy-peasy';
import moment from 'moment';
import postService from '../services/postService';
import pollService from '../services/pollService';
import communityService from '../services/communityService';
import analytics from '../services/analyticsService';
import { eventNames, eventProps } from '../utils/eventConstants';
import { getPostObjForEventTracking } from '../utils/postUtil';

export default {
  postsObj: {},
  setPostState: action((state, docs) => {
    const posts = {};
    docs.map((doc) => (posts[doc.id] = doc));
    state.postsObj = { ...state.postsObj, ...posts };
  }),
  updatePostState: action((state, postObj) => {
    const currentTime = moment.utc().format();
    if (postObj && moment(postObj.postCreatedAt).isAfter(currentTime)) {
      postObj.isSchedulePost = true;
    }
    state.postsObj[postObj.id] = postObj;
  }),
  fetchPost: thunk(
    async (actions, { postEntityId, postSubType, isPostDetail, history }) => {
      try {
        const post = await postService.getPost(postEntityId, postSubType);
        actions.updatePostState(post);
        return post.id; //need to return the postId in order to get corresponding postObj directly from store.
      } catch (error) {
        if (isPostDetail) history.replace('/');
      }
    }
  ),
  likePost: thunk(
    async (actions, { postId, postEntityId, likeStatus }, { getState }) => {
      const postObj = getState().postsObj[postId];
      const prevPostLikesCount = postObj.likesCount;
      const prevPostLikeStatus = postObj.isLiked;
      postObj.isLiked = likeStatus;
      postObj.shouldRecompute = true;
      postObj.likesCount = likeStatus
        ? prevPostLikesCount + 1
        : prevPostLikesCount - 1;
      try {
        actions.updatePostState(postObj);
        await postService.toggleLike(postEntityId, likeStatus);
        const eventName = postObj.isLiked
          ? eventNames.postLiked
          : eventNames.postUnLiked;
        analytics.track(eventName, getPostObjForEventTracking(postObj));
      } catch (exception) {
        postObj.isLiked = prevPostLikeStatus;
        postObj.likesCount = prevPostLikesCount;
      }
    }
  ),

  likeComment: thunk(
    async (
      actions,
      {
        postId,
        postEntityId,
        commentIndex,
        likeStatus,
        commentId,
        isPostDetail,
      },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      let commentObj = isPostDetail
        ? postObj.comments[commentIndex]
        : postObj.lastComment[0];

      const prevPostLikesCount = commentObj.likesCount;
      const prevPostLikeStatus = commentObj.isLiked;
      commentObj.isLiked = likeStatus;

      commentObj.likesCount = likeStatus
        ? prevPostLikesCount + 1
        : prevPostLikesCount - 1;

      const commentsCount = postObj.comments.length;
      const lastComment = commentIndex === commentsCount - 1;

      if (isPostDetail) {
        postObj.comments[commentIndex] = commentObj;
        if (lastComment) {
          postObj.lastComment[0] = commentObj;
        }
      } else {
        postObj.lastComment[0] = commentObj;
        postObj.comments[commentsCount - 1] = commentObj;
      }

      try {
        actions.updatePostState(postObj);
        await postService.toggleCommentLike(
          postEntityId,
          commentId,
          likeStatus
        );
        const eventName = commentObj.isLiked
          ? eventNames.postLiked
          : eventNames.postUnLiked;
        analytics.track(eventName, getPostObjForEventTracking(commentObj));
      } catch (exception) {
        commentObj.isLiked = prevPostLikeStatus;
        commentObj.likesCount = prevPostLikesCount;
      }
    }
  ),

  repost: thunk(
    async (actions, { postId, postEntityId, repostStatus }, { getState }) => {
      const postObj = getState().postsObj[postId];
      const prevRepostsCount = postObj.repostsCount;
      const prevPostrepostStatus = postObj.isReposted;
      postObj.isReposted = repostStatus;
      postObj.shouldRecompute = true;
      postObj.repostsCount = repostStatus
        ? prevRepostsCount + 1
        : prevRepostsCount - 1;
      try {
        actions.updatePostState(postObj);
        await postService.toggleRepost(postEntityId, repostStatus);
        const eventName = postObj.isReposted
          ? eventNames.postReposted
          : eventNames.postUnReposted;
        analytics.track(eventName, getPostObjForEventTracking(postObj));
      } catch (exception) {
        postObj.isReposted = prevPostrepostStatus;
        postObj.repostsCount = prevRepostsCount;
      }
    }
  ),
  postFollow: thunk(
    async (
      actions,
      { postId, followStatus, postEntityId, setErrorMessage },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      const prevFollowStatus = postObj.isFollowed;
      postObj.isFollowed = followStatus;
      try {
        actions.updatePostState(postObj);
        await postService.postFollow(postEntityId, followStatus);
      } catch (exception) {
        setErrorMessage('Please try again after sometime.');
        postObj.isFollowed = prevFollowStatus;
      }
    }
  ),
  fetchPostComments: thunk(
    async (
      actions,
      { postId, postEntityId, pageNum, shouldPrepend },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      try {
        const comments = await postService.getPostComments(
          postEntityId,
          pageNum
        );
        postObj.comments = shouldPrepend
          ? [...comments, ...postObj.comments]
          : [...postObj.comments, ...comments];
        actions.updatePostState(postObj);
      } catch (error) { }
    }
  ),

  saveCommentPost: thunk(
    async (
      actions,
      { postId, postEntityId, commentObj, isAnonymous, commentUserMention },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      const prevComments = [...postObj.comments];
      const prevCommentsCount = postObj.commentsCount;
      postObj.comments = [...postObj.comments, ...commentObj];
      postObj.commentsCount = prevCommentsCount + 1;
      postObj.shouldRecompute = true;
      actions.updatePostState(postObj);
      try {
        const comment = await postService.saveComment(
          postEntityId,
          commentObj[0].description,
          isAnonymous,
          commentUserMention
        );
        postObj.lastComment = [comment];
        postObj.comments = [...prevComments, comment];
        actions.updatePostState(postObj);
        analytics.track(eventNames.replyCreated, {
          [eventProps.body]: commentObj[0].description,
          [eventProps.postId]: postObj.entityId,
          [eventProps.subType]: postObj.subType,
          [eventProps.streamType]: postObj.streamType,
        });
        return '';
      } catch (error) {
        postObj.comments = prevComments;
        postObj.commentsCount = prevCommentsCount;
        return commentObj[0].description; //need to return the comment which was written to show it in the box if any error is thrown.
      }
    }
  ),
  deleteCommentPost: thunk(
    async (actions, { postId, postEntityId, commentId }, { getState }) => {
      const postObj = getState().postsObj[postId];
      const prevComments = [...postObj.comments];
      const prevCommentsCount = postObj.commentsCount;
      postObj.comments = postObj.comments.filter((comment) => {
        return comment.id !== commentId;
      });
      postObj.commentsCount = prevCommentsCount - 1;
      postObj.lastComment = postObj.comments.length
        ? [postObj.comments[postObj.commentsCount - 1]]
        : [];
      postObj.shouldRecompute = true;
      actions.updatePostState(postObj);

      try {
        await postService.deleteComment(postEntityId, commentId);
        actions.updatePostState(postObj);
        analytics.track(eventNames.replyDeleted, {
          [eventProps.postId]: postObj.entityId,
          [eventProps.subType]: postObj.subType,
          [eventProps.streamType]: postObj.streamType,
        });
      } catch (error) {
        postObj.comments = prevComments;
        postObj.commentsCount = prevCommentsCount;
        actions.updatePostState(postObj);
      }
    }
  ),
  savePost: thunk(
    async (
      actions,
      {
        createPostRequestBody,
        files,
        history,
        isEditablePost,
        setErrorMessage,
        lastLocation,
        fromScreen,
      }
    ) => {
      try {
        const postObj = await postService.savePost(
          createPostRequestBody,
          files
        );
        if (!isEditablePost) postObj.isNewPost = true;
        //const postId = postObj.id;
        postObj.shouldRecompute = true;
        actions.updatePostState(postObj);
        const eventName = isEditablePost
          ? eventNames.postEdited
          : eventNames.postCreated;
        analytics.track(eventName, getPostObjForEventTracking(postObj));
        if (lastLocation && lastLocation.pathname) {
          history.goBack();
        } else if (fromScreen) {
          history.replace(fromScreen);
        } else {
          history.replace('/feed');
        }
      } catch (e) {
        setErrorMessage('Please try Again');
        return e;
      }
    }
  ),
  deletePost: thunk(
    async (
      actions,
      { postId, postEntityId, isPostDetail, history },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      try {
        await postService.deletePost(postEntityId);
        analytics.track(
          eventNames.postDeleted,
          getPostObjForEventTracking(postObj)
        );
        if (isPostDetail) history.replace('/');
      } catch (error) { }
    }
  ),
  submitPoll: thunk(async (actions, { pollId, pollOptionId }, { getState }) => {
    try {
      const postObj = await pollService.submitPoll(pollId, pollOptionId);
      const pollObj = getState().postsObj[postObj.id];
      analytics.track(
        eventNames.pollVoted,
        getPostObjForEventTracking(postObj)
      );
      postObj.comments = pollObj.comments;
      postObj.lastComment = pollObj.lastComment;
      actions.updatePostState(postObj);
    } catch (e) {
      //setErrorMessage('Please try Again');
      return e;
    }
  }),
  joinCommunity: thunk(
    async (
      actions,
      { communityId, userId, toJoin },
      { getStoreActions, getStoreState }
    ) => {
      try {
        const communityData = await communityService.joinCommunity(
          communityId,
          userId,
          toJoin
        );
        let communityJoinedStatus = false;
        const communityDetail = {
          ...getStoreState().community.communities[communityId]
            .communityDetails,
        };
        if (communityData.status === 'SUCCESS') {
          communityJoinedStatus = toJoin;
          analytics.track(eventNames.communityJoined, {
            [eventProps.id]: communityId,
            [eventProps.communityName]: communityDetail.name,
          });
        }
        communityDetail.isMember = communityJoinedStatus;

        getStoreActions().community.setCommunityDetails(communityDetail);
      } catch (e) {
        //setErrorMessage('Please try Again');
        return e;
      }
    }
  ),
  approveOrDeletePost: thunk(
    async (
      actions,
      { postId, entityId, is_spam, is_active, is_approved },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      try {
        postObj.isSpamPost = false;
        postObj.shouldRecompute = true;
        actions.updatePostState(postObj);
        await postService.approveOrDeletePost(
          entityId,
          is_spam,
          is_active,
          is_approved
        );
      } catch (error) { }
    }
  ),
  featurePost: thunk(
    async (actions, { isTopPost, postId, entityId }, { getState }) => {
      const postObj = getState().postsObj[postId];
      try {
        const changePostStatus = postObj.isTopPost ? false : true;
        postObj.isTopPost = changePostStatus;
        postObj.shouldRecompute = true;
        actions.updatePostState(postObj);
        if (postObj && postObj.optionsMenu.length > 0) {
          postObj.optionsMenu.forEach((option) => {
            if (option.id === 'featurePost') {
              option.name = postObj.isTopPost
                ? 'UnFeature This Post'
                : 'Feature This Post';
            }
          });
        }
        await postService.featurePost(changePostStatus, entityId);
      } catch (error) { }
    }
  ),
  fetchPostReactions: thunk(
    async (
      actions,
      { postId, postEntityId, pageNum },
      { getState }
    ) => {
      const postObj = getState().postsObj[postId];
      try {
        const likeList = await postService.getPostReactions(
          postEntityId,
          pageNum
        );
        postObj.likeList = [...likeList];
        actions.updatePostState(postObj);
      } catch (error) { }
    }
  )
};
