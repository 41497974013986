import React, { useState } from 'react';
import { useInfiniteScrollThunkWithPageNumber } from '../../hooks/infiniteScrollHook';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useCommentOnPost } from '../../hooks/postHooks';
import CommentList from './commentList';
import CreateComment from './createCommentCard';
import { useScrollToBottom } from '../../hooks/utilHooks';

const CommentSection = ({
  isPostDetail,
  focusComment,
  post,
  isArticleDetail,
  handleCommentClick,
}) => {
  const postId = post.id;
  const parentId = post.parentId;
  const communityId = post.communityId;
  const [scrollToBottom, setScrollToBottom] = useState(false);
  useScrollToBottom(scrollToBottom);
  const fetchPostComments = useStoreActions(
    (actions) => actions.posts.fetchPostComments
  ); // thunk.
  const postComments = useStoreState(
    (state) => state.posts.postsObj[postId].comments
  ); // store.
  const triggerPagination =
    (isPostDetail || isArticleDetail) && !postComments.length ? true : false;
  const { handleLoadMore, hasMore } = useInfiniteScrollThunkWithPageNumber(
    (pageNum) => {
      return fetchPostComments({
        postId: post.id,
        postEntityId: post.parentId,
        pageNum: pageNum,
        shouldPrepend: true,
      });
    },
    { triggerPagination }
  );
  const {
    handleSaveComment,
    commentDescription,
    commentUserMention,
    handleSetCommentEditor,
    isCommentSubmit,
    setIsCommentSubmit,
  } = useCommentOnPost(post, setScrollToBottom);
  return (
    <React.Fragment>
      <CommentList
        isPostDetail={isPostDetail}
        post={post}
        hasMore={hasMore}
        handleLoadMore={handleLoadMore}
        isArticleDetail={isArticleDetail}
      />
      {((isArticleDetail && focusComment) || !isArticleDetail) && (
        <CreateComment
          handleCommentClick={handleCommentClick}
          handleSaveComment={handleSaveComment}
          post={post}
          focusComment={focusComment}
          isPostDetail={isPostDetail}
          isArticleDetail={isArticleDetail}
          commentDescription={commentDescription}
          commentUserMention={commentUserMention}
          handleSetCommentEditor={handleSetCommentEditor}
          isCommentSubmit={isCommentSubmit}
          setIsCommentSubmit={setIsCommentSubmit}
          entityId={parentId}
          communityId={communityId}
        />
      )}
    </React.Fragment>
  );
};

export default CommentSection;
