import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid } from '@material-ui/core';
import playStore from '../../img/playstore-icon.jpg';
import appStore from '../../img/home/appstore.svg';
import StarIcon from '@material-ui/icons/Star';
import { useMobile, useTablet } from '../../hooks/utilHooks';

const AppDownload = () => {
  const classes = useStyles();
  const isMobile = useMobile();
  const isTablet = useTablet();

  return (
    <React.Fragment>
      <Box py={6} px={4}>
        <Box
          align="center"
          className={isTablet ? 'width100' : 'mxAuto homeContainer'}
        >
          <Grid container>
            <Grid item xs={12} sm={4} md={4}>
              <Box mt={isMobile ? 0 : 2}>
                <Typography align={isMobile ? 'center' : 'left'} variant="h6">
                  Download SHEROES App
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} sm={8} md={8}>
              <Grid container>
                <Grid item xs={12} sm={6} md={5}>
                  <Box className={classes.rating}>
                    <StarIcon className={classes.starIcon} />
                    <Typography variant="subtitle2">4.5 </Typography>
                    <Typography variant="body2" className={classes.ratingText}>
                      ratings on Play store
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={7}>
                  <Box
                    mt={isMobile ? 6 : 0}
                    display="flex"
                    justifyContent="space-around"
                  >
                    <a
                      href="https://shrs.me/zELbHsDJNS"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={playStore} alt="Play Store" width="90%" />
                    </a>
                    <a
                      href="https://shrs.me/UY88ifnmNS"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={appStore} alt="App Store" width="90%" />
                    </a>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};
export default AppDownload;

const useStyles = makeStyles((theme) => ({
  rating: {
    width: '100%',
    height: '30px',
    marginTop: theme.spacing(1),
    borderRadius: theme.spacing(4),
    boxShadow: '0 0 7px 0 rgba(124, 122, 122, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  starIcon: {
    color: '#ffbf12',
    marginRight: theme.spacing(2),
  },
  ratingText: {
    marginLeft: theme.spacing(1),
  },
}));
