import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
  Box,
} from '@material-ui/core';
import AdapterLink from '../common/adapterLink';
import { useLoggedInUser } from '../../hooks/userHooks';
import imgParams from '../../utils/imageUtil';
import ChampionIcon from '../../img/championIcon.svg';

const SideBarProfileSection = () => {
  const classes = useStyles();
  const user = useLoggedInUser();

  return (
    <React.Fragment>
      {user && (
        <Box>
          <List>
            <ListItem
              to={user ? user.userProfileLink : '/login'}
              component={AdapterLink}
            >
              <ListItemIcon>
                <Box display="inline-block" position="relative">
                  <Avatar
                    src={imgParams(user.image, 60, 60, 'auto')}
                    aria-label="Recipe"
                    className={classes.avatar}
                  />
                  {user.userTypeId === 7 && (
                    <img
                      src={ChampionIcon}
                      alt="Champion Icon"
                      className={`championIconStyle ${classes.championIcon}`}
                    />
                  )}
                </Box>
              </ListItemIcon>
              <ListItemText
                className={classes.itemText}
                primary={
                  <Typography color="textPrimary" variant="body2">
                    {user && user.name ? user.name : 'Login'}
                  </Typography>
                }
                secondary={
                  <Typography color="textSecondary" variant="body1">
                    {user && user.emailId}
                  </Typography>
                }
              />
            </ListItem>
          </List>
          <Divider />
        </Box>
      )}
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    margin: theme.spacing(1, 0),
  },
  itemText: {
    wordWrap: 'break-word',
  },
  championIcon: {
    bottom: '5px!important',
  },
}));
export default SideBarProfileSection;
