import { action } from 'easy-peasy';
import { themePostConfig } from '../utils/constants';
import { postToFollowerObj } from '../mappers/communityMapper';

const postRequestObj = {
  id: '',
  communityId: '',
  communityName: '',
  postCreatorType: '',
  postDescription: '',
  isThemePost: false,
  isActive: false,
  selectedTheme: null,
  files: [],
  deletedImageIds: [],
  isThemeChange: false,
  isPostCreatorChange: false,
  schedulePostDate: '',
  schedulePostISODate: '',
  isFromCommunityDetails: false,
  selectedCommunity: {
    id: postToFollowerObj[0].id,
    name: postToFollowerObj[0].name,
  },
  ogTitle: '',
  ogImageUrl: '',
  ogRequestedUrl: '',
  isOgVideoLink: '',
  ogDescription: '',
  userMentions: [],
  hasMentions: false,
  isCommentSubmit: false,
};
export default {
  postObject: postRequestObj,
  setIsFromCommunityDetails: action((state, payload) => {
    state.postObject.isFromCommunityDetails = payload;
  }),
  setSelectedCommunity: action((state, payload) => {
    state.postObject.communityId = payload.id;
    state.postObject.communityName = payload.name;
    state.postObject.selectedCommunity = { id: payload.id, name: payload.name };
  }),
  setPostDescription: action((state, payload) => {
    state.postObject.postDescription = payload;
  }),
  setPostCreatorType: action((state, payload) => {
    state.postObject.postCreatorType = payload;
    state.postObject.isPostCreatorChange = true;
  }),
  setIsThemePost: action((state, payload) => {
    if (
      state.postObject.postDescription.length >= themePostConfig.maxCharLimit
    ) {
      state.postObject.isThemePost = false;
    } else {
      state.postObject.isThemePost = payload;
    }
  }),
  setTheme: action((state, payload) => {
    state.postObject.selectedTheme = payload;
  }),
  setFiles: action((state, payload) => {
    state.postObject.files = payload;
  }),
  setDeletedImageIds: action((state, payload) => {
    state.postObject.deletedImageIds = payload;
  }),
  resetCreatePostObject: action((state, payload) => {
    state.postObject = postRequestObj;
  }),
  setHandleThemeChange: action((state, payload) => {
    const { e, selectedThemeObj } = payload;
    e.preventDefault();
    state.postObject.isThemeChange = true;
    state.postObject.selectedTheme = selectedThemeObj;
  }),
  checkThemePostStatus: action((state, payload) => {
    const { selectedTheme, postDescription } = state.postObject;
    if (selectedTheme) {
      const defaultThemeId = parseInt(process.env.REACT_APP_DEFAULT_THEME_ID);
      const selectedThemeId = selectedTheme.id;
      if (selectedThemeId === defaultThemeId) {
        state.postObject.isThemePost = false;
        state.postObject.selectedTheme = null;
      }
    }

    if (postDescription && postDescription !== null) {
      const textLength = postDescription.length;
      const numberOfLineBreaks = (postDescription.match(/\n/g) || []).length;
      if (
        textLength >= themePostConfig.maxCharLimit ||
        numberOfLineBreaks >= themePostConfig.maxLineBreakLimit
      ) {
        state.postObject.isThemePost = false;
        state.postObject.selectedTheme = null;
      }
    }
  }),
  setHandleDeleteItem: action((state, payload) => {
    const { e, index, files } = payload;
    e.preventDefault();
    const updatedFiles = files.filter((file, i) => i !== index);
    state.postObject.files = updatedFiles;
    if (files[index].id)
      state.postObject.deletedImageIds = [
        ...state.postObject.deletedImageIds,
        files[index].id,
      ];
  }),
  setRichLinkData: action((state, payload) => {
    const {
      ogTitle,
      ogImageUrl,
      ogRequestedUrl,
      isOgVideoLink,
      ogDescription,
    } = payload.ogResponseData;
    state.postObject.ogTitle = ogTitle ? ogTitle : '';
    state.postObject.ogImageUrl = ogImageUrl ? ogImageUrl : '';
    state.postObject.ogRequestedUrl = ogRequestedUrl ? ogRequestedUrl : '';
    state.postObject.isOgVideoLink = isOgVideoLink ? isOgVideoLink : '';
    state.postObject.ogDescription = ogDescription ? ogDescription : '';
  }),
  setUserMentions: action((state, payload) => {
    state.postObject.userMentions = payload;
  }),
  setIsCommentSubmit: action((state, payload) => {
    state.postObject.isCommentSubmit = payload;
  }),
};
