import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { useMobile } from '../../hooks/utilHooks';

const TestimonialCarouselBuilt = ({ testimonialObj, activeStep }) => {
  const classes = useStyles();
  const isMobile = useMobile();

  return (
    <React.Fragment>
      <Box
        className={classes.carouseBuilt}
        py={4}
        display="flex"
        justifyContent="center"
        top={isMobile ? 45 : 12}
      >
        {testimonialObj &&
          testimonialObj.map((item, index) => (
            <li
              key={index}
              className={` ${classes.carouselPosition} ${
                activeStep === index && classes.activeBuilt
              }`}
            ></li>
          ))}
      </Box>
    </React.Fragment>
  );
};
export default TestimonialCarouselBuilt;

const useStyles = makeStyles((theme) => ({
  carouselPosition: {
    border: '1px solid' + theme.palette.grey['50'],
    listStyle: 'none',
    width: '8px',
    height: '8px',
    marginRight: theme.spacing(1),
    borderRadius: '50%',
  },
  activeBuilt: {
    background: theme.palette.primary.main,
    border: '1px solid' + theme.palette.primary.main,
  },
  carouseBuilt: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    left: 0,
    right: 0,
  },
}));
