import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { Typography } from '@material-ui/core';
import { useStoreActions, useStoreState } from 'easy-peasy';

const CustomSnackbar = () => {
  const { open, message, duration } = useStoreState((state) => state.snackBar);
  const setOpen = useStoreActions((actions) => actions.snackBar.setOpen);
  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      open={open}
      onClose={() => setOpen(false)}
      autoHideDuration={duration}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={<Typography id="message-id">{message}</Typography>}
    />
  );
};

export default CustomSnackbar;
