import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import NavigationMenu from '../sideMenu/navigationMenu';
import MyCommunitiesDrawer from '../community/myCommunitiesDrawer';
import { useTablet } from '../../hooks/utilHooks';
import { useLoggedInUser } from '../../hooks/userHooks';
import { useStoreState } from 'easy-peasy';
import AdapterLink from './adapterLink';

const CustomSideBarGrid = ({ children }) => {
  const classes = useStyles();
  const isTablet = useTablet();
  const user = useLoggedInUser();
  const showCommunitySideBar = useStoreState(
    (state) => state.navBar.options.showCommunitySideBar
  );
  const showMenuSideBar = useStoreState(
    (state) => state.navBar.options.showMenuSideBar
  );

  const rightSideBar = useStoreState(
    (state) => state.navBar.options.rightSideBar
  );
  const topSpace = useStoreState((state) => state.navBar.options.topSpace);
  const disableCustomSideBarGrid = useStoreState(
    (state) => state.navBar.options.disableCustomSideBarGrid
  );

  if (disableCustomSideBarGrid) {
    return <React.Fragment> {children}</React.Fragment>;
  }

  return (
    <Box className="container" px={!isTablet ? 4 : ''}>
      <Grid container>
        {!isTablet && showMenuSideBar && (
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Box
              marginTop={topSpace}
              className={classes.sideBar}
              left="0"
              right="890px"
            >
              <Box className={classes.sideMenu}>
                <NavigationMenu />
              </Box>
              <Box
                to="/create-post"
                component={AdapterLink}
                className={classes.postBtn}
              >
                <Typography variant="subtitle2"> Create Post</Typography>
              </Box>
            </Box>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={8}
          md={6}
          lg={6}
          className={
            (!showMenuSideBar && !showCommunitySideBar) || isTablet
              ? 'mxAuto'
              : ''
          }
        >
          <Box px={!isTablet ? 4 : ''}>{children}</Box>
        </Grid>

        {!isTablet && showCommunitySideBar && (
          <Grid item xs={12} sm={3} md={3} lg={3}>
            <Box className={classes.sideBar} left="890px" right="0">
              <Box marginTop={topSpace} className={classes.rightSideBar}>
                {rightSideBar || (user && <MyCommunitiesDrawer />)}
              </Box>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  sideBar: {
    width: '275px',
    position: 'fixed',
    margin: '0px auto',
    zIndex: '1',
  },
  rightSideBar: {
    background: '#fff',
    overflow: 'hidden',
    borderRadius: '15px',
    width: '100%',
  },
  sideMenu: {
    background: '#fff',
    borderRadius: '15px',
  },
  postBtn: {
    background: theme.palette.primary.main,
    color: 'white',
    display: 'block',
    textDecoration: 'none',
    borderRadius: theme.spacing(6),
    padding: theme.spacing(3, 0),
    margin: '16px auto 0px',
    textAlign: 'center',
    width: '80%',
  },
}));

export default CustomSideBarGrid;
