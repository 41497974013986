import React from 'react';
import { makeStyles, Grid, Box, Typography } from '@material-ui/core';
import LikeIcon from '../../img/heart.svg';
import LikedIcon from '../../img/heart-liked.svg';
import CommentIcon from '../../img/comment.svg';
import RepostedIcon from '../../img/reposted.svg';
import RepostIcon from '../../img/repost.svg';
import ShareIcon from '../../img/share.svg';
import { sharePost } from '../../utils/postUtil';
import BorderLine from '../common/borderLine';
import PostActionButton from './postActionButton';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useMobile } from '../../hooks/utilHooks';
import { useSnackBar } from '../../hooks/snackBarHooks';
import AnyLink from '../common/anyLink';
import LikeList from '../common/likeList';

const PostEngagementSection = ({
  post,
  handleLike,
  isLiked,
  handleCommentClick,
  handleRepost,
  isReposted,
  isArticleDetail,
  focusComment,
}) => {
  const isMobile = useMobile();
  const snackBar = useSnackBar();
  const classes = useStyles();
  const showBorder =
    ((post.likesCount || post.commentsCount) && post.isImagePost) ||
    !post.isImagePost;

  return (
    <React.Fragment>
      {isArticleDetail && !focusComment && <BorderLine color="grey.600" />}
      {showBorder && (
        <Box px={4}>
          <BorderLine color="grey.100" />
        </Box>
      )}
      <Box px={4} pt={2}>
        <Box>
          <Grid
            justify="space-between" // Add it here :)
            container
          >
            <Grid item xs={8} lg={7}>
              <Box display="flex" justifyContent="space-between">
                <PostActionButton
                  icon={
                    isLiked ? (
                      <img
                        src={LikedIcon}
                        width="24px"
                        height="24px"
                        alt="Liked"
                      />
                    ) : (
                      <img
                        src={LikeIcon}
                        width="24px"
                        height="24px"
                        alt="Like"
                      />
                    )
                  }
                  handleAction={handleLike}
                  activeStatus={isLiked}
                />

                <PostActionButton
                  icon={
                    <img
                      src={CommentIcon}
                      alt="Comment"
                      width="24px"
                      height="24px"
                    />
                  }
                  handleAction={handleCommentClick}
                />

                {post.subType !== 'PL' && post.subType !== 'A' && (
                  <PostActionButton
                    icon={
                      isReposted ? (
                        <img
                          src={RepostedIcon}
                          alt="reposted"
                          width="24px"
                          height="24px"
                        />
                      ) : (
                        <img
                          src={RepostIcon}
                          alt="repost"
                          width="24px"
                          height="24px"
                        />
                      )
                    }
                    handleAction={handleRepost}
                  />
                )}

                <CopyToClipboard
                  text={post && post.deepLinkUrl}
                  onCopy={() => !isMobile && snackBar('Copied Successfully')}
                >
                  <Box onClick={(e) => !isMobile && e.stopPropagation()}>
                    <PostActionButton
                      icon={
                        <img
                          src={ShareIcon}
                          alt="Share"
                          width="24px"
                          height="24px"
                        />
                      }
                      handleAction={(e) =>
                        isMobile &&
                        sharePost(e, post.sharePostInfo, post, isMobile)
                      }
                    />
                  </Box>
                </CopyToClipboard>
              </Box>
              <Box className={classes.countSet}>
                {post.likesCount > 0 && (
                  <LikeList className={classes.likeText} post={post} />
                )}

                {post.commentsCount > 0 && (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    component="span"
                  >
                    {post.commentsCount}
                    {post.commentsCount > 1 ? ' comments' : ' comment'}
                  </Typography>
                )}

                {post.repostsCount > 0 && (
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    component="span"
                  >
                    {post.repostsCount}{' '}
                    {post.repostsCount > 1 ? ' reposts' : ' repost'}
                  </Typography>
                )}
              </Box>
            </Grid>
            {!isArticleDetail && (
              <Grid item xs={4} lg={5}>
                <Box align="right">
                  <AnyLink to={post.deepLinkUrl}>
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      component="span"
                    >
                      {post.author && post.createdAt}
                    </Typography>
                  </AnyLink>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  countSet: {
    padding: theme.spacing(1.5, 0),
    '& span': {
      position: 'relative',
      cursor: 'pointer',
      '&::after': {
        content: '"."',
        display: 'inline-block',
        padding: theme.spacing(0, 1.5),
        fontSize: theme.spacing(5),
        position: 'relative',
        top: '-3px',
      },
      '&:last-child': {
        '&::after': {
          display: 'none',
        },
      },
    },
  },
  likeText: {
    display: 'inline-block',
  },
}));
export default PostEngagementSection;
