import { action } from 'easy-peasy';

export default {
  open: false,
  message: '',
  duration: 3000,
  setOpen: action((state, payload) => {
    state.open = payload;
  }),
  setMessage: action((state, payload) => {
    state.message = payload;
    state.open = true;
  }),
  setDuration: action((state, payload) => {
    state.duration = payload;
  }),
};
