import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Drawer } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { ReactComponent as DefaultCommunityIcon } from '../../img/default-community-icon.svg';
import DrawerContent from './drawerContent';
import MoreThemeIcon from '../../img/more-theme-icon.svg';
import Close from '@material-ui/icons/Close';
import { useTablet } from '../../hooks/utilHooks';

export default function ToggleDrawer({
  isCommunity,
  direction,
  isThemeDrawer,
  component,
}) {
  const classes = useStyles();
  const isTablet = useTablet();
  const [state, setState] = useState({
    left: false,
    right: false,
  });
  const toggleDrawer = (side, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setState({ ...state, [side]: open });
  };

  const sideList = (side) => (
    <Box
      className={classes.list}
      role="presentation"
      onClick={!isThemeDrawer ? toggleDrawer(side, false) : null}
      onKeyDown={toggleDrawer(side, false)}
    >
      <DrawerContent
        isCommunity={isCommunity}
        isThemeDrawer={isThemeDrawer}
        component={component}
      />
    </Box>
  );

  return (
    <Box position="relative">
      {!isCommunity && !isThemeDrawer && (
        <MenuIcon
          className={classes.menuIconStyle}
          onClick={toggleDrawer(direction, true)}
        />
      )}
      {isCommunity && !isThemeDrawer && (
        <DefaultCommunityIcon
          alt="CommunityIcon"
          onClick={toggleDrawer(direction, true)}
          className={classes.communityIcon}
        />
      )}
      {isThemeDrawer && !isCommunity && (
        <Box className={classes.setMoreThemeIcon}>
          <img
            src={MoreThemeIcon}
            alt="more Theme Icon"
            onClick={toggleDrawer(direction, true)}
          />
        </Box>
      )}
      <Drawer
        anchor={direction}
        open={state[direction]}
        onClose={toggleDrawer(direction, false)}
        transitionDuration={300}
        classes={{
          paper: isTablet ? classes.paperDrawer : classes.desktopDrawer,
        }}
      >
        {isThemeDrawer && (
          <Close
            className={classes.setClose}
            onClick={toggleDrawer('bottom', false)}
          />
        )}

        {sideList(direction)}
      </Drawer>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  list: {
    paddingBottom: theme.spacing(10),
  },
  menuIconStyle: {
    margin: theme.spacing(1.5, 0, 1, 0),
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
  communityIcon: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(1),
  },
  setMoreThemeIcon: {
    position: 'absolute',
    right: '2px',
    top: '-40px',
    backgroundColor: '#fff',
  },
  setClose: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(4),
    margin: theme.spacing(1, 2, 1, 2),
  },
  paperDrawer: {
    borderTopLeftRadius: theme.spacing(2.5),
    borderTopRightRadius: theme.spacing(2.5),
  },
  desktopDrawer: {
    width: '40%',
    margin: '0px auto',
    borderTopLeftRadius: theme.spacing(2.5),
    borderTopRightRadius: theme.spacing(2.5),
  },
}));
