import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';
import Navbar from './components/header/navbar';
import { StoreProvider, createStore } from 'easy-peasy';
import globalState from './stores/globalStore';
import theme from './theme';
import globalStyles from './components/common/globalStyles';
import CustomSnackbar from './components/common/customSnackbar';
import CustomDialogBox from './components/common/customDialogBox';
import ReportSpam from './components/common/reportSpam';
import { getRemoteConfigFromServer } from './services/remoteConfigService';
import communityService from './services/communityService';
import { renderRoutes } from './utils/routeUtils';
import routes from './routes/mainRoutes';
import { requestNotificationPermission } from './utils/pushNotification';
import { LastLocationProvider } from 'react-router-last-location';
import auth from './services/authService';

import {
  getLoginEventFired,
  setLoginEventFired,
  setPostLanguage,
  getPostLanguage,
} from './services/authService';
import { eventNames, eventProps } from './utils/eventConstants';
import analytics from './services/analyticsService';
import LoginModal from './components/home/loginModal';
import CustomSideBarGrid from './components/common/customSideBarGrid';
import MetaData from './components/common/metaData';

const globalStore = createStore(globalState);
const Main = () => {
  return (
    <React.Fragment>
      <LastLocationProvider>
        <Navbar />
        <CustomSideBarGrid>{renderRoutes(routes)}</CustomSideBarGrid>
        <MetaData />
        <CustomSnackbar />
        <CustomDialogBox />
        <ReportSpam />
        <LoginModal />
      </LastLocationProvider>
    </React.Fragment>
  );
};

const App = ({ children }) => {
  globalStyles(); //calling global css classes here so that they can be used across app.
  globalStore.getActions().user.updateUser();
  const { user } = globalStore.getState().user;

  //Check the token and login anywhere in site than only for protected routes.
  if (!user) auth.loginUserByToken(window.location.href);
  const loginEventFired = getLoginEventFired();
  const postLang = getPostLanguage();
  user && postLang == null && setPostLanguage('en');
  user && analytics.registerUser(user);
  user &&
    !loginEventFired &&
    analytics.track(eventNames.webLogin, {
      [eventProps.isNewUser]: user.isNewUser,
    });
  !loginEventFired && setLoginEventFired();
  useEffect(() => {
    user && getRemoteConfigFromServer();
    user && communityService.getCommunityFromServices();
    user && requestNotificationPermission();
    user && communityService.getThemesFromServices();
  }, [user]);

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <BrowserRouter>
        <StoreProvider store={globalStore}>
          {children || <Main />}
        </StoreProvider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
