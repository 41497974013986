import React, { useState } from 'react';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  makeStyles,
} from '@material-ui/core';
import { useStoreState, useStoreActions } from 'easy-peasy';
import ScheduleIcon from '@material-ui/icons/Schedule';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import { useCreatePost } from '../../hooks/createPostHook';
import SchedulePostCalendar from '../createPost/schedulePostCalendar';
import SchedulePostMessage from './schedulePostMessage';
import { getTodayDate, YEAR_FIRST_FORMAT } from '../../utils/dateUtil';

const AdminPostModal = () => {
  const classes = useStyles();
  const {
    dialogTitle,
    dialogContentText,
    btnPrimaryText,
    btnSecondaryText,
  } = useStoreState((state) => state.dialogBox);
  const { setDialogBoxOpen, setDialogContent } = useStoreActions(
    (actions) => actions.dialogBox
  );

  const { handleSavePost } = useCreatePost();
  const handleOpenCalender = () => {
    setDialogBoxOpen(true);
    setDialogContent({
      dialogTitle: 'Select Date',
      dialogContentText: null,
      btnPrimaryText: '',
      btnSecondaryText: '',
      showCalendar: true,
      isAdminPost: true,
    });
  };
  const { showCalendar, showSchedulePostMessage } = useStoreState(
    (state) => state.dialogBox
  );
  const todayDateTime = getTodayDate(YEAR_FIRST_FORMAT);
  const [scheduleDate, setScheduleDate] = useState(todayDateTime);
  const handleDateChange = (e) => {
    e.preventDefault();
    setScheduleDate(e.target.value);
  };
  return (
    <React.Fragment>
      <DialogTitle className={classes.setDialog}>{dialogTitle}</DialogTitle>
      <Divider />
      {dialogContentText && (
        <DialogContent>
          <DialogContentText>{dialogContentText}</DialogContentText>
        </DialogContent>
      )}
      {btnPrimaryText && (
        <DialogActions>
          <Button
            fullWidth
            size="large"
            onClick={handleSavePost}
            className={classes.setDialog}
          >
            {btnPrimaryText && (
              <ScheduleIcon className={classes.setDialogIcon} />
            )}
            {btnPrimaryText}
          </Button>
        </DialogActions>
      )}
      {btnSecondaryText && (
        <DialogActions>
          <Button
            fullWidth
            size="large"
            onClick={() => handleOpenCalender()}
            className={classes.setDialog}
          >
            {btnSecondaryText && (
              <EventAvailableIcon className={classes.setDialogIcon} />
            )}
            {btnSecondaryText}
          </Button>
        </DialogActions>
      )}
      {showCalendar && (
        <SchedulePostCalendar
          handleDateChange={handleDateChange}
          scheduleDate={scheduleDate}
        />
      )}
      {showSchedulePostMessage && (
        <SchedulePostMessage scheduleDate={scheduleDate} />
      )}
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  setDialog: {
    padding: theme.spacing(0),
    textAlign: 'left',
    display: 'block',
    color: theme.palette.text.primary,
  },
  setDialogIcon: {
    verticalAlign: 'middle',
    marginRight: theme.spacing(5),
  },
}));
export default AdminPostModal;
