import http from './httpService';
import reverse from 'lodash/reverse';
import { postMap, commentMap } from '../mappers/modelMapper';
import apiUrl from '../utils/apiUrl';
import { maxPageSize } from '../utils/constants';
import { default as _each } from 'lodash/each';

async function toggleLike(entityId, toLike) {
  const api = toLike ? apiUrl.POST_LIKE_URL : apiUrl.POST_UNLIKE_URL;
  const { data } = await http.post(api, {
    entity_id: entityId,
    value: '10',
  });
  return data;
}
async function toggleRepost(entityId, toRepost) {
  const api = toRepost ? apiUrl.REPOST : apiUrl.UNREPOST;
  const { data } = await http.post(api, {
    entity_id: entityId,
    is_repost: toRepost,
    value: '10',
  });
  return data;
}

async function getPost(postId, postSubType) {
  let api = '';
  let requestBody = {};
  if (postSubType === 'PL') {
    api = apiUrl.GET_USER_POLL + postId;
    requestBody = {};
  } else {
    api = apiUrl.GET_POST;
    requestBody = {
      id_of_entity_or_participant: postId,
      sub_type: postSubType,
      source: 'web',
    };
  }
  const { data } = await http.post(api, requestBody);
  return postMap(data.docs[0]);
}
async function deletePost(postId, postSubType = 'P') {
  const { data } = await http.post(apiUrl.DELETE_POST, {
    id: postId,
    sub_type: postSubType,
  });
  return data.status;
}

async function getPostComments(postId, pageNum) {
  let { data } = await http.post(apiUrl.GET_POST_COMMENTS, {
    entity_id: postId,
    page_no: pageNum,
    page_size: maxPageSize,
  });
  data = data.docs.map(commentMap);
  return reverse(data);
}

async function saveComment(postId, comment, userAnonymity, commentUserMention) {
  const { data } = await http.post(apiUrl.SAVE_COMMENT, {
    entity_id: postId,
    comment: comment,
    is_anonymous: userAnonymity,
    has_mentions: commentUserMention ? true : false,
    user_mentions: commentUserMention && [...commentUserMention],
  });
  return commentMap(data.participation_model);
}
async function deleteComment(postId, commentId) {
  const { data } = await http.post(apiUrl.DELETE_POST_COMMENT, {
    participation_id: commentId,
    entity_id: postId,
  });
  return commentMap(data.participation_model);
}

async function reportSpam(docObj, spamInfoObj, userId, contentType) {
  const spamReqObj = {
    community_id: docObj.communityId,
    model_id: docObj.docId,
    model_type: contentType.toUpperCase(),
    reason: spamInfoObj.reason,
    reported_by_user_id: userId,
    reported_on_user_id: docObj.docAuthorId,
    score: spamInfoObj.score,
  };
  await http.post(apiUrl.REPORT_SPAM, spamReqObj);
  return;
}

async function savePost(savePostObj, files) {
  const postApi = savePostObj.id ? apiUrl.EDIT_POST : apiUrl.CREATE_POST;
  const reqData = new FormData();
  _each(files, function (file) {
    reqData.append('file', file);
  });
  reqData.append('postContent', JSON.stringify(savePostObj));
  const { data } = await http.post(postApi, reqData);
  return postMap(data.doc);
}

async function approveOrDeletePost(entityId, isSpam, isActive, isApproved) {
  const { data } = await http.post(apiUrl.APPROVE_POST, {
    id: entityId,
    is_spam: isSpam,
    is_active: isActive,
    is_approved: isApproved,
  });
  return data.status;
}
async function featurePost(isTopPost, entityId) {
  const { data } = await http.post(apiUrl.FEATURE_POST, {
    user_post_id: entityId,
    is_top_post_b: isTopPost,
  });
  return data.status;
}

async function toggleCommentLike(entityId, commentId, toLike) {
  const api = toLike ? apiUrl.POST_LIKE_URL : apiUrl.POST_UNLIKE_URL;
  const { data } = await http.post(api, {
    entity_id: entityId,
    parent_participation_id: commentId,
    value: '10',
  });
  return data;
}

async function getRichLinkData(url) {
  const { data } = await http.post(apiUrl.LINK_RENDER, {
    og_link_url: url,
  });
  return data;
}

async function postFollow(entityId, toFollow) {
  const api = toFollow ? apiUrl.FOLLOW : apiUrl.UNFOLLOW;
  const requestBody = {
    id_of_entity_or_participant: entityId,
  };
  const { data } = await http.post(api, requestBody);
  return data;
}

async function getPostReactions(postId, pageNum) {
  let { data } = await http.post(apiUrl.GET_REACTIONS, {
    entity_id: postId,
    page_no: pageNum,
    page_size: 100
  });
  data = data.docs.map(commentMap);
  return reverse(data);
}

export default {
  toggleLike,
  getPost,
  saveComment,
  getPostComments,
  deleteComment,
  deletePost,
  reportSpam,
  savePost,
  toggleRepost,
  approveOrDeletePost,
  toggleCommentLike,
  featurePost,
  getRichLinkData,
  postFollow,
  getPostReactions
};
