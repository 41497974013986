import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid } from '@material-ui/core';
import { useMobile, useTablet } from '../../hooks/utilHooks';
import TestimonialCarousel from './testimonialCarousel';

const Testimonial = () => {
  const classes = useStyles();
  const isMobile = useMobile();
  const isTablet = useTablet();

  return (
    <React.Fragment>
      <Box
        py={isMobile ? 6 : 10}
        px={4}
        className={classes.testimonialMain}
        bgcolor="#f6f6f6"
        position="relative"
      >
        <Box className={isTablet ? 'width100' : 'mxAuto homeContainer'}>
          <Box pb={isMobile ? 0 : 1} className="clearfix"></Box>
          <Grid container>
            <Grid item xs={12} sm={5} md={4}>
              <Box mt={isMobile ? 0 : '50px'} mb={isMobile ? 4 : 0}>
                <Typography align={isMobile ? 'center' : 'left'} variant="h6">
                  What our users are saying?
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} sm={7} md={8}>
              <TestimonialCarousel />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </React.Fragment>
  );
};
export default Testimonial;

const useStyles = makeStyles((theme) => ({
  starIcon: {
    color: '#ffbf12',
    margin: theme.spacing(1, 0, 0, 2),
  },
  description: {
    borderBottom: '2px solid #dc4541',
    paddingBottom: theme.spacing(2),
  },
  listItem: {
    padding: theme.spacing(0),
  },
  carouselPosition: {
    border: '1px solid' + theme.palette.grey['50'],
    listStyle: 'none',
    width: '8px',
    height: '8px',
    marginRight: theme.spacing(1),
    borderRadius: '50%',
  },
  red: {
    background: theme.palette.primary.main,
    border: '1px solid' + theme.palette.primary.main,
  },
  carouseBuilt: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    left: 0,
    right: 0,
  },
}));
