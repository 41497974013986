import authRoutes from './authRoutes';
import onboardingRoutes from './onboardingRoutes';
import feedRoutes from './feedRoutes';
import lastRoutes from './lastRoutes';
import postRoutes from './postRoutes';
import ProtectedRoute from '../components/common/protectedRoute';
import authlessRoutes from './authlessRoutes';
import { footerRoutes } from '../utils/homeUtils';

function makeProtected(routes) {
  routes.forEach((route) => (route.route = ProtectedRoute));
  return routes;
}
export default [
  ...authRoutes,
  ...onboardingRoutes,
  ...makeProtected(feedRoutes),
  ...makeProtected(postRoutes),
  ...authlessRoutes,
  ...footerRoutes,
  ...lastRoutes, // last routes always should be last.
];
