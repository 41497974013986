import http from './httpService';
import APIURL from '../utils/apiUrl';
import {
  similarContentMap,
  articleCategoryMap,
} from '../mappers/articleMapper';
import { defaultArticleCategory } from '../utils/constants';

function getArticleCategoriesFromLocalStorage() {
  return JSON.parse(localStorage.getItem('articleCategories'));
}
async function getArticleCategoriesFromServices() {
  const { data } = await http.post(APIURL.GET_ALL_CATEGORIES, {
    source: 'web',
  });
  const articleCategoriesObj = articleCategoryMap(
    data.data.ARTICLE_CATEGORY.POPULAR
  );
  const articleCategoriesTabs = [
    ...defaultArticleCategory,
    ...articleCategoriesObj,
  ];
  localStorage.setItem(
    'articleCategories',
    JSON.stringify(articleCategoriesTabs)
  );
  return articleCategoriesTabs;
}
function getArticleCategoryList() {
  const articleCategoryList = getArticleCategoriesFromLocalStorage();
  const data =
    articleCategoryList != null
      ? getArticleCategoriesFromLocalStorage()
      : getArticleCategoriesFromServices();
  return data;
}
async function getSimilarContent(subType, entityId) {
  const { data } = await http.post(APIURL.GET_SIMILAR_CONTENTS, {
    source: 'web',
    id: entityId,
    page_no: 1,
    page_size: 5,
    sub_type: subType,
  });
  return similarContentMap(data.docs);
}
export default {
  getArticleCategoriesFromLocalStorage,
  getArticleCategoriesFromServices,
  getArticleCategoryList,
  getSimilarContent,
};
