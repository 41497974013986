import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box, Grid } from '@material-ui/core';
import sheroesLogo from '../../img/sheroes-red-logo.svg';
import { footerLinkObj } from '../../utils/homeUtils';
import { useMobile, useTablet } from '../../hooks/utilHooks';
import AnyLink from '../common/anyLink';
import { socialLinkObj } from '../../utils/homeUtils';

const Footer = () => {
  const classes = useStyles();
  const isMobile = useMobile();
  const isTablet = useTablet();

  return (
    <React.Fragment>
      <Box className={classes.footerMain} pt={isMobile ? 4 : 8} px={4}>
        <Box className={isTablet ? 'width100' : 'mxAuto homeContainer'}>
          <Grid container>
            <Grid item xs={12} sm={4} md={4}>
              {!isMobile && (
                <Box>
                  <img src={sheroesLogo} alt="SHEROES" width="180px" />
                </Box>
              )}
              <Box
                className={classes.socialImg}
                justifyContent={isMobile ? 'center' : 'left'}
                pt={isMobile ? 0 : 4}
                mb={isMobile ? 4 : 6}
              >
                {socialLinkObj &&
                  socialLinkObj.map((item, index) => (
                    <AnyLink key={index} to={item.url}>
                      <img
                        src={item.imgUrl}
                        alt={item.altText}
                        className={classes.interestsIcon}
                      />
                    </AnyLink>
                  ))}
              </Box>
            </Grid>

            <Grid item xs={12} sm={8} md={8}>
              <Box className={classes.footerLink}>
                {footerLinkObj &&
                  footerLinkObj.map((item, index) => (
                    <Box
                      key={index}
                      display="inline-block"
                      mr={isTablet ? 2.5 : 11.5}
                    >
                      <AnyLink to={item.url}>
                        <Typography variant="body1" color="textPrimary">
                          {item.text}
                        </Typography>
                      </AnyLink>
                    </Box>
                  ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Typography
            variant={isTablet ? 'caption' : 'body1'}
            color="textPrimary"
            className={classes.footerBottomText}
          >
            Applied Life Private Limited © 2019 All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </React.Fragment>
  );
};
export default Footer;

const useStyles = makeStyles((theme) => ({
  footerMain: {
    background: '#eee',
  },
  socialImg: {
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: theme.spacing(9),
    },
  },
  footerLink: {
    '& a': {
      marginBottom: theme.spacing(3),
      display: 'inline-block',
    },
  },
  footerBottomText: {
    borderTop: '1px solid #bbb',
    display: 'block',
    textAlign: 'center',
    padding: theme.spacing(3, 0),
    marginTop: theme.spacing(1.5),
  },
}));
