import React, { useState, useEffect } from 'react';

/**
 * Show Loading fallback only if Suspense took more than 300ms
 * Source: https://stackoverflow.com/a/58971161/559680
 */
const DelayedFallback = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    let timeout = setTimeout(() => setShow(true), 300);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return show ? <h3>Loading ...</h3> : null;
};
export default DelayedFallback;
