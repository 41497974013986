import { action } from 'easy-peasy';
import authService from '../services/authService';

export default {
  showDeactivateModal: false,
  user: null,
  showModal: false,
  updateUser: action((state, payload) => {
    state.user = authService.getCurrentUser();
  }),
  updateUserDeactivateState: action((state, { showDeactivateModal }) => {
    state.showDeactivateModal = showDeactivateModal;
  }),
  userLoginModal: action((state, { showModal, referrerUrl }) => {
    state.showModal = showModal;
    authService.setReferrerUrl(referrerUrl);
  }),
};
