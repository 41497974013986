import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  Typography,
  Badge,
  Box,
  AppBar,
  Grid,
} from '@material-ui/core';
import notificationService from '../../services/notificationService';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useStoreState, useStoreActions } from 'easy-peasy';
import ToggleDrawer from './toggleDrawer';
import AdapterLink from '../common/adapterLink';
import { useLoggedInUser } from '../../hooks/userHooks';
import useReactRouter from 'use-react-router';
import CustomButton from '../common/customButton';
import { useMobile, useTablet } from '../../hooks/utilHooks';
import ShareCommunityIcon from '../../img/ic-share.svg';
import NotificationIcon from '../../img/notification-icon.svg';
import { useLastLocation } from 'react-router-last-location';
import LanguageMenu from './languageMenu';
import SheroesLogo from '../../img/logo.svg';
import AnyLink from '../common/anyLink';
import { ellipsize } from '../../utils/textUtil';
import BottomNavbar from '../bottomNavbar';
import { useFetchData } from '../../hooks/fetchDataHooks';
import SearchBar from '../common/searchBar';

const Navbar = () => {
  const lastLocation = useLastLocation();
  const { history } = useReactRouter();
  const hideNavBar = useStoreState((state) => state.navBar.options.hideNavBar);
  const handleGoBack = useStoreActions((state) => state.navBar.handleGoBack);
  const shareCommunity = useStoreActions(
    (actions) => actions.community.shareCommunity
  );
  const isMobile = useMobile();

  const isTablet = useTablet();
  const { setDialogBoxOpen, setDialogContent } = useStoreActions(
    (actions) => actions.dialogBox
  );
  const { showDialogOnBack, showBottomNavbar, showSearchBar } = useStoreState(
    (state) => state.navBar.options
  );

  const classes = useStyles();
  const user = useLoggedInUser();
  const options = useStoreState((state) => state.navBar.options);
  const {
    data: notificationsCount,
    setData: setNotificationsCount,
  } = useFetchData(user && notificationService.getNotificationCount, 0);

  const handleHideBell = () => {
    return setNotificationsCount(0);
  };
  function showDiscardModal() {
    setDialogBoxOpen(true);
    setDialogContent({
      dialogTitle: 'Discard Post',
      dialogContentText: 'Are you sure you want to discard your changes?',
      btnPrimaryText: 'No',
      btnSecondaryText: 'Yes',
    });
  }
  function handleBackNavigation() {
    showDialogOnBack
      ? showDiscardModal()
      : handleGoBack({
          history: history,
          lastLocation: lastLocation,
        });
  }

  return (
    <React.Fragment>
      {!hideNavBar && (
        <Box>
          <AppBar position="fixed" className={classes.navbarBorder}>
            <Box className="container" px={!isTablet ? 4 : ''}>
              <Grid container>
                {!isTablet && (
                  <Grid item sm={3} md={2} lg={3}>
                    <AnyLink to="/">
                      <Box pt={3.5} pl={isTablet ? 0 : 5}>
                        <img src={SheroesLogo} alt="SHEROES" />
                      </Box>
                    </AnyLink>
                  </Grid>
                )}
                <Grid item xs={12} sm={12} md={10} lg={9}>
                  <Toolbar className={classes.tollBar}>
                    <CustomButton
                      className={classes.menuStyle}
                      onClick={() => options.showBack && handleBackNavigation()}
                      icon={
                        options.showBack ? (
                          <ArrowBackIcon />
                        ) : (
                          options.toggleDrawerIcon &&
                          isTablet && (
                            <ToggleDrawer
                              direction={'left'}
                              isCommunity={false}
                            />
                          )
                        )
                      }
                    ></CustomButton>

                    <Typography
                      variant="h6"
                      color="inherit"
                      className={classes.title}
                    >
                      {ellipsize(options.title, isMobile ? 18 : 60)}
                    </Typography>

                    {!showSearchBar && user && !isTablet && (
                      <SearchBar
                        handleSubmit={(value) =>
                          history.push('/search?q=' + value)
                        }
                        fixRight={true}
                      />
                    )}

                    <LanguageMenu />
                    {user && (
                      <React.Fragment>
                        {options.showBell && !isTablet && (
                          <Badge
                            to="/notifications"
                            component={AdapterLink}
                            badgeContent={notificationsCount}
                            classes={{ badge: classes.customBadge }}
                            onClick={handleHideBell}
                            className={classes.notificationBell}
                          >
                            <img
                              src={NotificationIcon}
                              alt="NotificationIcon"
                            />
                          </Badge>
                        )}
                        {options && options.topRightButton}

                        {options.showCommunityShareIcon && (
                          <CustomButton
                            className={classes.shareButton}
                            onClick={shareCommunity}
                            icon={<img src={ShareCommunityIcon} alt="Share" />}
                          />
                        )}

                        {options.showCommunityIcon && isTablet && (
                          <ToggleDrawer
                            direction={'right'}
                            isCommunity={true}
                          />
                        )}
                      </React.Fragment>
                    )}
                  </Toolbar>
                </Grid>
              </Grid>
            </Box>
          </AppBar>
          <Box mb={isMobile ? 14 : 14} />
          {showBottomNavbar && isTablet && (
            <BottomNavbar
              handleBackNavigation={handleBackNavigation}
              options={options}
            />
          )}
        </Box>
      )}
    </React.Fragment>
  );
};
const useStyles = makeStyles((theme) => ({
  title: {
    flexGrow: 1,
    textDecoration: 'none',
  },
  notificationBell: {
    color: 'inherit',
  },
  customBadge: {
    backgroundColor: '#FF0',
    color: theme.palette.text.primary,
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
  },
  menuStyle: {
    minWidth: 'auto',
    padding: 0,
    margin: theme.spacing(1, 2, 0, 0),
    color: 'white',
  },
  navbarBorder: {
    boxShadow: 'inherit',
  },
  shareButton: {
    padding: theme.spacing(0),
    minWidth: 'inherit',
    marginLeft: theme.spacing(4),
  },
  tollBar: {
    minHeight: '50px',
  },
  buttonBackground: {
    background: 'rgb(0,0,0, 0.295596)',
    borderRadius: '50%',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    width: '28px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

export default Navbar;
