import * as Sentry from '@sentry/browser';
import googleAnalytics from 'react-ga';
import CleverTap from '../services/clevertapService';
import authService from '../services/authService';

const clevertapAccountId = process.env.REACT_APP_CLEVERTAP_ACCOUNT_ID;
const sentryUrl = process.env.REACT_APP_SENTRY_URL;
const gaTrackingId = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

function initializeServices() {
  if (process.env.NODE_ENV === 'production') {
    googleAnalytics.initialize(gaTrackingId);
    Sentry.init({ dsn: sentryUrl });
    const user = authService.getCurrentUser();
    user &&
      Sentry.setUser({
        email: user.emailId,
        id: user.id,
        userType: user.userType,
        name: user.name,
      });
  }
  CleverTap.initialize(clevertapAccountId);
}

function AppShare(options) {
  if (navigator.share) {
    navigator
      .share({
        title: options.title,
        text: options.text,
        url: options.url,
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  } else {
  }
}

function addToHomeScreen(promptTriggered) {
  let deferredPrompt;
  // const btnAdd = document.getElementById('installButton');
  // console.log(btnAdd);

  window.addEventListener('beforeinstallprompt', function (e) {
    e.preventDefault();
    // Stash the event so it can be triggered later.
    deferredPrompt = e;
    // Update UI notify the user they can add to home screen
    //btnAdd.style.display = 'block';
    return false;
  });

  if (promptTriggered) {
    // btnAdd.style.display = 'none'; //
    deferredPrompt.prompt();

    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        // btnAdd.style.display = 'none'; //
      } else {
        // btnAdd.style.display = 'block'; //
      }
      deferredPrompt = null;
    });
  }
}

export { addToHomeScreen, AppShare, initializeServices, isLocalhost };
