import React from 'react';
import { Box } from '@material-ui/core';
import NavigationMenu from '../sideMenu/navigationMenu';
import MyCommunitiesDrawer from '../community/myCommunitiesDrawer';

const DrawerContent = ({ isCommunity, isThemeDrawer, component }) => {
  return (
    <Box>
      {isCommunity ? (
        <MyCommunitiesDrawer />
      ) : !isThemeDrawer ? (
        <NavigationMenu />
      ) : (
        ''
      )}
      {isThemeDrawer && !isCommunity && component}
    </Box>
  );
};

export default DrawerContent;
