import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AdapterLink from '../common/adapterLink';
import { useTablet } from '../../hooks/utilHooks';
import {
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

const SideBarMenuItem = ({
  stickToBottom,
  text,
  path,
  icon,
  handleClick,
  externalLinkUrl,
}) => {
  const classes = useStyles();
  const isTablet = useTablet();

  const listItemContent = (
    <React.Fragment>
      <Box className={classes.itemMain}>
        <ListItemIcon color="secondary" className={classes.icon}>
          {icon}
        </ListItemIcon>
        <ListItemText className={classes.iconTextSet}>
          <Typography
            display="inline"
            variant={isTablet ? 'body1' : 'subtitle2'}
          >
            {text}
          </Typography>
        </ListItemText>
      </Box>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {externalLinkUrl && (
        <ListItem>
          <a
            rel="noopener noreferrer"
            target="_blank"
            className={classes.sideMenuText}
            href={externalLinkUrl}
          >
            {listItemContent}
          </a>
        </ListItem>
      )}
      {!externalLinkUrl && (
        <ListItem
          component={path && AdapterLink}
          to={path}
          onClick={handleClick}
        >
          {listItemContent}
        </ListItem>
      )}
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  sideMenuText: {
    textDecoration: 'none',
    display: 'flex',
  },
  stickToBottom: {
    position: 'fixed',
    bottom: 0,
  },
  icon: {
    minWidth: theme.spacing(8),
    marginRight: theme.spacing(2),
  },
  iconTextSet: {
    margin: theme.spacing(0),
  },
  itemMain: {
    marginBottom: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
  },
}));
export default SideBarMenuItem;
