import React from 'react';
import { List } from '@material-ui/core';
import SideBarMenuItem from './sideBarMenuItem';
import { addToHomeScreen } from '../../utils/appUtil';
import HomeIcon from '@material-ui/icons/Home';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
//import TermsAndCondIcon from '../../img/tnc.svg';
import AddHomeScreenIcon from '../../img/addhome.svg';
import CommunityGuidelinesIcon from '../../img/community-guidelines.svg';
import LogoutIcon from '../../img/logout.svg';
import CommunityIcon from '../../img/sidebar-community-icon.svg';
import ArticlesIcon from '../../img/sidebar-articles-icon.svg';
import HelplineIcon from '../../img/helpline-icon.svg';
import { useLoggedInUser } from '../../hooks/userHooks';
import SideBarProfileSection from './sideBarProfileSection';
import { useTablet } from '../../hooks/utilHooks';
import imgParams from '../../utils/imageUtil';
import { NavLink } from 'react-router-dom';
import { isAdminOrModerator } from '../../utils/userUtil';
import AnyLink from '../common/anyLink';
import { SHEROES_ADMIN_URL } from '../../utils/constants';

const isIosDevice =
  !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

const NavigationMenu = () => {
  const user = useLoggedInUser();
  const isTablet = useTablet();
  return (
    <React.Fragment>
      {isTablet && <SideBarProfileSection />}
      <List component="nav" className="navLink">
        <NavLink to="/feed">
          <SideBarMenuItem text="Feed" icon={<HomeIcon />} />
        </NavLink>

        <NavLink to="/communities">
          <SideBarMenuItem
            text="Communities"
            icon={<img src={CommunityIcon} alt="Community" />}
          />
        </NavLink>

        <NavLink to="/articles">
          <SideBarMenuItem
            text="Articles"
            icon={<img src={ArticlesIcon} alt="Article" />}
          />
        </NavLink>

        <NavLink to="/community-guidelines">
          <SideBarMenuItem
            text="Community Guidelines"
            icon={
              <img src={CommunityGuidelinesIcon} alt="Community Guideline" />
            }
          />
        </NavLink>
        <NavLink to="/Home/advertorial-policy">
          <SideBarMenuItem
            text="Advertorial Policy"
            icon={
              <img src={CommunityGuidelinesIcon} alt="Advertorial Policy" />
            }
          />
        </NavLink>

        {/* <NavLink to="/Home/tnc">
          <SideBarMenuItem
            text="Terms & Conditions"
            icon={<img src={TermsAndCondIcon} alt="TnC Icon" />}
          />
        </NavLink> */}

        {user && (
          <NavLink to="/helpline">
            <SideBarMenuItem
              text="Helpline"
              icon={<img src={HelplineIcon} alt="Helpline" />}
            />
          </NavLink>
        )}

        {!isIosDevice && isTablet && (
          <SideBarMenuItem
            text="Add to Home screen"
            handleClick={() => addToHomeScreen(true)}
            icon={<img src={AddHomeScreenIcon} alt="Home" />}
          />
        )}

        {isAdminOrModerator() && (
          <AnyLink to={SHEROES_ADMIN_URL + '/landing/landingadmin'}>
            <SideBarMenuItem text="Admin" icon={<SupervisorAccountIcon />} />
          </AnyLink>
        )}

        {!isTablet && user && (
          <NavLink to={user.userProfileLink}>
            <SideBarMenuItem
              text="Profile"
              icon={
                <img
                  src={imgParams(user.image, 40, 40, 'auto')}
                  className="navProfileImg"
                  alt="user"
                />
              }
            />
          </NavLink>
        )}
        {user && (
          <NavLink to="/logout">
            <SideBarMenuItem
              text="Logout"
              stickToBottom={isTablet ? true : false}
              icon={<img src={LogoutIcon} alt="Logout" />}
            />
          </NavLink>
        )}
      </List>
    </React.Fragment>
  );
};
export default NavigationMenu;
