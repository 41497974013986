import httpService from './httpService';
import apiUrl from '../utils/apiUrl';

async function getRemoteConfigFromServer() {
  const { data } = await httpService.get(apiUrl.APP_CONFIG, {});

  data &&
    data.configuration.config_data &&
    localStorage.setItem(
      'appConfig',
      JSON.stringify(data.configuration.config_data)
    );
}

function getRemoteConfig() {
  return JSON.parse(localStorage.getItem('appConfig'));
}

export { getRemoteConfigFromServer, getRemoteConfig };
