import { action, thunk, thunkOn } from 'easy-peasy';
import feedService from '../services/feedService';
import remove from 'lodash/remove';
import { CellMeasurerCache } from 'react-virtualized';

export function initFeed() {
  return {
    docs: [],
    nextToken: null,
    status: '',
    setOrderKey: null,
    feedConfigVersion: null,
    scrollPosition: 0,
    feedCache: {
      cacheObj: new CellMeasurerCache({
        fixedWidth: true,
        defaultHeight: 300,
      }),
      listRef: null,
    },
    showFab: true,
  };
}
export default {
  //Feeds is a map where the key is the feedStreamApi url and value is the above initFeed Obj. For different streams new feed is created & stored in globalstate.
  //Ex. feeds['participant/feed/stream'] = {docs:[],...}
  feeds: {},
  currentApi: '',
  isFeedLoading: false,
  //use this action inorder to stop multiple stream calls to indicate an inflight request.
  setLoading: action((state, isLoading) => {
    // console.log('isLoading:', isLoading);
    state.isFeedLoading = isLoading;
  }),
  //create the feed store for any stream if its not already created.
  createFeed: action((state, feedStreamApi) => {
    state.currentApi = feedStreamApi;
    if (!state.feeds[feedStreamApi]) {
      state.feeds[feedStreamApi] = initFeed();
    }
  }),
  renderFab: action((state, { showFab, streamUrl }) => {
    state.feeds[streamUrl].showFab = showFab;
  }),
  setScrollPostId: action((state, postId) => {
    const currentFeed = state.feeds[state.currentApi];
    const postPosition =
      postId && currentFeed && currentFeed.docs.indexOf(postId);
    state.feeds[state.currentApi].scrollPosition = postPosition
      ? postPosition
      : 0;
  }),
  setCacheListRef: action((state, ref) => {
    if (ref && state.feeds[state.currentApi])
      state.feeds[state.currentApi].feedCache.listRef = ref;
  }),
  updateItemCardHeight: action(
    (state, { postId, clearAll, shouldRecompute }) => {
      const currentFeed = state.feeds[state.currentApi];
      const postPosition = currentFeed ? currentFeed.docs.indexOf(postId) : -1;
      //If any action happens on a post card apart from delete.. Height for that UI card is recomputed.
      if (postPosition > -1 && shouldRecompute && currentFeed) {
        currentFeed.feedCache.cacheObj.clear(postPosition);
        currentFeed.feedCache.listRef.recomputeRowHeights(postPosition);
      }
      //If a post is deleted or created, as the card is removed from feed, gaps are formed. So we are clearing the entire cache for that feed.
      clearAll && currentFeed && currentFeed.feedCache.cacheObj.clearAll(0);
    }
  ),
  setFeedState: action(
    (state, { docs, nextToken, setOrderKey, feedConfigVersion, status }) => {
      docs.map((doc) => {
        const feedDocs = state.feeds[state.currentApi].docs;
        return feedDocs.indexOf(doc.id) < 0 ? feedDocs.push(doc.id) : feedDocs;
      });
      state.feeds[state.currentApi].nextToken = nextToken;
      state.feeds[state.currentApi].setOrderKey = setOrderKey;
      state.feeds[state.currentApi].status = status;
      state.feeds[state.currentApi].feedConfigVersion = feedConfigVersion;
    }
  ),
  updateFeedState: action((state, feedDocs) => {
    const currentFeed = state.feeds[state.currentApi];
    if (currentFeed) currentFeed.docs = [...feedDocs];
  }),
  removePostFromFeedState: action((state, postId) => {
    remove(state.feeds[state.currentApi].docs, (item) => {
      return item === postId;
    });
  }),
  fetchFeed: thunk(
    async (
      actions,
      { feedStreamApi, isGetRequest },
      { getState, getStoreActions }
    ) => {
      actions.createFeed(feedStreamApi);
      const isLoading = getState().isFeedLoading;
      // console.log('isLoading:', isLoading);
      if (isLoading) return;
      actions.setLoading(true);
      const currentToken = getState().feeds[feedStreamApi].nextToken;
      const {
        docs,
        nextToken,
        feedConfigVersion,
        setOrderKey,
        status,
      } = await feedService.getFeedStream(
        feedStreamApi,
        currentToken,
        isGetRequest
      );
      // console.log('docs:', docs);
      getStoreActions().posts.setPostState(docs);
      actions.setFeedState({
        docs,
        nextToken,
        feedConfigVersion,
        setOrderKey,
        status,
      });
      actions.setLoading(false);
    }
  ),
  //Listeners.
  onPostAction: thunkOn(
    (actions, storeActions) =>
      storeActions.posts && [storeActions.posts.updatePostState],
    (actions, target) => {
      actions.updateItemCardHeight({
        postId: target.payload.id || target.payload.postId,
        shouldRecompute: target.payload.shouldRecompute,
      });
      target.payload.shouldRecompute && delete target.payload.shouldRecompute;
      if (target.payload.isNewPost) {
        actions.updateFeedState([target.payload.id]);
        actions.updateItemCardHeight({
          clearAll: true,
        });
        delete target.payload.isNewPost;
      }
    }
  ),
  onPostDelete: thunkOn(
    (actions, storeActions) =>
      storeActions.posts && [
        storeActions.posts.deletePost,
        storeActions.posts.approveOrDeletePost,
      ],
    (actions, target) => {
      if (target.payload.is_approved) {
        return;
      }
      actions.updateItemCardHeight({
        clearAll: true,
      });
      actions.removePostFromFeedState(target.payload.postId);
    }
  ),
};
