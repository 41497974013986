import axios from 'axios';
import axiosUtil from '../utils/axiosUtil';

//default axios.
axios.interceptors.response.use(axiosUtil.responseInterceptor);

export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};
