import http from './httpService';
import APIURL from '../utils/apiUrl';
import { userMap, userErrorMap } from '../mappers/modelMapper';
import { setOnboardingStatus } from './onboardingService';
import analytics from './analyticsService';
import { setCookie, getCookie, deleteCookie } from '../utils/cookies';
import { getUserDetailsByToken } from './userService';
import { cookieOptions } from '../utils/constants';

const userKey = 'user';
const deactivatedUserKey = 'deactivatedUser';
const deactivatedUserMessageKey = 'deactivatedUserMessage';
http.setUserToken(getUserToken());

export async function login(username, password) {
  const { data } = await http.post(APIURL.SIGN_IN_BY_EMAIL, {
    advertisementid: 'string',
    deviceid: '1e62a780a8fc3268',
    devicetype: 'htc HTC Desire 626 dual sim',
    gcmorapnsid: '5.0.7',
    password,
    username,
    source: 'web',
  });

  return processLoginResponse(data, 'emailId');
}

export function logout() {
  analytics.reset();
  localStorage.clear();
  deleteCookie(cookieOptions.AUTH_TOKEN);
  deleteCookie(cookieOptions.USER_ID);
  deleteCookie(cookieOptions.LOGIN_EVENT_FIRED);
}

export function getCurrentUser() {
  try {
    const userStr = localStorage.getItem(userKey);
    return JSON.parse(userStr);
  } catch (ex) {
    return null;
  }
}

export function setCurrentUser(data) {
  localStorage.setItem(userKey, JSON.stringify(data));
  setCookie(cookieOptions.USER_ID, data.id, cookieOptions.COOKIE_EXPIRE_DAYS);
}

export function getUserToken() {
  return getCookie(cookieOptions.AUTH_TOKEN);
}

export function setUserAuthToken(data) {
  setCookie(cookieOptions.AUTH_TOKEN, data, cookieOptions.COOKIE_EXPIRE_DAYS);
}

export async function socialLogin(token, type) {
  let loginAPIUrl = null;
  loginAPIUrl =
    type === 'facebook' ? APIURL.SIGN_IN_BY_FACEBOOK : APIURL.SIGN_IN_BY_GOOGLE;
  const { data } = await http.post(loginAPIUrl, {
    advertisementid: 'string',
    deviceid: '1e62a780a8fc3268',
    devicetype: 'htc HTC Desire 626 dual sim',
    gcmorapnsid: '5.0.7',
    source: 'web',
    accees_token: token,
  });
  return processLoginResponse(data, type);
}

function processLoginResponse(data, signupType) {
  if (data.status === 'SUCCESS') {
    const responseData = userMap(data);
    setOnboardingStatus(responseData.isOnboardingDone);
    setUserAuthToken(responseData.token);
    setCurrentUser(responseData);
    const referrerUrl = getReferrerUrl();
    document.location = referrerUrl || responseData.nextScreenUrl;
    if (referrerUrl !== null) localStorage.removeItem('referrerUrl');
    return responseData;
  } else {
    const responseData = userErrorMap(data);
    if (data.fieldErrorMessageMap.IS_DEACTIVATED) {
      localStorage.setItem(deactivatedUserKey, responseData.isDeactivated);
      localStorage.setItem(deactivatedUserMessageKey, responseData.message);
      document.location = responseData.nextScreenUrl;
    }
    return responseData;
  }
}

export function isUserDeactivated() {
  return localStorage.getItem(deactivatedUserKey);
}
export function getUserDeactivateMessage() {
  const userDeactivatedMessage = localStorage.getItem(
    deactivatedUserMessageKey
  );
  return userDeactivatedMessage;
}

/**
 * If token is there, but user object not there.
 * Fetch user object from server (Login from old sheroes.com)
 * @param {*} currentPath
 */
async function loginUserByToken(currentPath) {
  const token = getUserToken();
  if (!token || getCurrentUser()) return;
  const data = await getUserDetailsByToken(token);
  setCurrentUser(data);
  setUserAuthToken(token);
  setOnboardingStatus(true);
  document.location = currentPath;
}
export function setReferrerUrl(url) {
  localStorage.setItem('referrerUrl', url);
}
export function getReferrerUrl() {
  const referrerUrl = localStorage.getItem('referrerUrl');
  return referrerUrl;
}
export function setLoginEventFired() {
  setCookie(
    cookieOptions.LOGIN_EVENT_FIRED,
    true,
    cookieOptions.COOKIE_EXPIRE_DAYS
  );
}
export function getLoginEventFired() {
  return getCookie(cookieOptions.LOGIN_EVENT_FIRED);
}
export function setPostLanguage(language) {
  localStorage.setItem('postLanguage', language);
}
export function getPostLanguage() {
  return localStorage.getItem('postLanguage');
}

export default {
  login,
  logout,
  getCurrentUser,
  getUserToken,
  socialLogin,
  getUserDeactivateMessage,
  isUserDeactivated,
  setCurrentUser,
  setUserAuthToken,
  loginUserByToken,
  setReferrerUrl,
  getReferrerUrl,
  setLoginEventFired,
  getLoginEventFired,
  setPostLanguage,
  getPostLanguage,
};
