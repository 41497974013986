import uuid from 'uuid';
import http from './httpService';
import { notificationMap } from '../mappers/modelMapper';
import APIURL from '../utils/apiUrl';

async function getNotificationCount() {
  const requestBodyCount = {};
  try {
    const { data } = await http.post(
      APIURL.BELL_NOTIFICATION_COUNT,
      requestBodyCount
    );
    return data.unreadNotificationCount;
  } catch (ex) {
    return null;
  }
}

async function getNotifications(pageNumber) {
  const requestBody = { page_size: 20 };
  let notificationDocs = {};
  requestBody.page_no = pageNumber;
  try {
    const { data } = await http.post(APIURL.BELL_NOTIFICATION, requestBody);
    //return data.solr_ignore_notification_responses;
    notificationDocs.docs = data.solr_ignore_notification_responses.map((doc) =>
      notificationMap(doc.notification)
    );
    return notificationDocs.docs;
  } catch (ex) {
    return null;
  }
}
function getUuid() {
  return localStorage.getItem('device_guid');
}
function setUuid(uuid) {
  localStorage.setItem('device_guid', uuid);
}

async function savePushNotificationToken(token) {
  const deviceGuid = getUuid() ? getUuid() : uuid();

  const request = {
    device_guid: deviceGuid,
    device_id: 'web-pwa',
    device_type: 'android',
    push_token: token,
    device_name: 'web',
    is_logged_out: false,
    locale: 'eng/en',
    time_zone: 'usa',
    app_version: '8.2.3',
  };
  try {
    const { data } = await http.post(APIURL.SAVE_PUSH_TOKEN, request);
    console.log('data:', data);
    setUuid(deviceGuid);
    return data;
  } catch (ex) {
    return null;
  }
}

async function markAsRead(id) {
  try {
    const { data } = await http.post(APIURL.NOTIFICATION_MARK_AS_READ, {
      notification_id: id,
    });
    return data;
  } catch (ex) {
    return null;
  }
}
export default {
  getNotificationCount,
  getNotifications,
  savePushNotificationToken,
  markAsRead,
};
