import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
} from '@material-ui/core';
import Loader from '../components/common/loader';
import { useGetPost } from '../hooks/postHooks';

import PostEngagement from '../components/post/postEngagement';
import { makeStyles } from '@material-ui/styles';
import { useNavBar } from '../hooks/navBarHooks';
import { useScreenOpenTracker } from '../hooks/analyticsHook';
import imgParams from '../utils/imageUtil';
import BorderLine from '../components/common/borderLine';
import articleService from '../services/articleService';
import SimilarArticles from '../components/article/similarArticles';
import { ReactComponent as CommentIcon } from '../img/comment.svg';
import { scrollToPosition } from '../utils/postUtil';
import { useTablet, useDimensions } from '../hooks/utilHooks';
import MetaData from '../components/common/metaData';
import ArticleDetailHeader from '../components/article/articleDetailHeader';
import ArticleDetailCardContent from '../components/article/articleDetailCardContent';
import ArticleSchema from '../components/post/articleSchema';
import { Redirect } from 'react-router-dom';

const ArticleDetail = ({ match, location }) => {
  const [similarData, setSimilarData] = useState([]);
  const { width, ref } = useDimensions();
  useNavBar({
    showBack: true,
    rightSideBar: (
      <Box px={4} py={2}>
        <SimilarArticles similarData={similarData} />
      </Box>
    ),
  });
  const postId = location.state && location.state.postId;
  const postEntityId = atob(decodeURIComponent(match.params.articleId));
  const postSubType = 'A';
  const focusComment = location.focusComment;
  const classes = useStyles();
  const { post } = useGetPost(postId, postEntityId, postSubType);

  const articleEntityId = post && post.id;
  const articleRef = React.useRef();
  useScreenOpenTracker(
    {
      screenName: 'Article Details Screen',
      id: postEntityId,
      title: post && post.title,
    },
    post ? true : false
  );
  const handleClickToScroll = (event) => {
    scrollToPosition(articleRef);
  };

  useEffect(() => {
    async function getSimilarArticles() {
      try {
        const similarArticles = await articleService.getSimilarContent(
          'A',
          articleEntityId
        );
        setSimilarData(similarArticles);
      } catch (error) {
        setSimilarData(null);
      }
    }
    articleEntityId && getSimilarArticles();
  }, [articleEntityId]);

  const isTablet = useTablet();

  if (post && !post.isActive) {
    return <Redirect to="/not-found" />;
  }

  return (
    <React.Fragment>
      {post && (
        <MetaData
          title={post.metaTitle || post.title}
          description={post.metaDescription || post.title}
          ogUrl={post.deepLinkUrl}
          ogImageUrl={post.articleImageUrl}
        />
      )}

      <Box width={1} maxWidth="sm" mb={20} mt={2} bgcolor="white">
        {post && !post.id ? <Loader key={1} /> : ''}
        {post && post.id && (
          <React.Fragment>
            <ArticleDetailHeader post={post} />
            <Box mt={3} px={4}>
              <img
                ref={ref}
                className={classes.media}
                src={imgParams(post.articleImageUrl, width)}
                alt={post.title}
              />
            </Box>
            <ArticleDetailCardContent post={post} />
            <ListItem alignItems="flex-start">
              <ListItemAvatar>
                <Avatar
                  src={imgParams(
                    post.author && post.author.imageUrl,
                    40,
                    40,
                    'auto'
                  )}
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  <Typography variant="body2" color="textPrimary">
                    {post.author && post.author.name}
                  </Typography>
                }
                secondary={
                  <Typography variant="subtitle1" color="textSecondary">
                    {post.author && post.author.description}
                  </Typography>
                }
              />
            </ListItem>
            <Box
              px={4}
              py={4}
              display="flex"
              alignItems="center"
              onClick={handleClickToScroll}
              ref={articleRef}
            >
              <CommentIcon className={classes.setCommentIcon} />
              <Typography variant="body2">
                {isTablet ? 'See comments' : 'Comments'}
              </Typography>
            </Box>
            {isTablet && (
              <Box px={4}>
                <SimilarArticles similarData={similarData} />
              </Box>
            )}
            <BorderLine color="grey.600" />
            <Box ref={articleRef} />
            <PostEngagement
              isPostDetail={false}
              focusComment={focusComment}
              post={post}
              isArticleDetail={true}
            />
            {post && <ArticleSchema post={post} />}
          </React.Fragment>
        )}
      </Box>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  media: {
    width: '100%',
    height: 'auto',
    borderRadius: theme.spacing(3),
  },
  setCommentIcon: {
    marginRight: theme.spacing(2),
  },
}));

export default ArticleDetail;
