import React from 'react';
import { lazyPreload } from '../utils/lazyUtil';
import ArticleDetail from '../scenes/articleDetail';
const PostDetail = lazyPreload(() => import('../scenes/postDetail'));
const CommunityDetail = lazyPreload(() => import('../scenes/communityDetail'));
const Articles = lazyPreload(() => import('../scenes/articles'));
const MenuScene = lazyPreload(() => import('../scenes/menuScene'));

//Path for logout user page access
export default [
  {
    path: '/articles/:articleTitleSlug/:articleId',
    component: ArticleDetail,
  },
  {
    path: '/communities/:communityNameSlug/:communityId/:postId',
    component: PostDetail,
  },
  {
    path: '/communities/:communityNameSlug/:communityId',
    render: (props) => (
      <CommunityDetail {...props} setOrderName="CommunityFeed" />
    ),
  },
  {
    path: '/articles/category/:categorySlug/:categoryId',
    component: Articles,
  },
  {
    path: '/articles',
    component: Articles,
  },
  {
    path: '/articles/trending',
    component: Articles,
  },
  {
    path: '/menu',
    component: MenuScene,
  },
];
