import { action } from 'easy-peasy';
import { urlMap } from '../utils/constants';

const defaultNavBar = {
  title: '',
  showBack: false,
  showBell: true,
  showDialogOnBack: false,
  topRightButton: '',
  hideNavBar: false,
  showSearchBar: false,
  showCommunityIcon: false,
  showCommunityShareIcon: false,
  toggleDrawerIcon: false,
  showCommunitySideBar: true,
  showBottomNavbar: false,
  showMenuSideBar: true,
  topSpace: '0px',
  rightSideBar: null,
  disableCustomSideBarGrid: false,
};

export default {
  options: defaultNavBar,
  setTitle: action((state, payload) => {
    state.options.title = payload;
  }),
  setOptions: action((state, payload) => {
    state.options = { ...defaultNavBar, ...payload };
  }),
  notificationsCount: null,
  handleGoBack: action((state, { history, lastLocation, goBack = true }) => {
    if (goBack) {
      if (lastLocation && lastLocation.pathname) {
        history.goBack();
      } else {
        history.replace(urlMap.FEED);
      }
    }
  }),
};
