import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStoreActions } from 'easy-peasy';
import { useCreatePost } from '../../hooks/createPostHook';
import { getDateFormatByValue, DAY_FIRST_FORMAT } from '../../utils/dateUtil';

const SchedulePostMessage = ({ scheduleDate }) => {
  const { communityName, handleSavePost } = useCreatePost();
  const { setDialogBoxOpen, setDialogContent } = useStoreActions(
    (actions) => actions.dialogBox
  );
  async function handleSaveSchedulePost() {
    handleSavePost({ scheduleDate });
  }
  const handleOpenCalendar = () => {
    setDialogBoxOpen(true);
    setDialogContent({
      dialogTitle: 'Select Date',
      dialogContentText: null,
      btnPrimaryText: '',
      btnSecondaryText: '',
      showCalendar: true,
      isAdminPost: true,
    });
  };
  return (
    <React.Fragment>
      <Box mb={4} mt={5}>
        <Typography>
          You are scheduling a post for <b>{communityName}</b> Community to be
          posted on
          <b> {getDateFormatByValue(scheduleDate, DAY_FIRST_FORMAT)}.</b>
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="body2"
          component="span"
          onClick={() => handleOpenCalendar()}
        >
          CANCEL &nbsp;
        </Typography>
        <Typography
          variant="body2"
          component="span"
          color="primary"
          onClick={() => handleSaveSchedulePost()}
        >
          &nbsp; OK
        </Typography>
      </Box>
    </React.Fragment>
  );
};

export default SchedulePostMessage;
