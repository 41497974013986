import React from 'react';
import { Box } from '@material-ui/core';
import NoResultsIcon from '../img/search-results.svg';

const NotFound = ({ icon, title, description }) => {
  return (
    <Box align="center" py={4} px={4}>
      <img src={icon || NoResultsIcon} alt="not found" />
      <h1>{title || 'Not found'}</h1>
      <p>{description}</p>
    </Box>
  );
};

export default NotFound;
