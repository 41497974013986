import React from 'react';
import { Typography, Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import auth from '../../services/authService';
import facebookBtn from '../../img/onboarding/facebook-round.svg';

const FacebookLoginButton = ({ setIsLoading }) => {
  const classes = useStyles();
  async function responseFacebook(response) {
    setIsLoading(true);
    const accessToken = response.accessToken;
    await auth.socialLogin(accessToken, 'facebook');
  }
  const appId = process.env.REACT_APP_FACEBOOK_APP_ID;
  return (
    <React.Fragment>
      <FacebookLogin
        appId={appId}
        autoLoad={false}
        fields="name,email,picture"
        scope="public_profile"
        callback={responseFacebook}
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            className={classes.socialLoginButton}
          >
            <Box width={20}>
              <img
                src={facebookBtn}
                alt="Continue with Facebook"
                className={classes.socialIcon}
              />
            </Box>
            <Typography
              variant="body2"
              component="span"
              className={classes.buttonTitle}
            >
              Continue with Facebook
            </Typography>
          </Button>
        )}
      />
    </React.Fragment>
  );
};
export default FacebookLoginButton;
const useStyles = makeStyles((theme) => ({
  socialLoginButton: {
    marginBottom: theme.spacing(3),
    background: '#3b5998',
    padding: theme.spacing(2),
    color: ' #fff',
    textTransform: 'inherit',
    width: '75%',
    justifyContent: 'left',
    '&:hover': {
      background: '#3b5998',
    },
  },
  socialIcon: {
    verticalAlign: 'middle',
  },
  buttonTitle: {
    paddingLeft: theme.spacing(4),
  },
}));
