import React from 'react';
import { Box, Menu, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { vernacularLanguageList } from '../../utils/constants';
import { setPostLanguage, getPostLanguage } from '../../services/authService';

const LanguageMenu = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const selectedLanguage = getPostLanguage();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const setLanguage = (selectedLanguage) => {
    setPostLanguage(selectedLanguage);
    setAnchorEl(null);
    window.location.reload();
  };
  return (
    <React.Fragment>
      <Box className={classes.customLanguageBadge}>
        <Box onClick={handleClick}>
          {selectedLanguage && selectedLanguage.toUpperCase()}
        </Box>
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {vernacularLanguageList &&
          vernacularLanguageList.map((option) => (
            <MenuItem
              key={option.key}
              className={
                option.key === selectedLanguage
                  ? `${classes.menuItem} ${classes.selectedMenu}`
                  : `${classes.menuItem}`
              }
              onClick={() => setLanguage(option.key)}
            >
              {option.value} {option.name}
            </MenuItem>
          ))}
      </Menu>
    </React.Fragment>
  );
};
const useStyles = makeStyles((theme) => ({
  customLanguageBadge: {
    background: 'rgb(0,0,0, 0.295596)',
    borderRadius: '50%',
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    width: '28px',
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  menuItem: {
    minHeight: '30px',
  },
  selectedMenu: {
    background: theme.palette.grey['200'],
  },
}));
export default LanguageMenu;
