const registerFirebaseServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    const firebaseWorkerUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js`;
    window.addEventListener('load', function () {
      navigator.serviceWorker
        .register(firebaseWorkerUrl)
        .then(function (registration) {
          //console.log('Registration successful, scope is:', registration.scope);
        })
        .catch(function (err) {
          console.log('Service worker registration failed, error:', err);
        });
    });
  }
};

export { registerFirebaseServiceWorker };
