import { default as _map } from 'lodash/map';
import { stripDomain, getUserProfileLink } from '../utils/postUtil';
import { renderMention } from '../utils/editorUtil';
import authService from '../services/authService';
import { momentTimeFormat } from '../utils/textUtil';
import { articleMap } from '../mappers/articleMapper';
import { SHEROES_ADMIN_URL } from '../utils/constants';

import {
  urlMap,
  onboardingSteps,
  userDeactivatedUrl,
  imageUrls,
} from '../utils/constants';
import { getRemoteConfig } from '../services/remoteConfigService';
import { communityItemMap } from '../mappers/communityMapper';
import {
  checkUserType,
  isAdminOrModerator,
  hideNotificationByCategory,
  getUserLinkUrl,
} from '../utils/userUtil';
import { userType } from '../utils/constants';

const appConfig = getRemoteConfig();
const userTypeMap = {
  1: 'Customer',
  2: 'Admin',
  3: 'Company',
  4: 'Content Writer',
  7: 'Mentor',
  9: 'Campus Sheroes',
  10: 'Public Profile',
  11: 'Sheroes BlogService',
  12: 'MARS Project Manager',
};

function postMap(doc) {
  const subType = doc.sub_type;
  const postTypeMap = {
    PL: pollMap,
    P: basePostMap,
    C: communityMap,
    H: challengeMap,
    A: articleMap,
    I: promocardMap,
    W: carouselMap,
    V: videoMap,
    LB: basePostMap,
    U: userSearchMap,
  };

  return postTypeMap[subType](doc);
}

function userSearchMap(doc) {
  const userObj = {
    id: doc.id,
    name: doc.name,
    entityId: doc.id_of_entity_or_participant,
    imageUrl: doc.image_url,
    userSlug: stripDomain(doc.solr_ignore_deep_link_url),
    location: doc.city_name_s || doc.location_s,
    isFollowed:
      doc.solr_ignore_is_user_followed || doc.solr_ignore_is_mentor_followed,
    userTypeId: userTypeMap[doc.entity_or_participant_type_id_i],
  };
  return basePostMap(doc, userObj);
}

function carouselMap(doc) {
  let carouselObj = {};
  if (doc.solr_ignore_list_of_base_or_participant_model.length) {
    const carouselCardSubType =
      doc.solr_ignore_list_of_base_or_participant_model[0].sub_type;
    const carouselId = doc.sub_type.toLowerCase() + '-' + carouselCardSubType;

    carouselObj = {
      cards: doc.solr_ignore_list_of_base_or_participant_model.map(
        carouselCardMap
      ),
      id: carouselId,
      cardType: carouselCardSubType,
    };
  }
  return basePostMap(doc, carouselObj);
}

function carouselCardMap(card) {
  return {
    imageUrl: card.image_url,
    id: card.id,
    deepLinkUrl: card.solr_ignore_deep_link_url,
  };
}

function communityMap(doc) {
  const communityObj = communityItemMap(doc);
  return basePostMap(doc, communityObj);
}

function videoMap(doc) {
  const videoObj = {
    sourceVideoUrl: doc.source_video_url_s,
    videoUrl: doc.video_url_s,
    videoThumbnailUrl: doc.video_thumbnail_url_s,
    isVideoStreamingEnabled: doc.is_video_streaming_enabled_b,
    // "video_id_l": 276,
  };
  return basePostMap(doc, videoObj);
}

function pollOptionsMap(doc) {
  const options = doc.solr_ignore_poll_options;
  return (
    options &&
    options.map((option) => ({
      id: option.id,
      description: option.description,
      voteResults:
        option.solr_ignore_total_no_of_votes_percent === null
          ? '0%'
          : option.solr_ignore_total_no_of_votes_percent + '%',
      isVoted: option.solr_ignore_is_voted,
      imageUrl: option.image_url,
    }))
  );
}

function pollMap(doc) {
  const postSlug = stripDomain(doc.solr_ignore_deep_link_url);
  const pollEndSet = doc.is_expired
    ? 'Poll Ended'
    : 'Ends in: ' + momentTimeFormat(doc.ends_at_dt);
  const pollObj = {
    slug: postSlug,
    pollType: doc.poll_type_s,
    pollOptions: pollOptionsMap(doc),
    totalVotes: doc.solr_ignore_total_no_of_responses,
    isResponseOnPoll: doc.solr_ignore_is_responed_on_poll,
    showResult: doc.show_results_b,
    isExpired: doc.is_expired,
    endDate: pollEndSet,
  };
  return basePostMap(doc, pollObj);
}

function promocardMap(doc) {
  const promocardObj = {
    imageUrl: doc.image_url,
  };
  return basePostMap(doc, promocardObj);
}

function challengeMap(doc) {
  const challengeObj = {};
  return basePostMap(doc, challengeObj);
}

function imagesMap(doc) {
  const sources = doc.display_text_image_url;
  const ids = doc.search_id_post_image;
  return (
    sources &&
    sources.map((src, i) => ({
      src: sources[i],
      id: ids[i],
    }))
  );
}
function basePostMap(doc, otherFields) {
  const user = authService.getCurrentUser();
  const commentData = commentMap(
    doc.solr_ignore_last_comments && doc.solr_ignore_last_comments[0]
  );

  const postSlug = stripDomain(doc.solr_ignore_deep_link_url);
  const branchLinkUrl = doc.short_branch_url_s;
  const authorData = postAuthorMap(doc);
  const postOptions = optionsMap(doc, 'post');
  const themePostDescriptionFontSize =
    setThemePostDescriptionFont(doc.description) || 22;
  const userDeepLinkUrl =
    authorData.name === 'Anonymous' || !user
      ? ''
      : stripDomain(doc.solr_ignore_user_deep_link_url);
  const mentorDeepLinkUrl =
    authorData.name === 'Anonymous' || !user
      ? ''
      : stripDomain(doc.solr_ignore_mentor_deep_link_url);
  const isAuthorMentor = doc.solr_ignore_is_author_mentor;

  const showProfileBadge =
    authorData.name !== 'Anonymous' &&
    user &&
    doc.solr_ignore_show_profile_badge;
  const getOnlyMention =
    doc.user_mentions && _map(doc.user_mentions, 'mention');
  let userMentions = getOnlyMention && mapUserMention(getOnlyMention);
  const deepLinkUrl = isAuthorMentor ? mentorDeepLinkUrl : userDeepLinkUrl;
  const baseObj = {
    id: doc.id,
    entityId: doc.id_of_entity_or_participant,
    subType: doc.sub_type,
    description:
      doc.sub_type !== 'A'
        ? renderMention(
            doc.description,
            userMentions,
            false,
            doc.theme_text_color_s,
            doc.is_theme_post_b
          )
        : doc.description,
    createdAt: doc.solr_ignore_posting_date_dt,
    likesCount: doc.likes_count_l || doc.solr_ignore_no_of_likes,
    repostsCount: doc.repost_count_l,
    commentsCount: doc.comments_count_l || doc.solr_ignore_no_of_comments,
    images: imagesMap(doc), // [ {src: '', id: 1}, {src: '', id: 2} ]
    isLiked: !!doc.solr_ignore_reacted_value,
    isReposted: !!doc.solr_ignore_is_own_repost,
    isTopPost: doc.is_top_post_b,
    parentId: doc.entity_or_participant_id,
    communityName:
      doc.solr_ignore_post_community_name ||
      doc.solr_ignore_poll_community_name,
    communityId: doc.community_i,
    communitySlug: stripDomain(doc.solr_ignore_participant_deep_link_url),
    slug: postSlug,
    author: authorData,
    lastComment: [commentData],
    deepLinkUrl: doc.solr_ignore_deep_link_url,
    isAuthorMentor: doc.solr_ignore_is_author_mentor,
    userDeepLinkUrl: deepLinkUrl,
    branchLinkUrl: branchLinkUrl,
    comments: [],
    optionsMenu: postOptions,
    isThemePost: doc.is_theme_post_b,
    themePostDescriptionColor: doc.theme_text_color_s,
    themePostDescriptionFontSize: themePostDescriptionFontSize,
    sharePostInfo: {
      text: doc.description,
      url: doc.solr_ignore_deep_link_url,
      title: 'SHEROES',
    },
    metaInfo: doc.solr_ignore_meta_details,
    metaDescription: doc.solr_ignore_meta_description || doc.meta_description_s,
    metaTitle: doc.solr_ignore_meta_title || doc.meta_title,
    isImagePost:
      doc.theme_image_url_s || doc.display_text_image_url ? true : false,
    themeImageUrl: doc.theme_image_url_s,
    typeId: doc.entity_or_participant_type_id_i,
    showProfileBadge: showProfileBadge,
    profileBadgeUrl: doc.solr_ignore_profile_badge_url,
    streamType: doc.solr_ignore_stream_name,
    isIndexed: doc.is_index_b,
    isCommunityPost: doc.is_commumity_post_b,
    postLanguage: doc.entity_language_s,
    isSpamPost: doc.is_spam_post_b,
    isCommunityOwner: doc.solr_ignore_is_community_owner,
    authorId: doc.author_id,
    isActive: doc.p_is_active,
    isSchedulePost: false,
    postCreatedAt: doc.p_crdt,
    userRecipientId: doc.recipient_id_l,
    userRecipientParticipantId: doc.recipient_participant_id_l,
    isWallPost: doc.solr_ignore_wall_post,
    isPostOwnWall: doc.solr_ignore_post_on_own_wall,
    userDeepLinkUrlForAdmin: SHEROES_ADMIN_URL + '/users/edit/' + doc.author_id,
    isAuthorProfileSpam: doc.solr_ignore_is_author_profile_spam,
    isVideoLink: doc.is_og_video_link_b,
    requestedUrl: doc.og_requested_url_s,
    ogImageUrl: doc.og_image_url_s,
    ogTitle: doc.og_title_s,
    ogDescription: doc.og_description_s,
    isFollowed: doc.solr_ignore_is_user_followed,
    challengePostText: doc.challenge_accept_post_text_s,
    createdById: doc.created_by_l,
    isAnonymous: doc.is_anonymous_b,
    userMentions: userMentions && userMentions,
  };
  return {
    ...baseObj,
    ...otherFields,
  };
}
/**
 *
 * @param {In} userType
 * set user profile from user type
 */
function mapUserType(userType) {
  if (userType === 7) {
    return '/champions';
  } else {
    return '/users';
  }
}
function mapUserMention(users) {
  const mappedUser = [];
  users &&
    _map(users, function (user) {
      const userLinkUrl = getUserLinkUrl(user);
      const data = { ...user, userLinkUrl };
      mappedUser.push(data);
    });
  return mappedUser;
}
function commentMap(doc) {
  const commentOptions = optionsMap(doc, 'comment');
  if (!doc) {
    return;
  }
  const user = authService.getCurrentUser();

  const showProfileBadge =
    doc.solr_ignore_participant_name !== 'Anonymous' &&
    user &&
    doc.solr_ignore_show_author_profile_badge;
  const getOnlyMention =
    doc.comment_user_mentions && _map(doc.comment_user_mentions, 'mention');
  const usersMentions = getOnlyMention && mapUserMention(getOnlyMention);
  return {
    id: doc.id_of_entity_or_participant || doc.id,
    entityId: doc.entity_id,
    commentId: doc.comments_id,
    description: renderMention(doc.comment, usersMentions),
    authorName: doc.solr_ignore_participant_name,
    authorImageUrl: doc.solr_ignore_participant_image_url,
    authorId: doc.participant_id,
    createdAt: doc.solr_ignore_created_on,
    isComment: true,
    optionsMenu: commentOptions,
    authorDeepLinkUrl:
      doc.solr_ignore_participant_name === 'Anonymous'
        ? ''
        : stripDomain(doc.solr_ignore_user_deep_link_url),
    isAuthorMentor:
      doc.solr_ignore_participant_name !== 'Anonymous' &&
      user &&
      doc.solr_ignore_is_mentor,
    showProfileBadge: showProfileBadge,
    profileBadgeUrl: doc.solr_ignore_author_profile_badge_url,
    likesCount: doc.solr_ignore_number_of_likes_on_comment,
    isLiked: !!doc.solr_ignore_is_my_own_like_on_comment,
  };
}

function postAuthorMap(doc) {
  const authorObj = {
    name: doc.author_name,
    imageUrl: doc.author_image_url,
    id: doc.author_id,
    description: doc.author_short_description,
  };
  return authorObj;
}

function optionsMap(doc, postType) {
  //console.log('doc:', doc);
  //TODO make conditional with post type.
  const isTopPostStatus = doc && doc.is_top_post_b;
  const deleteAccess =
    isCurrentUserAuthor(doc, postType) || checkUserType(userType.isAdmin);
  const updateAccess = isCurrentUserAuthor(doc, postType);
  const reportAccess = !isCurrentUserAuthor(doc, postType);
  const featurePostAccess =
    postType !== 'comment' && (isCommunityOwner(doc) || isAdminOrModerator());
  const optionsMap = [
    { id: 'edit', name: 'Edit', access: updateAccess },
    { id: 'delete', name: 'Delete', access: deleteAccess },
    { id: 'reportSpam', name: 'Report Spam', access: reportAccess },
    {
      id: 'featurePost',
      name: isTopPostStatus ? 'UnFeature This Post' : 'Feature This Post',
      access: featurePostAccess,
    },
  ];
  return optionsMap;
}
function isCommunityOwner(doc) {
  return doc && doc.solr_ignore_is_community_owner;
}
function userOptionsMap(doc, postType) {
  const isActive = doc && doc.p_is_active;
  //TODO make conditional with post type.
  const deactivateAccess =
    postType === 'U' ||
    checkUserType(userType.isAdmin) ||
    checkUserType(userType.isCommunityMod);
  const reactivateAccess =
    postType === 'U' ||
    checkUserType(userType.isAdmin) ||
    checkUserType(userType.isCommunityMod);
  const optionsMap = [
    {
      id: isActive ? 'deactivate' : 'reactivate',
      name: isActive ? 'Deactivate' : 'Reactivate',
      access: isActive ? deactivateAccess : reactivateAccess,
    },
  ];
  return optionsMap;
}

function isCurrentUserAuthor(doc, postType) {
  const user = authService.getCurrentUser();
  if (!doc || !user) return;
  const authorId = postType === 'comment' ? doc.participant_id : doc.author_id;
  return authorId === user.id;
}

function setThemePostDescriptionFont(description) {
  if (!appConfig || !description) return;
  const {
    themeMediumFontCharLimit,
    themeLargeFontSize,
    themeMediumFontSize,
  } = appConfig;
  const themePostDescriptionFontSize =
    description && description.length > themeMediumFontCharLimit
      ? themeLargeFontSize
      : themeMediumFontSize;
  return themePostDescriptionFontSize;
}

function notificationMap(doc) {
  const deepLinkUrl =
    doc.category === 'FOLLOW_SUPER_SHEROES_NEW_BADGE_EARNED'
      ? 'https://sheroes.com/super-sheroes?endPoint=' +
        doc.deep_link_url +
        '&title=SuperSHEROES of the Week'
      : doc.deep_link_url;
  return {
    notification: {
      id: doc.id,
      category: doc.category,
      superSheroesBadge: doc.FOLLOW_SUPER_SHEROES_NEW_BADGE_EARNED,
      title: doc.title,
      icon: doc.icon,
      message: doc.message,
      leftImgIcon: doc.left_image_icon,
      rightImgIcon: doc.right_image_icon,
      createdAt: doc.solr_ignore_posting_date_dt,
      deepLinkUrl: deepLinkUrl,
      isRead: doc.is_read,
      isBlocked: hideNotificationByCategory(doc.category),
    },
  };
}

function userMap(doc) {
  const isOnboardingDone = doc.next_screen !== 'community_onboarding';
  if (!doc)
    return {
      image: imageUrls.DEFAULT_USER_IMG,
    };

  return {
    id: doc.user_summary.user_id,
    userTypeId: doc.user_summary.user_details.userTypeId,
    userType: userTypeMap[doc.user_summary.user_details.userTypeId],
    image: doc.user_summary.photo_url
      ? doc.user_summary.photo_url
      : imageUrls.DEFAULT_USER_IMG,
    name: doc.user_summary.user_details.name,
    isActive: doc.user_summary.user_details.isActive,
    firstName: doc.user_summary.first_name,
    lastName: doc.user_summary.last_name,
    gender: doc.gender,
    isNewUser: doc.is_new_user,
    nextScreen: doc.next_screen,
    token: doc.token,
    mobileNumber: doc.user_summary.user_details.mobile,
    emailId: doc.user_summary.email_id,
    createdDate: doc.user_summary.user_details.crdt,
    dateOfBirth: doc.user_summary.user_details.dob,
    status: doc.status,
    nextScreenUrl: !isOnboardingDone ? onboardingSteps[0] : urlMap.FEED,
    isOnboardingDone: isOnboardingDone,
    userProfileLink: getUserProfileLink(doc.user_summary),
    location: doc.user_summary.user_details.location,
    cityMasterId: doc.user_summary.user_details.cityMasterId,
    summary: doc.user_summary.user_details.userSummary,
    maritalStatus: doc.user_summary.user_details.maritalStatus,
    userParticipantId: doc.user_summary.user_details.participantId,
  };
}
/**
 * This mapper  is used for cross domain/sub domain mapping
 */
function getUserInfoMap(doc) {
  return {
    id: doc.id_of_entity_or_participant,
    userTypeId: doc.entity_or_participant_type_id_i,
    userImage: doc.image_url,
    name: doc.name,
    isActive: doc.p_is_active,
    gender: doc.gender_s,
    status: doc.status,
    nextScreenUrl: urlMap.FEED,
    isOnboardingDone: true,
  };
}

function userErrorMap(doc) {
  if (doc.status === 'FAILED' && doc.fieldErrorMessageMap !== null) {
    const errorMessageMap = doc.fieldErrorMessageMap;
    return {
      status: doc.status,
      message: errorMessageMap.INVALID_DATA,
      isDeactivated: errorMessageMap.IS_DEACTIVATED,
      nextScreenUrl: userDeactivatedUrl.USER_DEACTIVATED,
    };
  }
}

function userBadgesMap(doc) {
  const badgeList = doc.solr_ignore_user_badges_list;
  return (
    badgeList &&
    badgeList.map((list) => ({
      imageUrl: list.imageUrl,
    }))
  );
}

function userDetailsMap(doc) {
  const userOptions = userOptionsMap(doc, 'comment');

  return {
    imageUrl: doc.image_url,
    name: doc.name,
    totalPost: doc.solr_ignore_total_no_of_post_created,
    followersCount: doc.solr_ignore_no_of_followers,
    followingCount: doc.solr_ignore_no_of_following,
    isFollowed: doc.solr_ignore_is_user_followed,
    description: doc.list_description,
    location: doc.location_s,
    badgesList: userBadgesMap(doc),
    showProfileBadge: doc.solr_ignore_show_profile_badge,
    profileBadgeUrl: doc.solr_ignore_profile_badge_url,
    typeId: doc.entity_or_participant_type_id_i,
    shareProfileInfo: {
      text: appConfig && appConfig.profileShareText,
      url: doc.short_branch_url_s,
      title: 'SHEROES',
    },
    userOptions: userOptions,
    isActive: doc.p_is_active,
  };
}

export {
  commentMap,
  postMap,
  notificationMap,
  optionsMap,
  userMap,
  userErrorMap,
  getUserInfoMap,
  videoMap,
  pollMap,
  userDetailsMap,
  userOptionsMap,
  basePostMap,
  mapUserType,
};
