import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { useTablet } from '../../hooks/utilHooks';
import sheroesLogo from '../../img/sheroes-red-logo.svg';

const Header = () => {
  const classes = useStyles();
  const isTablet = useTablet();

  return (
    <React.Fragment>
      <Box className={classes.header} bgcolor="#FFF" py={6} px={4}>
        <Box className={isTablet ? 'width100' : 'mxAuto homeContainer'}>
          <img
            src={sheroesLogo}
            alt="SHEROES"
            width={isTablet ? 'auto' : '180px'}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};
export default Header;

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'fixed',
    width: '100%',
    left: theme.spacing(0),
    right: theme.spacing(0),
    zIndex: '1',
    top: theme.spacing(0),
  },
}));
