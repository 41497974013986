/**
 * To make an object constant, recursively freeze each property which is of type object (deep freeze).
 *
 * CAUTION: Use the pattern on a case-by-case basis based on your design when you know the object contains no cycles
 *          in the reference graph, otherwise an endless loop will be triggered
 *
 * https://developer.mozilla.org/en/docs/Web/JavaScript/Reference/Global_Objects/Object/freeze
 * @param obj
 * @returns {Object}
 */
function deepFreeze(obj) {
  // Retrieve the property names defined on obj
  var propNames = Object.getOwnPropertyNames(obj);

  // Freeze properties before freezing self
  propNames.forEach(function (name) {
    var prop = obj[name];

    // Freeze prop if it is an object
    if (typeof prop == 'object' && prop !== null) deepFreeze(prop);
  });

  // Freeze self (no-op if already frozen)
  return Object.freeze(obj);
}

export default deepFreeze;
