import React from 'react';
import { JSONLD, Generic } from 'react-structured-data';
const ArticleSchema = ({ post }) => {
  return (
    <JSONLD>
      <Generic
        type="BlogPosting"
        jsonldtype="BlogPosting"
        schema={{
          inLanguage: `${post.postLanguage}`,
          headline: `${post.title}`,
          image: `${post.articleImageUrl}`,
          datePublished: `${post.createdAtStr}`,
          dateModified: `${post.modifiedAtStr}`,
          description: `${post.metaDescription}`,
        }}
      >
        <Generic
          type="mainEntityOfPage"
          jsonldtype="WebPage"
          schema={{
            '@id': `${post.deepLinkUrl}`,
          }}
        />
        <Generic
          type="author"
          jsonldtype="Person"
          schema={{ name: `${post.author.name}` }}
        />
        <Generic
          type="publisher"
          jsonldtype="Organization"
          schema={{
            name: 'SHEROES',
            logo: {
              '@type': 'ImageObject',
              url: 'https://sheroes.com/img/feed/sheroes-logo-main.png',
            },
          }}
        ></Generic>
      </Generic>
    </JSONLD>
  );
};
export default ArticleSchema;
