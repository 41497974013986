import http from './httpService';
import apiUrl from '../utils/apiUrl';
import { pollMap } from '../mappers/modelMapper';

export async function submitPoll(pollId, pollOptionId) {
  try {
    const { data } = await http.post(apiUrl.SUBMIT_USER_POLL, {
      poll_id: pollId,
      poll_option_id: pollOptionId,
    });
    if (data.status === 'SUCCESS') {
      return pollMap(data.poll_reaction_model.poll_doc);
    }
  } catch (ex) {
    return null;
  }
}

export default {
  submitPoll,
};
