import React from 'react';
import Inspiration from '../img/home/inspiration.svg';
import Bazaar from '../img/home/bazaar.svg';
import SheCo from '../img/home/sheco.svg';
import Loans from '../img/home/loans.svg';
import Helpline from '../img/home/helpline.svg';
import love from '../img/home/interests/love.svg';
import health from '../img/home/interests/health.svg';
import career from '../img/home/interests/career.svg';
import cook from '../img/home/interests/cook.svg';
import aspiringWriters from '../img/home/interests/aspiring-writers.svg';
import babyCare from '../img/home/interests/baby-care.svg';
import english from '../img/home/interests/english.svg';
import fashion from '../img/home/interests/fashion.svg';
import facebookIcon from '../img/home/facebook.svg';
import instagramIcon from '../img/home/instagram.jpg';
import linkedInIcon from '../img/home/linkedin.svg';
import twitterIcon from '../img/home/twitter.svg';
import FooterPage from '../components/home/footerPage';
import { SHEROES_ADMIN_URL } from '../utils/constants';

const signUpType = [
  {
    text: 'Become an inspiration for millions of women',
    buttonText: 'Join & Connect',
    imgUrl: Inspiration,
    redirectUrl: '/communities/aspiring-writers/MzEx',
  },
  {
    text: 'Buy and Sell Products & Services',
    buttonText: 'Explore More',
    imgUrl: Bazaar,
    redirectUrl: '/communities/bazaar/MzA4',
  },
  {
    text: 'Become a SHECO Entrepreneur',
    buttonText: 'Apply Now',
    imgUrl: SheCo,
    redirectUrl: 'https://sheroes.com/go/public/go/sheco/verifiedseller',
  },
  {
    text: 'Get Quick Short Term Loans',
    buttonText: 'Join Now',
    imgUrl: Loans,
    redirectUrl: 'https://sheroes.com/go/public/go/landing/loan_form',
  },
  {
    text: 'Get Answers For Your Questions',
    buttonText: 'Join Now',
    imgUrl: Helpline,
    redirectUrl: 'https://sheroes.com/helpline',
  },
];

const testimonialObj = [
  {
    description:
      'Sheroes is the best app for any women. When you fell down or low just click to Sheroes, your mood gonna change instantly. You can get sisterhood feeling here. Safe app in comparison to other apps. Learn lot of things and help people learn as well. I would recommend it to every women.',
    authorUrl:
      'https://img.sheroes.in/img/user_4191936_20191109140735.jpeg?tr=w-40',
    authorName: 'Durba Dey',
  },
  {
    description:
      'The best women app, excellent for sharing content, feelings, milestones and experiences. Quite good for providing acknowledgement to many topics and many more things. Helped me to share my life, my triumphs, it connects me to many wonder woman! This time missed the conference but will definitely attend for my betterment towards my writing career. All the best! Literally of love.',
    authorUrl:
      'https://img.sheroes.in/img/user_3232272_20181009114211.jpeg?tr=w-40',
    authorName: 'Triveni Sidha',
  },
  {
    description:
      'Very good application for ladies and girls. This application are very helpful to me. All ladies and girl if u not share your problem with any person so this app very create help to you about all problem thanks so much Sheroes Team….Good Job',
    authorUrl:
      'https://img.sheroes.in/img/user_7561688_20190913065133.jpeg?tr=w-40',
    authorName: 'Kinjal Soni',
  },
];

const communitiesInterestsObj = [
  {
    imgUrl: love,
    text: 'Love, Sex and Relationships',
    url: '/communities/love-sex-and-relationships/Mjgx',
  },
  {
    imgUrl: health,
    text: 'Health',
    url: '/communities/health/MzQz',
  },
  {
    imgUrl: career,
    text: 'Career and Education',
    url: '/communities/career-and-exams/Mjc5',
  },
  {
    imgUrl: cook,
    text: 'Cooking & Recipes',
    url: '/communities/cooking-food-recipes-and-more/NDky',
  },
  {
    imgUrl: aspiringWriters,
    text: 'Aspiring Writers',
    url: '/communities/aspiring-writers/MzEx',
  },
  {
    imgUrl: babyCare,
    text: 'Parenting and BabyCare',
    url: '/communities/babygogo/NDk2',
  },
  {
    imgUrl: english,
    text: ' English Speaking',
    url: '/communities/english-winglish/Mjk4OQ==',
  },
  {
    imgUrl: fashion,
    text: 'Fashion and Beauty',
    url: '/communities/fashion-and-lifestyle/Mjgw',
  },
];

const socialLinkObj = [
  {
    url: 'https://www.facebook.com/sheroesindia',
    imgUrl: facebookIcon,
    altText: 'Facebook',
  },
  {
    url: 'https://twitter.com/sheroes',
    imgUrl: twitterIcon,
    altText: 'Twitter',
  },
  {
    url: 'https://www.linkedin.com/company/sheroesindia',
    imgUrl: linkedInIcon,
    altText: 'LinkedIn',
  },
  {
    url: 'https://www.instagram.com/sheroesindia',
    imgUrl: instagramIcon,
    altText: 'Instagram',
  },
];

const footerLinkObj = [
  {
    url:
      'https://play.google.com/store/apps/details?id=appliedlife.pvtltd.SHEROES&hl=en',
    text: 'Sheroes App',
    noIframe: true,
  },
  {
    url: '/community-guidelines',
    text: 'Community Guidelines',
  },
  {
    url: '/about',
    text: 'About Us',
  },
  {
    url: '/articles',
    text: 'Articles',
    noIframe: true,
  },
  {
    url: '/team',
    text: 'Team',
  },
  {
    url: '/advisory-council',
    text: 'Advisory Council',
  },
  {
    url: 'https://sheroes.tech',
    text: 'Blog',
    noIframe: true,
  },
  {
    url: '/Home/tnc',
    text: 'Terms & Conditions',
  },
  {
    url: '/Home/advertorial-policy',
    internalPhpUrl: '/Home/editorial-policy',
    text: 'Advertorial Policy',
  },
  {
    url: '/investors',
    text: 'Investors',
  },
  {
    url: '/Home/policy',
    text: 'Privacy Policy',
  },
  {
    url: '/helpline',
    text: 'Helpline',
  },
  {
    url: '/contact',
    text: 'Contact Us',
  },
];

const footerRoutes = footerLinkObj
  .filter((obj) => !obj.noIframe)
  .map((obj) => {
    return {
      path: obj.url,
      render: (props) => (
        <FooterPage
          {...props}
          title={obj.text}
          url={
            SHEROES_ADMIN_URL +
            `${obj.internalPhpUrl || obj.url}/?header=0&footer=0`
          }
        />
      ),
    };
  });

export {
  signUpType,
  testimonialObj,
  communitiesInterestsObj,
  socialLinkObj,
  footerLinkObj,
  footerRoutes,
};
