import { useEffect } from 'react';
import analytics from '../services/analyticsService';
import useReactRouter from 'use-react-router';
import { eventNames, eventProps } from '../utils/eventConstants';
import logService from '../services/logService';

// Use this hook for only screen opens. Send the args in the following style with valid names.
function useScreenOpenTracker(
  { screenName, id, title, extras },
  shouldTrack = true
) {
  const { history } = useReactRouter();
  const { pathname } = history.location;
  //As screenName is a mandatory prop for event to be tracked, throw an exception if it's not found.
  if (!screenName) {
    const error = new Error('Event Screen Name NOT FOUND');
    logService.error(error);
  }
  useEffect(() => {
    //ShouldTrack is to prevent event tracking when data is not available & allow tracking if data is fetched when re-rendered.
    shouldTrack &&
      analytics.track(eventNames.screenOpen, {
        [eventProps.screenName]: screenName,
        [eventProps.title]: title,
        [eventProps.id]: id,
        ...extras,
      });
    //GA tracking
    shouldTrack && analytics.pageView(pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldTrack]);
}

export { useScreenOpenTracker };
