import http from './httpService';
import { postMap } from '../mappers/modelMapper';

async function getFeedStream(feedStreamApi, nextToken, isGetRequest) {
  let feedData = {};
  const { apiRequest } = getRequestData(feedStreamApi, nextToken, isGetRequest);
  const { data } = await apiRequest;
  feedData.docs = data.docs.map(postMap);
  feedData.nextToken = data.next_token;
  feedData.setOrderKey = data.set_order_key;
  feedData.feedConfigVersion = data.server_feed_config_version;
  feedData.status = data.status;
  return feedData;
}

export default {
  getFeedStream,
};

function getRequestData(feedStreamApi, nextToken, isGetRequest) {
  let requestBody, streamApi, apiRequest;

  if (isGetRequest) {
    streamApi = feedStreamApi + (nextToken ? '&next_token=' + nextToken : '');
    apiRequest = http.get(streamApi, requestBody);
  } else {
    requestBody = { next_token: nextToken };
    streamApi = feedStreamApi;
    apiRequest = http.post(streamApi, requestBody);
  }
  return { streamApi, requestBody, apiRequest };
}
