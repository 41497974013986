import React, { useState, useEffect } from 'react';
import { useStoreActions } from 'easy-peasy';
import useReactRouter from 'use-react-router';
import ActionToggle from '../common/actionToggle';

const OptionsMenu = ({ comment, isPostDetail, post, postId, postEntityId }) => {
  const deletePost = useStoreActions((actions) => actions.posts.deletePost);
  const featurePost = useStoreActions((actions) => actions.posts.featurePost);
  const deleteCommentPost = useStoreActions(
    (actions) => actions.posts.deleteCommentPost
  );
  const setScrollPostId = useStoreActions(
    (actions) => actions.feed.setScrollPostId
  );
  const { history } = useReactRouter();
  const [optionsMenu, setOptionsMenu] = useState([]);
  const { updateSpamState } = useStoreActions((actions) => actions.reportSpam);

  useEffect(() => {
    let currentComp = true;
    currentComp &&
      setOptionsMenu(post ? post.optionsMenu : comment.optionsMenu);
    return () => {
      currentComp = false;
    };
  }, [post, comment]);

  function handleDeletePost(e) {
    e.stopPropagation();
    deletePost({
      postEntityId: post.entityId,
      postId: post.id,
      isPostDetail: isPostDetail,
      history: history,
    });
  }

  //TODO handle edit comment.
  function handleEditComment() {}

  function handleEditAction() {
    if (post) {
      const postStr = JSON.stringify(post);
      const fromScreen = window.location.href;
      history.push('/edit-post', { postStr, fromScreen });
    } else {
      handleEditComment();
    }
  }

  function handleDeleteComment() {
    const reqBody = {
      commentId: comment.id,
      postId: postId,
      postEntityId: postEntityId,
    };
    deleteCommentPost(reqBody);
  }

  function handleReportSpam() {
    const docInfo = {
      docId: post ? post.entityId : postEntityId,
      communityId: post ? post.communityId : 0, //0 if doc is comment.
      docAuthorId: post ? post.author.id : comment.authorId,
    };
    updateSpamState({
      showSpamModal: true,
      contentType: post ? 'post' : 'comment',
      docInfo: docInfo,
    });
  }
  function handleFeaturePostAction() {
    const currentIsTopPostStatus = post.isTopPost === null ? false : true;
    featurePost({
      isTopPost: currentIsTopPostStatus,
      entityId: post.entityId,
      postId: post.id,
    });
    setScrollPostId(post.id);
  }

  const actionMap = {
    delete: post ? handleDeletePost : handleDeleteComment,
    reportSpam: handleReportSpam,
    edit: handleEditAction,
    featurePost: handleFeaturePostAction,
  };
  optionsMenu &&
    optionsMenu.map((option) => (option.action = actionMap[option.id]));

  return <ActionToggle options={optionsMenu} />;
};

export default OptionsMenu;
