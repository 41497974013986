import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import auth from '../../services/authService';
import { getProtectedRedirectUrl } from '../../utils/redirectUtil';

const ProtectedRoute = ({
  path,
  component: Component,
  redirectFun = getProtectedRedirectUrl, //Default redirect function
  render,
  ...rest
}) => {
  const user = auth.getCurrentUser();
  const redirectUrl = redirectFun(path);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (redirectUrl)
          return (
            <Redirect
              to={{
                pathname: redirectUrl,
                state: { from: props.location },
              }}
            />
          );
        return Component ? <Component {...props} user={user} /> : render(props);
      }}
    />
  );
};

export default ProtectedRoute;
