import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Modal, Backdrop, Fade } from '@material-ui/core';
import SocialSignUp from './socialSignUp';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useTablet } from '../../hooks/utilHooks';

const LoginModal = () => {
  const classes = useStyles();
  const showModal = useStoreState((state) => state.user.showModal);
  const userLoginModal = useStoreActions(
    (actions) => actions.user.userLoginModal
  );
  const isTablet = useTablet();

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showModal}
        onClose={() => {
          userLoginModal({ showModal: false });
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={showModal}>
          <Box className={classes.paper} borderRadius={isTablet ? 0 : '6px'}>
            <SocialSignUp />
          </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};
export default LoginModal;

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 10),
  },
}));
