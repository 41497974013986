import React, { useState } from 'react';
import {
  Typography,
  Box,
  TextField,
  Grid,
  makeStyles,
} from '@material-ui/core';
import { useStoreState } from 'easy-peasy';
import UserAvatar from '../common/userAvatar';
import { ReactComponent as SendIcon } from '../../img/send.svg';
import { ReactComponent as DisabledSendIcon } from '../../img/disabledSend.svg';
import CustomButton from '../common/customButton';
import { imageUrls } from '../../utils/constants';
import imgParams from '../../utils/imageUtil';
import { useTablet } from '../../hooks/utilHooks';
import CustomMentionEditor from '../customMentionEditor';
import mentionStyles from '../common/mentionStyles';

const CreateComment = ({
  isPostDetail,
  focusComment,
  handleCommentClick,
  handleSaveComment,
  isArticleDetail,
  commentDescription,
  handleSetCommentEditor,
  commentUserMention,
  isCommentSubmit,
  setIsCommentSubmit,
  entityId,
  communityId,
}) => {
  mentionStyles();
  const [userAnonymity, setUserAnonymity] = useState(false);
  function handleUserAnonymity(bool) {
    setUserAnonymity(bool);
  }
  const classes = useStyles();
  const user = useStoreState((state) => state.user.user);
  const isDetailPage = isPostDetail || isArticleDetail;
  const isTablet = useTablet();
  return (
    <React.Fragment>
      <Box
        onClick={handleCommentClick}
        width="1200px"
        maxWidth={1}
        bgcolor={isDetailPage && 'background.secondary'}
        px={4}
        py={2}
        left="auto!important"
        className={
          (isPostDetail || (isArticleDetail && focusComment)) && 'stickToBottom'
        }
      >
        <Grid>
          <Grid
            item
            sm={isDetailPage ? 6 : 12}
            md={isDetailPage ? 6 : 12}
            lg={isDetailPage ? 6 : 12}
          >
            <Box
              alignItems="center"
              display="flex"
              pr={!isTablet && isDetailPage ? 12.5 : ''}
            >
              <Box pr={2}>
                <UserAvatar
                  userImgUrl={imgParams(
                    user
                      ? !userAnonymity
                        ? user.image
                        : imageUrls.ANONYMOUS_IMG
                      : imageUrls.DEFAULT_USER_IMG,
                    40,
                    40,
                    'auto'
                  )}
                />
              </Box>

              {isDetailPage ? (
                <Box width={1} className={classes.textareaSet}>
                  <Box
                    className={`${classes.commentBox} ${
                      isPostDetail ? classes.resetBorder : classes.staticBorder
                    }`}
                  >
                    <CustomMentionEditor
                      placeholder="Add your comment here"
                      handleSetEditorState={handleSetCommentEditor}
                      isCommentSubmit={isCommentSubmit}
                      setIsCommentSubmit={setIsCommentSubmit}
                      context={'COMMENT'}
                      entityId={entityId}
                      communityId={communityId}
                    />
                  </Box>
                </Box>
              ) : (
                <TextField
                  id="standard-textarea"
                  placeholder="Add your comment here"
                  value={commentDescription}
                  multiline
                  //TODO optimise this input box later. https://reactjs.org/docs/uncontrolled-components.html
                  autoFocus={focusComment}
                  rowsMax={5}
                  variant="outlined"
                  className={`${classes.commentBox} ${
                    isPostDetail ? classes.resetBorder : classes.staticBorder
                  }`}
                  fullWidth
                  InputProps={{
                    className: classes.textareaSet,
                  }}
                />
              )}

              {isDetailPage && (
                <Box pl={3}>
                  <CustomButton
                    onClick={() =>
                      handleSaveComment(
                        userAnonymity,
                        commentDescription,
                        commentUserMention
                      )
                    }
                    variant="iconButton"
                    disabled={commentDescription === '' ? true : false}
                    icon={
                      commentDescription !== '' ? (
                        <SendIcon />
                      ) : (
                        <DisabledSendIcon />
                      )
                    }
                  />
                </Box>
              )}
            </Box>
            {isPostDetail && (
              <Box mt={2}>
                <Grid spacing={4} container>
                  <Grid item>
                    <Typography variant="body1" component="span">
                      Post as :
                    </Typography>
                  </Grid>
                  <Grid onClick={() => handleUserAnonymity(false)} item>
                    <Typography
                      variant="body2"
                      color={userAnonymity ? 'secondary' : 'primary'}
                      component="span"
                    >
                      {user && user.firstName}
                    </Typography>
                  </Grid>
                  <Grid onClick={() => handleUserAnonymity(true)} item>
                    <Typography
                      variant="body2"
                      color={userAnonymity ? 'primary' : 'secondary'}
                      component="span"
                    >
                      Anonymous
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  textareaSet: {
    color: theme.palette.text.primary,
    fontSize: '14px',
    fontWeight: 400,
    backgroundColor: 'white',
    padding: theme.spacing(2, 3),
    borderRadius: '4px',
  },
  commentBox: {
    caretColor: theme.palette.primary.main,
  },
  resetBorder: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 0,
      },
    },
  },
  staticBorder: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.grey['100'],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey['100'],
      },
      '&.Mui-focused fieldset': {
        border: 0,
      },
    },
  },
}));

export default CreateComment;
