import http from './httpService';
import APIURL from '../utils/apiUrl';
import { userMap, userDetailsMap } from '../mappers/modelMapper';
import { entityMasterMap } from '../mappers/entityMasterMapper';
import { mapMentionData } from '../utils/editorUtil';

export async function updateUserDOB(dob) {
  const { data } = await http.post(APIURL.USER_ADD_EDIT, {
    source: 'web',
    type: 'BASIC_PROFILE',
    subType: 'BASIC_USER_PROFILE_SERVICE',
    dob: dob,
  });

  return data;
}
export async function getUserDetailsByToken(token) {
  http.setUserToken(token);
  const { data } = await http.post(APIURL.USER_DETAIL_URL, {});
  const response = userMap(data);
  return response;
}

export async function getUserDetails(userId) {
  const requestBody = {
    id_of_entity_or_participant: userId,
    sub_type: 'U',
  };
  const { data } = await http.post(APIURL.GET_POST, requestBody);
  const response = userDetailsMap(data.docs[0]);
  return response;
}

async function followProfile(userId, isFollowed) {
  const requestBody = {
    id_of_entity_or_participant: userId,
  };
  const data = await http.post(
    isFollowed ? APIURL.UNFOLLOW : APIURL.FOLLOW,
    requestBody
  );
  return data;
}
export async function updateUserSummary(summary) {
  const { data } = await http.post(APIURL.USER_ADD_EDIT, {
    source: 'web',
    type: 'SUMMARY',
    subType: 'USER_SUMMARY_SERVICE',
    summary: summary,
  });

  return data;
}
export async function updateUserAdditionalInfo(mobile, maritalStatus, dob) {
  const { data } = await http.post(APIURL.USER_ADD_EDIT, {
    source: 'web',
    type: 'ADD_INFO',
    subType: 'ADDITIONAL_INFO_SERVICE',
    mobile: mobile,
    marital_status: maritalStatus,
    dob: dob,
  });

  return data;
}
export async function updateBasicProfile(firstName, lastName, cityMasterId) {
  const { data } = await http.post(APIURL.USER_ADD_EDIT, {
    source: 'web',
    type: 'BASIC_PROFILE',
    subType: 'BASIC_USER_PROFILE_SERVICE',
    first_name: firstName,
    last_name: lastName,
    city_master_id: cityMasterId,
  });
  return data;
}
export async function updateProfilePicture(image) {
  const { data } = await http.post(APIURL.USER_ADD_EDIT, {
    source: 'web',
    type: 'PROFILE_PHOTO',
    subType: 'USER_PROFILE_PHOTO_SERVICE',
    image_string: image,
  });
  return data.docs;
}

export async function getCityMaster() {
  const { data } = await http.post(APIURL.GET_ENTITY_MASTER_DATA, {
    source: 'web',
    master_data_type: 'city',
    q: '',
    page_no: 1,
    page_size: 20,
    test_user_type: 'organisations_test_user',
  });
  return entityMasterMap(data.docs);
}

async function getLocationMasterData() {
  const { data } = await http.get(APIURL.GET_ALL_LOCATIONS);
  return entityMasterMap(data.locations);
}

export async function getUserDeactivationReasons() {
  const { data } = await http.post(APIURL.USER_DEACTIVATION_REASONS);
  return data.deactivationReason;
}

async function userDeactivate(userId, reasonId, reasonText) {
  const reqObj = {
    user_id: userId,
    deactivation_reason: reasonId,
    modify_posts: true,
    modify_comments: true,
    reason_for_inactive: reasonText,
  };
  await http.post(APIURL.USER_DEACTIVATION, reqObj);
  return;
}

async function userReactivate(userId) {
  const reqObj = {
    user_id: userId,
    modify_posts: true,
    modify_comments: true,
    reason_for_inactive: 'reasonText',
    reactivate: true,
  };
  await http.post(APIURL.USER_DEACTIVATION, reqObj);
  return;
}
async function getMentionData(request) {
  const { data } = await http.post(APIURL.GET_USER_MENTION, request);
  return data.user_mention_suggestions.map((user) => mapMentionData(user));
}

export default {
  updateUserDOB,
  getUserDetailsByToken,
  getUserDetails,
  followProfile,
  updateUserSummary,
  updateUserAdditionalInfo,
  updateBasicProfile,
  updateProfilePicture,
  getCityMaster,
  getLocationMasterData,
  getUserDeactivationReasons,
  userDeactivate,
  userReactivate,
  getMentionData,
};
