import { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useScrollToTop, useTablet } from './utilHooks';
import { scrollToBottom } from '../utils/postUtil';
import { useCreateOrEditPostRequest } from '../hooks/createOrEditPostRequest';
import { getUserMentionObj } from '../utils/editorUtil';
/**
 * Updates post title in NavBar
 * @param {Object} post
 */

function usePostTitle(post) {
  const isTablet = useTablet();
  const setTitle = useStoreActions((actions) => actions.navBar.setTitle); //Global state
  const postTitleText = post && post.author && `${post.author.name}'s Post`;
  const pollTitleText = post && post.author && `${post.author.name}'s Poll`;
  const communityName = post && post.name;
  const titleMap = {
    P: postTitleText,
    A: isTablet ? 'S H E R O E S' : 'Article',
    V: postTitleText,
    PL: pollTitleText,
    C: communityName,
  };
  useEffect(() => {
    const title = titleMap[post && post.subType];
    setTitle(title);
  }, [post, setTitle, titleMap]);
}

/**
 * Fetches post TODO
 * @param {String} id is an unique string used in post store
 * @param {Number} postEntityId
 * @param {String} postSubType
 */
function useGetPost(id, postEntityId, postSubType = 'P') {
  const [postId, setPostId] = useState(id);
  const post = useStoreState((state) => state.posts.postsObj[postId]);
  const fetchPost = useStoreActions((actions) => actions.posts.fetchPost);
  usePostTitle(post);
  useScrollToTop();
  useEffect(() => {
    async function fetchPostId() {
      const postIdResponse = await fetchPost({ postEntityId, postSubType });
      postId || setPostId(postIdResponse);
    }
    post || fetchPostId();
    return () => {
      postId && setPostId();
    };
  }, [fetchPost, post, postEntityId, postId, postSubType]);

  return { post };
}

function useLikeOnPost(postId) {
  const isLiked = useStoreState(
    (state) => state.posts.postsObj[postId].isLiked
  );
  const likesCount = useStoreState(
    (state) => state.posts.postsObj[postId].likesCount
  );
  const likePost = useStoreActions((actions) => actions.posts.likePost);
  return { isLiked, likesCount, likePost };
}

function useLikeOnComment(postId, commentIndex, isDetails) {
  const comment = useStoreState(
    (state) => state.posts.postsObj[postId].comments[commentIndex]
  );
  const feedComment = useStoreState(
    (state) => state.posts.postsObj[postId].lastComment[0]
  );
  const isLiked = isDetails
    ? comment && comment.isLiked
    : feedComment && feedComment.isLiked;
  const likesCount = isDetails
    ? comment && comment.likesCount
    : feedComment && feedComment.likesCount;

  const likeComment = useStoreActions((actions) => actions.posts.likeComment);
  return { isLiked, likesCount, likeComment };
}

function useRepost(postId) {
  const isReposted = useStoreState(
    (state) => state.posts.postsObj[postId].isReposted
  );
  const repostsCount = useStoreState(
    (state) => state.posts.postsObj[postId].repostsCount
  );
  const repost = useStoreActions((actions) => actions.posts.repost);

  return { isReposted, repostsCount, repost };
}

function useFollowOnPost(id, type) {
  const isFollow = useStoreState(
    (state) => state.posts.postsObj[id].isFollowed
  );
  const postFollow = useStoreActions((actions) => actions.posts.postFollow);
  return { isFollow, postFollow };
}

function useCommentOnPost(post) {
  const { getEditorContent } = useCreateOrEditPostRequest(post);
  const saveCommentPost = useStoreActions(
    (actions) => actions.posts.saveCommentPost
  );
  const user = useStoreState((state) => state.user.user);
  const { isCommentSubmit } = useStoreState(
    (state) => state.createPost.postObject
  );
  const { setIsCommentSubmit } = useStoreActions((state) => state.createPost);
  //TODO move to constants when merged.
  const anonymousUserImage =
    'https://img.sheroes.in/img/uploads/sheroespage/logoes/1513929058anonymouse_ic.png';
  const [commentDescription, setCommentDescription] = useState('');
  const [commentUserMention, setCommentUserMention] = useState([]);
  //const [isCommentSubmit, setIsCommentSubmit] = useState(false);
  function handleCommentChange(e) {
    setCommentDescription(e.target.value);
  }
  /**
   *
   * @param {state} editorState
   * Handle to set editor state  and set response to comment user
   */
  function handleSetCommentEditor(editorState) {
    const { content, mentionData } = getEditorContent(editorState);
    content && setCommentDescription(content);
    const userCommentMention = getUserMentionObj(mentionData, content);
    setCommentUserMention(userCommentMention);
    setIsCommentSubmit(false);
  }
  async function handleSaveComment(
    userAnonymity,
    commentDescription,
    commentUserMention
  ) {
    let newComment = {
      description: commentDescription,
      authorName: userAnonymity ? 'Anonymous' : user.name ? user.name : '',
      authorImageUrl: !userAnonymity ? user.image : anonymousUserImage,
      createdAt: 'Just Now',
      isComment: true,
      isAuthorMentor: user.userTypeId === 7,
    };
    const reqBody = {
      commentObj: [newComment],
      postId: post.id,
      postEntityId: post.parentId,
      isAnonymous: userAnonymity,
      commentUserMention: commentUserMention,
    };
    const commentDescript = await saveCommentPost(reqBody);
    commentDescript && setCommentDescription(commentDescript);
    scrollToBottom();
    setIsCommentSubmit(true);
    setCommentDescription('');
  }
  return {
    handleSaveComment,
    commentDescription,
    handleCommentChange,
    commentUserMention,
    handleSetCommentEditor,
    isCommentSubmit,
    setIsCommentSubmit,
  };
}

function useLikeList(postId) {
  const likeList = useStoreState(
    (state) => state.posts.postsObj[postId].likeList
  );
  return { likeList };
}

export {
  usePostTitle,
  useGetPost,
  useLikeOnPost,
  useCommentOnPost,
  useRepost,
  useLikeOnComment,
  useFollowOnPost,
  useLikeList
};
