export default {
  SIGN_IN_BY_EMAIL: '/participant/auth/signin',
  FEED_STREAM_URL: '/participant/feed/stream',
  USER_DETAIL_URL: '/participant/user/getUserSummarry',
  POST_LIKE_URL: '/participation/reaction/like',
  POST_UNLIKE_URL: '/participation/reaction/unlike',
  POST_FOLLOW_URL: '/participation/reaction/follow',
  POST_UNFOLLOW_URL: '/participation/reaction/unfollow',
  SIGN_IN_BY_GOOGLE: '/participant/user/gpsignup',
  SIGN_IN_BY_FACEBOOK: '/participant/user/fbsignup',
  GET_COMMUNITY_INTEREST: '/entity/master/get_interests',
  JOIN_COMMUNITY_INTEREST: '/participant/community/join/interest',
  USER_ADD_EDIT: '/participant/user/add_or_edit',
  BELL_NOTIFICATION: 'participation/notification/bell',
  BELL_NOTIFICATION_COUNT: 'participation/notification/bell/unread',
  GET_POST: '/participant/feed/',
  REPOST: '/participation/reaction/repost',
  UNREPOST: '/participation/reaction/unrepost',
  DELETE_POST: '/participation/post/delete',
  SAVE_COMMENT: '/participation/reaction/add_comment',
  GET_POST_COMMENTS: '/participation/reaction/get_comments',
  DELETE_POST_COMMENT: '/participation/reaction/delete_comment',
  MY_COMMUNITIES: 'participant/feed/my_communities',
  CREATE_POST: '/participation/post/add_post_with_multipart_image',
  EDIT_POST: '/participation/post/edit_post_with_multipart_image',
  APP_CONFIG: '/participant/remote_config/?config_type=AppConfig',
  REPORT_SPAM: '/participation/post/spam_report',
  GET_USER_DETAILS: '/participant/user/get_details',
  SUBMIT_USER_POLL: '/participation/reaction/poll/vote',
  GET_USER_POLL: '/participant/feed/stream?setOrderKey=PollStream&poll_id=',
  SAVE_PUSH_TOKEN: '/participant/user/update_user_device',
  COMMUNITY_CATEGORY: 'participant/feed/community_category_home',
  JOIN_COMMUNITY: 'participant/community/join',
  UNJOIN_COMMUNITY: 'participant/community/unjoin',
  COMMUNITIES_BY_TYPE_ID:
    '/participant/feed/community_category_listing?sub_type=C&community_type_id=',
  FOLLOW: 'participation/reaction/follow',
  UNFOLLOW: 'participation/reaction/unfollow',
  GET_USER_POST:
    '/participant/feed/stream?setOrderKey=ProfilePostStream&userId=',
  GET_BRANCH_LINK_DATA: 'https://api2.branch.io/v1/url?url=',
  GET_ALL_THEME: '/participation/post/get_themes',
  GET_ENTITY_MASTER_DATA: '/entity/master/get_data',
  GET_ENTITY_MASTER_LOCATION_DATA:
    '/entity/master/get_location?&page_no=1&page_size=400',
  APPROVE_POST: '/participation/post/approve_or_delete',
  IMPRESSION_API_URL: '/user/event/producer',
  USER_DEACTIVATION_REASONS:
    '/participant/user/fetch_user_deactivation_reasons',
  USER_DEACTIVATION: '/participant/user/deactivate_or_reactivate',
  GET_ALL_CATEGORIES: '/entity/master/all_data',
  GET_ALL_LOCATIONS: '/entity/master/get_location?&page_no=1&page_size=400',
  GET_ARTICLES: '/participant/feed/stream?setOrderKey=ArticleStream',
  GET_TRENDING_ARTICLES: '/participant/feed/trending_article',
  GET_SIMILAR_CONTENTS: '/participant/feed/similar_contents',
  GET_TRENDING_HASHTAGS: '/participant/search/getHashtags',
  NOTIFICATION_MARK_AS_READ:
    'participation/notification/update_read_notifications',
  FEATURE_POST: '/participation/post/feature_post_creator',
  GET_USER_MENTION: '/entity/master/user_mention_suggestions',
  LINK_RENDER: '/participation/link/render',
  GET_REACTIONS: "/participation/reaction/get_reactions"

};
