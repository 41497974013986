import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  makeStyles,
} from '@material-ui/core';
import { useStoreActions, useStoreState } from 'easy-peasy';
import useReactRouter from 'use-react-router';
import { useLastLocation } from 'react-router-last-location';
import AdminPostModal from '../createPost/adminPostModal';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CustomDialogBox = () => {
  const lastLocation = useLastLocation();
  const { history } = useReactRouter();
  const classes = useStyles();
  const {
    open,
    dialogTitle,
    dialogContentText,
    btnPrimaryText,
    btnPrimaryAction,
    btnSecondaryText,
    btnSecondaryAction,
    isAdminPost,
  } = useStoreState((state) => state.dialogBox);
  const handleGoBack = useStoreActions((state) => state.navBar.handleGoBack);
  const setDialogBoxOpen = useStoreActions(
    (actions) => actions.dialogBox.setDialogBoxOpen
  );
  const { resetCreatePostObject } = useStoreActions(
    (state) => state.createPost
  );

  function handleDialogBox(goBack) {
    setDialogBoxOpen(false);
    goBack && resetCreatePostObject();
    handleGoBack({
      history: history,
      lastLocation: lastLocation,
      goBack: goBack,
    });
  }

  return (
    <React.Fragment>
      <Dialog
        TransitionComponent={Transition}
        open={open}
        classes={{ paper: classes.paperDialog }}
        onClose={() => setDialogBoxOpen(false)}
      >
        {!isAdminPost ? (
          <React.Fragment>
            <DialogTitle className={classes.dialogTitleSet}>
              {dialogTitle}
            </DialogTitle>
            <DialogContent className={classes.dialogContentSet}>
              <DialogContentText color="textPrimary">
                {dialogContentText}
              </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.dialogActionsSet}>
              <Button
                onClick={() => handleDialogBox(btnPrimaryAction)}
                color="primary"
                className={classes.dialogButton}
              >
                {btnPrimaryText}
              </Button>
              <Button
                onClick={() => handleDialogBox(btnSecondaryAction)}
                color="primary"
                className={classes.dialogButton}
              >
                {btnSecondaryText}
              </Button>
            </DialogActions>
          </React.Fragment>
        ) : (
          <React.Fragment>{isAdminPost && <AdminPostModal />}</React.Fragment>
        )}
      </Dialog>
    </React.Fragment>
  );
};

const useStyles = makeStyles((theme) => ({
  paperDialog: {
    margin: theme.spacing(4),
    padding: theme.spacing(4),
  },
  dialogTitleSet: {
    padding: theme.spacing(0),
  },
  dialogContentSet: {
    padding: theme.spacing(0),
  },
  dialogActionsSet: {
    padding: theme.spacing(0),
  },
  dialogButton: {
    padding: theme.spacing(0),
  },
}));
export default CustomDialogBox;
