import React, { useEffect } from 'react';
import { useNavBar } from '../../hooks/navBarHooks';
import { useScreenOpenTracker } from '../../hooks/analyticsHook';
import { Box, Grid } from '@material-ui/core';
import { useMobile } from '../../hooks/utilHooks';
import RenderIframe from '../common/renderIframe';
import useReactRouter from 'use-react-router';
import { getCurrentUser } from '../../services/authService';

const FooterPage = ({ title, url }) => {
  const isMobile = useMobile();
  const user = getCurrentUser();
  const { history } = useReactRouter();

  useNavBar({
    title,
    showBack: true,
    showBell: false,
  });
  useScreenOpenTracker({
    screenName: title,
  });

  useEffect(() => {
    if (title === 'Helpline' && !user) {
      return history.push('/');
    }
  }, [title, user, history]);

  return (
    <Box width={1} py={isMobile ? 2 : 3} px={4} bgcolor="white">
      <Grid>
        <Grid item lg={12} className="mxAuto">
          <RenderIframe url={url} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FooterPage;
