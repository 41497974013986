import deepFreeze from './commonUtil';

const eventNames = deepFreeze({
  screenOpen: 'Screen Open',
  postLiked: 'Post Liked',
  postReposted: 'Post Reposted',
  postUnReposted: 'Post UnReposted',
  postUnLiked: 'Post UnLiked',
  postCreated: 'Post Created',
  postEdited: 'Post Edited',
  postDeleted: 'Post Deleted',
  postReported: 'Post Reported',
  postShared: 'Post Shared',
  replyCreated: 'Reply Created',
  replyEdited: 'Reply Edited',
  replyDeleted: 'Reply Deleted',
  replyReported: 'Reply Reported',
  onboardingBirthDaySubmitted: 'Onboarding birthday submitted',
  onboardingBirthdaySkipped: 'Onboarding birthday skipped',
  interestSelected: 'Interest Selected',
  webLogin: 'Web Login',
  imageCardCliked: 'Image Card Clicked',
  profileFollowed: 'Profile Followed',
  profileUnFollowed: 'Profile UnFollowed',
  communityJoined: 'Community Joined',
  pollVoted: 'Poll Voted',
  anonymousToggleClicked: 'Anonymous Toggle Clicked',
  communityShared: 'Community Shared',
});

const eventProps = deepFreeze({
  screenName: 'Screen Name',
  title: 'Title',
  description: 'Description',
  id: 'ID',
  url: 'URL',
  body: 'Body',
  communityId: 'Community ID',
  communityName: 'Community Name',
  streamType: 'Stream Type',
  isNewUser: 'Is New User',
  currentUrl: 'Current URL',
  postId: 'Post ID',
  postType: 'Post Type',
  postCreatorType: 'Post Creator Type',
  language: 'Language',
  userId: 'User ID',
  interestId: 'Interest ID',
  dateOfBirth: 'Date of Birth',
  subType: 'Sub Type',
  promocardUrl: 'Promocard Url',
  promocardId: 'Promocard ID',
  followedUserId: 'Followed User ID',
  followedChampionId: 'Followed Champion ID',
  name: 'Name',
  type: 'Type',
});

export { eventNames, eventProps };
