import authService from '../services/authService';
import { userType } from './constants';
import { mapUserType } from '../mappers/modelMapper';
import { getSlug } from './textUtil';

function setMobileNoLength(mobile) {
  if (mobile.length > 10) {
    mobile = mobile.slice(0, 10);
    return mobile;
  } else {
    return mobile;
  }
}

function getFirstAndLastName(name) {
  let [fName, ...lName] = name.split(' ');
  const firstName = fName ? fName : '';
  const lastName = lName ? lName.join(' ') : '';
  return {
    firstName,
    lastName,
  };
}
function getBase64Image(base64Image) {
  const getBase64Name = base64Image.split(',');
  const decodedImage = getBase64Name[1] ? getBase64Name[1] : '';
  return decodedImage;
}

function checkUserType(typeId) {
  const user = authService.getCurrentUser();
  if (!user) return;
  return user.userTypeId === typeId ? true : false;
}
function isAdminOrModerator() {
  return (
    checkUserType(userType.isAdmin) || checkUserType(userType.isCommunityMod)
  );
}
function checkOpenAdminModalStatus(isSchedulePost, isEditablePost) {
  const IS_SCHEDULE_POST = isSchedulePost;
  const IS_EDITABLE_POST = isEditablePost;
  const IS_ADMIN_OR_MODERATOR = isAdminOrModerator();

  switch ((IS_SCHEDULE_POST, IS_EDITABLE_POST, IS_ADMIN_OR_MODERATOR)) {
    case IS_ADMIN_OR_MODERATOR && !IS_SCHEDULE_POST && IS_EDITABLE_POST:
      return false;
    case IS_ADMIN_OR_MODERATOR && IS_SCHEDULE_POST && IS_EDITABLE_POST:
      return false;
    case IS_ADMIN_OR_MODERATOR:
      return true;
    default:
      return false;
  }
}
function hideNotificationByCategory(category) {
  return category === 'FRIEND_REQUEST' ? true : false;
}
function getUserLinkUrl(user) {
  if (!user) return;
  return (
    mapUserType(user.user_type_id) +
    '/' +
    getSlug(user.name) +
    '/' +
    btoa(user.user_id)
  );
}
export {
  setMobileNoLength,
  getFirstAndLastName,
  getBase64Image,
  checkUserType,
  isAdminOrModerator,
  checkOpenAdminModalStatus,
  hideNotificationByCategory,
  getUserLinkUrl,
};
