import { convertDateToUTC } from '../utils/dateUtil';
import { postToFollowerObj } from '../mappers/communityMapper';
import { strings } from '../utils/constants';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useLoggedInUser } from './userHooks';
import { themePostConfig } from '../utils/constants';
import analytics from '../services/analyticsService';
import { eventNames, eventProps } from '../../src/utils/eventConstants';
import postService from '../services/postService';
import { getUserMentionObj, getEntityTypeObj } from '../utils/editorUtil';
import { convertToRaw } from 'draft-js';
import { getUrlFromText } from '../utils/textUtil';

function useCreateOrEditPostRequest(post) {
  const user = useLoggedInUser();
  const setErrorMessage = useStoreActions(
    (actions) => actions.snackBar.setMessage
  );
  const {
    setPostCreatorType,
    setHandleDeleteItem,
    setSelectedCommunity,
    checkThemePostStatus,
    setPostDescription,
    setRichLinkData,
    setUserMentions,
  } = useStoreActions((state) => state.createPost);
  let {
    postCreatorType,
    selectedTheme,
    isThemeChange,
    isThemePost,
    files,
    postDescription,
    ogTitle,
    ogImageUrl,
    ogRequestedUrl,
    isOgVideoLink,
    ogDescription,
    userMentions,
  } = useStoreState((state) => state.createPost.postObject);
  const isEditablePost = post ? true : false;
  function handleUserAnonymity(event) {
    const checked = event.target.checked;
    setPostCreatorType(checked ? 'Anonymous' : user.name);
  }
  if (
    isEditablePost &&
    post &&
    post.userMentions &&
    userMentions &&
    userMentions.length === 0
  ) {
    userMentions = post.userMentions;
  }
  userMentions = getUserMentionObj(
    userMentions,
    postDescription || post.description,
    isEditablePost
  );
  //}
  if (post && post.isThemePost && !isThemeChange) {
    isThemePost = post.isThemePost;
    selectedTheme = {
      themeImageUrl: post.themeImageUrl,
      themeTextColor: post.themePostDescriptionColor,
      themeTextFontSize: post.themePostDescriptionFontSize,
    };
  } else if (isThemeChange && selectedTheme !== null) {
    isThemePost = isThemeChange;
    selectedTheme = {
      themeImageUrl: selectedTheme.themeImageUrl,
      themeTextColor: selectedTheme.themeTextColor,
      themeTextFontSize: themePostConfig.themeTextFontSize,
    };
  }
  const buildRequestBody = ({
    scheduleDate,
    post,
    selectedTheme,
    isThemePost,
    selectedCommunity,
    deletedImageIds,
    userMentions,
  }) => {
    const postId = (post && post.entityId) || '';
    const userId = user && user.id;
    const userParticipantId = user && user.userParticipantId;
    const schedulePostISODate = scheduleDate && convertDateToUTC(scheduleDate);
    const themePostRequest = {
      theme_image_url: selectedTheme && selectedTheme.themeImageUrl,
      theme_text_color: selectedTheme && selectedTheme.themeTextColor,
      theme_text_font_size: selectedTheme && selectedTheme.themeTextFontSize,
      is_theme_post: isThemePost,
    };
    const postToFollowersRequest = selectedCommunity.id ===
      postToFollowerObj[0].id && {
      recipient_participant_id: userParticipantId,
      recipient_id: userId,
    };

    const linkRenderRequest = ogRequestedUrl && {
      og_title_s: ogTitle,
      og_image_url_s: ogImageUrl,
      og_requested_url_s: ogRequestedUrl,
      is_og_video_link_b: isOgVideoLink,
      og_description_s: ogDescription,
    };

    let postRequestBody = {
      community_id: selectedCommunity.id,
      creator_type:
        postCreatorType === strings.ANONYMOUS ? 'ANONYMOUS' : 'USER',
      description:
        isEditablePost && !postDescription ? post.description : postDescription,
      id: postId,
      is_active: isEditablePost ? true : false,
      crdt: schedulePostISODate ? schedulePostISODate : '',
      has_mentions: userMentions && userMentions.length > 0 ? true : false,
      user_mentions: userMentions && [...userMentions],
      ...postToFollowersRequest,
      ...linkRenderRequest,
    };
    postRequestBody = themePostRequest.is_theme_post
      ? { ...postRequestBody, ...themePostRequest }
      : postRequestBody;
    if (isEditablePost)
      postRequestBody.delete_image_id = deletedImageIds.map((id) => id + '');
    return postRequestBody;
  };
  function validateCreatePost(selectedCommunity, files, postDescription) {
    if (!selectedCommunity.id) {
      setErrorMessage('Please choose the community to post in');
    } else if (!postDescription) {
      setErrorMessage('Please Enter Something in Description');
    }
    if (!selectedCommunity.id || (!files.length && !postDescription)) {
      return false;
    }
    if (
      postCreatorType === 'Anonymous' &&
      selectedCommunity.id === postToFollowerObj[0].id
    ) {
      setErrorMessage('Cannot post to your followers anonymously.');
      return false;
    }
    return true;
  }
  function createPostAnalyticsTrack({
    selectedCommunity,
    postDescription,
    postCreatorType,
    createPostRequestBody,
  }) {
    analytics.track(
      isEditablePost ? eventNames.postEdited : eventNames.postCreated,
      {
        [eventProps.communityId]: selectedCommunity.id,
        [eventProps.communityName]: selectedCommunity.name,
        [eventProps.description]: postDescription,
        [eventProps.postCreatorType]: postCreatorType,
        [eventProps.language]: 'en',
        [eventProps.postType]: createPostRequestBody.is_theme_post
          ? 'Theme Post'
          : 'Normal Post',
      }
    );
  }
  const handleDeleteItem = (payload) => {
    setHandleDeleteItem(payload);
  };
  const handleSelectCommunity = (community) => {
    setSelectedCommunity({ id: community.id, name: community.name });
  };
  const handleSetPostDescription = (value) => {
    setPostDescription(value);
    const renderUrl = getUrlFromText(value);
    if (renderUrl) {
      renderLinkUrl(renderUrl);
    } else {
      setRichLinkData({ ogResponseData: '' });
    }
    checkThemePostStatus({ files, selectedTheme, postDescription });
  };
  /**
   *
   * @param {string} url
   *  Call API for render Link url and set to Rich Link Data
   */
  async function renderLinkUrl(url) {
    const res = url && (await postService.getRichLinkData(url));
    const ogResponseData =
      res.status === 'SUCCESS'
        ? {
            ogTitle: res.og_title_s,
            ogImageUrl: res.og_image_url_s,
            ogRequestedUrl: res.og_requested_url_s,
            isOgVideoLink: res.is_og_video_link_b,
            ogDescription: res.og_description_s,
          }
        : {
            ogTitle: '',
            ogImageUrl: '',
            ogRequestedUrl: '',
            isOgVideoLink: '',
            ogDescription: '',
          };
    setRichLinkData({ ogResponseData });
  }
  /**
   *
   * @param {object} payload
   * handle set user mention object in state
   */
  const handleSetUserMention = (payload) => {
    setUserMentions(payload);
  };
  /**
   *
   * @param {*} editorState
   * Function to get Content from editor
   * An entity is an object that represents metadata for a range of text within a Draft editor.
   * It has three properties:
   * type: --- 'LINK','MENTION','PHOTO'
   * mutability
   * data:object containing metadata for the entity
   * For ref: https://draftjs.org/docs/api-reference-entity
   */
  function getEditorContent(editorState) {
    const blocks = convertToRaw(editorState.getCurrentContent()).blocks; // blocks represents no of lines in the post
    const entityMap = convertToRaw(editorState.getCurrentContent()).entityMap; //entityMap represents blocks type in the post for Eg.(User Tagged, Links)
    const { mentionData } = getEntityTypeObj(entityMap);
    const content = blocks
      .map((block) => (!block.text.trim() && '\n') || block.text)
      .join('\n');
    return {
      content,
      mentionData,
    };
  }
  /**
   *
   * @param {state} editorState
   * @param {type} context
   * set Editor State from editor and set user mention and post
   */
  function handleSetEditorState(editorState, context) {
    const { content, mentionData } = getEditorContent(editorState); //Get content from editor state
    if (context === 'POST') {
      handleSetUserMention(mentionData); //set user Mention Object
      content && handleSetPostDescription(content); //set post description
    }
  }
  return {
    buildRequestBody,
    handleUserAnonymity,
    selectedTheme,
    isThemePost,
    validateCreatePost,
    files,
    setErrorMessage,
    createPostAnalyticsTrack,
    handleDeleteItem,
    handleSelectCommunity,
    handleSetPostDescription,
    handleSetUserMention,
    handleSetEditorState,
    renderLinkUrl,
    userMentions,
    getEditorContent,
  };
}
export { useCreateOrEditPostRequest };
