import moment from 'moment';

function ellipsize(text, maxLength) {
  return text && text.length > maxLength
    ? text.substr(0, maxLength) + '...'
    : text;
}

function momentTimeFormat(dt) {
  moment.relativeTimeThreshold('s', 0);
  moment.relativeTimeThreshold('ss', 0);
  moment.relativeTimeThreshold('m', 61);
  moment.relativeTimeThreshold('h', 25);
  moment.relativeTimeThreshold('d', 31);
  const endTime = moment(dt);
  return moment(new Date()).to(endTime, true);
}

//Format the given input into string in suitable terms of 'k', 'M', etc.
function numberCountFormatter(n) {
  if (n < 999) {
    //return whatever the value it is as minimum value to represent in shorthand is 1000.
    return n;
  }
  const ranges = [
    { divider: 1e18, suffix: 'E' },
    { divider: 1e15, suffix: 'P' },
    { divider: 1e12, suffix: 'T' },
    { divider: 1e9, suffix: 'G' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'k' },
  ];

  for (let i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      const value =
        (n / ranges[i].divider) % 1 !== 0
          ? (n / ranges[i].divider).toFixed(1) // Upto only a single digit of the decimal.
          : n / ranges[i].divider;
      return value + ranges[i].suffix;
    }
  }
  return n.toString();
}
function getSlug(text) {
  if (!text) return;
  let cleanText = text
    .trim()
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, '-');
  cleanText = cleanText.replace(/-+/g, '-');
  return cleanText;
}

function getUrlFromText(text) {
  let match = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
  let firstUrl = match.exec(text);
  const urlToRender = firstUrl && firstUrl.length && firstUrl[0];
  return urlToRender;
}
function replaceBetween(str, start, end, what) {
  return str.substring(0, start) + what + str.substring(end);
}

export {
  ellipsize,
  momentTimeFormat,
  numberCountFormatter,
  getSlug,
  getUrlFromText,
  replaceBetween,
};
