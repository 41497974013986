import { makeStyles } from '@material-ui/core/styles';

const mentionStyles = makeStyles((theme) => ({
  //listing only classes which are very generic and used in more than 3-4 components.
  '@global': {
    '.draftJsMentionPlugin__mention__29BEd': {
      color: '#2793e7',
      cursor: 'pointer',
      display: 'inline-block',
      paddingLeft: '2px',
      paddingRight: '2px',
      borderRadius: '2px',
      textDecoration: 'none',
    },
    '.draftJsMentionPlugin__mention__29BEd_visited': {
      color: '#575f67',
      cursor: 'pointer',
      display: 'inline-block',
      background: '#e6f3ff',
      paddingLeft: '2px',
      paddingRight: '2px',
      borderRadius: '2px',
      textDecoration: 'none',
    },
    '.draftJsMentionPlugin__mention__29BEd_hover': {
      color: '#2793e7',
      outline: '0',
      cursor: 'pointer',
    },
    '.draftJsMentionPlugin__mention__29BEd_focus': {
      color: '#2793e7',
      outline: '0',
      cursor: 'pointer',
    },
    '.draftJsMentionPlugin__mention__29BEd_active': {
      color: '#3c3c3c',
      background: '#455261',
    },
    '.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm': {
      padding: '7px 10px 3px 10px',
      transition: 'background-color 0.4s cubic-bezier(.27,1.27,.48,.56)',
    },
    '.draftJsMentionPlugin__mentionSuggestionsEntry__3mSwm_active': {
      backgroundColor: '#cce7ff',
    },
    '.draftJsMentionPlugin__mentionSuggestionsEntryFocused__3LcTd': {
      backgroundColor: '#e6f3ff',
    },
    '.draftJsMentionPlugin__mentionSuggestionsEntryText__3Jobq': {
      display: 'inline-block',
      marginLeft: '8px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '200px',
      marginBottom: '0.2em',
      color: '#3c3c3c',
      fontSize: '12px',
      '& :hover': {
        color: '#2793e7',
      },
    },
    '.draftJsMentionPlugin__mentionSuggestionsEntryAvatar__1xgA9': {
      display: 'inline-block',
      width: '24px',
      height: '24px',
      borderRadius: '12px',
    },
    '.draftJsMentionPlugin__mentionSuggestions__2DWjA': {
      border: '1px solid #eee',
      marginTop: '0.4em',
      position: 'absolute',
      minWidth: '220px',
      maxWidth: '274px',
      background: '#fff',
      borderRadius: '2px',
      boxShadow: '0px 4px 30px 0px rgba(220,220,220,1)',
      cursor: 'pointer',
      paddingTop: '8px',
      paddingBottom: '8px',
      zIndex: '100',
      display: 'flex',
      WebkitBoxOrient: 'vertical',
      WebkitBoxDirection: 'normal',
      flexDirection: 'column',
      boxSizing: 'border-box',
      WebkitTransform: 'scale(0)',
      transform: 'scale(0)',
    },
    '.public-DraftEditorPlaceholder-root': {
      color: 'rgb(186, 186, 193)',
      paddingTop: '3px',
      paddingLeft: '3px',
      marginBottom: '-20px',
      backgroundColor: '#fff',
    },
    '.public-DraftStyleDefault-block': {
      position: 'relative',
      zIndex: 1,
    },
  },
}));

export default mentionStyles;
