import React from 'react';
import { Box, InputBase, makeStyles } from '@material-ui/core';
import { ReactComponent as CalenderImage } from '../../img/onboarding/calendar.svg';
import { useStoreActions } from 'easy-peasy';
import CustomButton from '../common/customButton';

const SchedulePostCalendar = ({ handleDateChange, scheduleDate }) => {
  const classes = useStyles();
  const { setDialogBoxOpen, setDialogContent } = useStoreActions(
    (actions) => actions.dialogBox
  );

  const handleShowScheduleMessage = (e) => {
    e.preventDefault();
    setDialogBoxOpen(true);
    setDialogContent({
      dialogTitle: 'Schedule Post ?',
      dialogContentText: null,
      btnPrimaryText: '',
      btnSecondaryText: '',
      isOPenCalender: false,
      isAdminPost: true,
      showSchedulePostMessage: true,
    });
  };
  return (
    <React.Fragment>
      <Box position="relative" mt={7}>
        <InputBase
          type="datetime-local"
          onChange={(e) => handleDateChange(e)}
          fullWidth={true}
          value={scheduleDate}
          autoFocus={false}
          className={classes.textField}
        />
        <CalenderImage className={classes.calendarIcon} />
        <CustomButton
          className={classes.setButtonText}
          color="primary"
          text="OK"
          onClick={(e) => handleShowScheduleMessage(e)}
        />
      </Box>
    </React.Fragment>
  );
};
export default SchedulePostCalendar;
const useStyles = makeStyles((theme) => ({
  calendarIcon: {
    position: 'absolute',
    top: '6px',
    right: '0px',
    background: '#fff',
    pointerEvents: 'none',
  },
  textField: {
    marginBottom: theme.spacing(1),
    borderBottom: '2px solid #dcdcdc',
  },
  setButtonText: {
    float: 'right',
  },
}));
