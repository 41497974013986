const urlMap = {
  HOME: '/',
  SIGNUP: '/signup',
  FEED: '/feed',
  LOGOUT: '/logout',
  ONBOARDING_GENDER: '/onboarding/gender',
  MALE_RESTRICTED: '/onboarding/male-restricted',
  ONBOARDING_INTEREST: '/onboarding/interest',
  ONBOARDING_BIRTHDAY: '/onboarding/birthday',
};

const imageUrls = {
  DEFAULT_USER_IMG: 'https://img.sheroes.in/img/default/user.png',
  ANONYMOUS_IMG:
    'https://img.sheroes.in/img/uploads/sheroespage/logoes/1549951886group-14.svg',
  DEFAULT_COMMUNITY_LOGO:
    'https://img.sheroes.in/img/uploads/sheroespage/logoes/1553753187close-community.svg',
  IMAGE_UPLOAD_ACCEPT_TYPE: 'image/jpeg, image/png, image/gif',
};

const strings = {
  ANONYMOUS: 'Anonymous',
  USER: 'USER',
};

const pageSize = 10;
const maxPageSize = 25;

const onboardingSteps = [
  urlMap.ONBOARDING_GENDER,
  urlMap.ONBOARDING_INTEREST,
  urlMap.ONBOARDING_BIRTHDAY,
  urlMap.FEED,
];
const userDeactivatedUrl = {
  USER_DEACTIVATED: '/user-deactivated',
};
const cookieOptions = {
  AUTH_TOKEN: 'sheRemMeCodeNew',
  USER_ID: 'userId',
  COOKIE_EXPIRE_DAYS: 365,
  LOGIN_EVENT_FIRED: 'loginEventFired',
};
const impressionConstants = {
  MAX_RETRY_COUNT: 4,
  INITIAL_NETWORK_RETRY_DELAY: 5000,
  MIN_ENGAGEMENT_TIME: 250,
};
const allScreen = {
  CREATE_POST: '/create-post',
  EDIT_POST: '/edit-post',
};
const firebaseConfig = {
  messagingSenderId: '409228886131',
};
const hiddenCommunityTabs = ['leaderboead', 'leaderboard'];
const themePostConfig = {
  maxCharLimit: 130,
  maxLineBreakLimit: 3,
  themeTextFontSize: 22,
};
const relationShipStatus = [
  {
    value: 'Select Status',
    label: 'Select Status',
  },
  {
    value: 'Single',
    label: 'Single',
  },
  {
    value: 'In a Relationship',
    label: 'In a Relationship',
  },
  {
    value: 'Married',
    label: 'Married',
  },
  {
    value: 'Divorced',
    label: 'Divorced',
  },
  {
    value: 'Widowed',
    label: 'Widowed',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];
const vernacularLanguageList = [
  {
    key: 'en',
    value: 'English',
    name: '',
  },
  {
    key: 'hi',
    value: 'Hindi',
    name: '(हिंदी)',
  },
  {
    key: 'te',
    value: 'Telugu',
    name: '(తెలుగు)',
  },
  {
    key: 'mr',
    value: 'Marathi',
    name: '(मराठी)',
  },
  {
    key: 'bn',
    value: 'Bangla',
    name: '(বাংলা)',
  },
];

const userType = {
  isAdmin: 2,
  isCommunityMod: 13,
};

const defaultArticleCategory = [
  {
    title: 'Latest',
    key: '/articles',
    tabIndex: 0,
    categoryUrl: '/articles',
    dataUrl: '',
  },
  {
    title: 'Trending',
    key: '/articles/trending',
    tabIndex: 1,
    categoryUrl: '/articles/trending',
    dataUrl: '',
  },
];

const SHEROES_ADMIN_URL = 'https://web.sheroes.com';

export {
  urlMap,
  onboardingSteps,
  imageUrls,
  strings,
  pageSize,
  maxPageSize,
  userDeactivatedUrl,
  cookieOptions,
  allScreen,
  firebaseConfig,
  hiddenCommunityTabs,
  themePostConfig,
  relationShipStatus,
  vernacularLanguageList,
  impressionConstants,
  userType,
  defaultArticleCategory,
  SHEROES_ADMIN_URL,
};
