import React from 'react';
import { Redirect } from 'react-router-dom';
import Signup from '../scenes/home'; // Don't use lazy loading for Home page
import NotFound from '../components/notFound';

export default [
  {
    path: '/not-found',
    component: NotFound,
  },
  {
    path: '/',
    component: Signup,
  },
  {
    exact: false,
    last: true, //Denotes that this is the last defined route. Used in `routeUtils.isSupportedRoute`
    render: (props) => <Redirect to="/not-found" />,
  },
];
