import React from 'react';
import { Redirect } from 'react-router-dom';
import { lazyPreload } from '../utils/lazyUtil';

const LoginForm = lazyPreload(() => import('../components/loginForm'));
const Logout = lazyPreload(() => import('../components/logout'));
const DeactivatedUser = lazyPreload(() =>
  import('../components/common/deactivatedUser')
);

export default [
  {
    path: '/login',
    component: LoginForm,
  },
  {
    path: '/signup',
    render: (props) => <Redirect to="/" />,
  },
  {
    path: '/sign-in',
    render: (props) => <Redirect to="/login" />,
  },
  {
    path: '/user-deactivated',
    component: DeactivatedUser,
  },
  {
    path: '/logout',
    component: Logout,
  },
];
