import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  Box,
} from '@material-ui/core';
import communityService from '../../services/communityService';
import CustomButton from '../common/customButton';
import AdapterLink from '../common/adapterLink';
import imgParams from '../../utils/imageUtil';
import { useTablet } from '../../hooks/utilHooks';
import Loader from '../../components/common/loader';
import { ellipsize } from '../../utils/textUtil';

const MyCommunitiesDrawer = () => {
  const [listOfCommunities, setListOfCommunities] = useState(
    communityService.getCommunityList()
  );
  const classes = useStyles();
  const isTablet = useTablet();

  useEffect(() => {
    async function newGetCommunityList() {
      const data = await communityService.getCommunityFromServices();
      !listOfCommunities && setListOfCommunities(data);
    }
    newGetCommunityList();
  }, [listOfCommunities]);

  return (
    <List className={classes.scrollSet}>
      <Typography
        variant="subtitle2"
        display="block"
        align="center"
        className={classes.communityHeading}
      >
        My Communities
      </Typography>
      <Box className={!isTablet && classes.listMain}>
        <Box width={isTablet ? 1 : 0.84} ml={!isTablet ? 4 : ''}>
          {!listOfCommunities && <Loader />}
          {listOfCommunities &&
            listOfCommunities.map((myCommunity, index) => (
              <ListItem
                key={index}
                className={classes.listItem}
                to={myCommunity.communitySlug}
                component={AdapterLink}
              >
                <ListItemAvatar>
                  <Avatar
                    alt={myCommunity.logoAltText}
                    src={imgParams(myCommunity.thumbnailImageUrl, 60, 60)}
                    className={classes.setCommunityLogo}
                  />
                </ListItemAvatar>
                <ListItemText
                  color="primary"
                  primary={
                    <Typography
                      variant="overline"
                      display="block"
                      color="textPrimary"
                    >
                      {ellipsize(myCommunity.name, 25)}
                    </Typography>
                  }
                />
                {myCommunity.totalNewPostNumber > 0 && (
                  <Typography variant="overline" className={classes.newPostNo}>
                    {myCommunity.totalNewPostNumber > 98
                      ? '99+'
                      : myCommunity.totalNewPostNumber}
                  </Typography>
                )}
              </ListItem>
            ))}
          <Box className="clearfix"></Box>
        </Box>
      </Box>

      <CustomButton
        to="/communities"
        component={AdapterLink}
        variant={isTablet ? 'primary' : ''}
        color="primary"
        text="Explore all"
        size="regular"
        className={
          isTablet
            ? `${classes.allCommunityBtn} stickToBottom`
            : classes.allCommunityBtn
        }
      ></CustomButton>
    </List>
  );
};

const useStyles = makeStyles((theme) => ({
  listMain: {
    width: '110%',
    height: '332px',
    overflowY: 'auto',
  },
  listItem: {
    width: '50%',
    height: theme.spacing(27),
    textAlign: 'center',
    display: 'inherit',
    float: 'left',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  setCommunityLogo: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0px auto',
    width: theme.spacing(13),
    height: theme.spacing(13),
    border: ' 1px solid #f1f1f1',
    borderRadius: '50%',
  },
  communityHeading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(7),
  },
  allCommunityBtn: {
    width: '272px',
    left: 'inherit!important',
    right: theme.spacing(0),
    borderRadius: 'inherit',
  },
  newPostNo: {
    position: 'absolute',
    top: theme.spacing(2),
    border: '1px solid #fff',
    right: '30px',
    borderRadius: theme.spacing(1.5),
    background: theme.palette.primary.main,
    color: 'white',
    width: theme.spacing(6),
    height: theme.spacing(4.3),
  },
  scrollSet: {
    width: theme.spacing(68),
  },
}));

export default MyCommunitiesDrawer;
