import { cookieOptions } from './constants';
import { convertDaysToGMTString } from './dateUtil';
import { isLocalhost } from './appUtil';

function getCookie(cname) {
  let name = cname + '=';
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function setCookie(cname, cvalue, expireDays) {
  const domain = getDomain();
  expireDays = expireDays || cookieOptions.COOKIE_EXPIRE_DAYS; // if cookies expire not set then default  365 days.
  const expireTime = convertDaysToGMTString(expireDays); //set cookies expire days in number
  const expires = ';expires=' + expireTime;
  const domainCookie = ';domain=' + domain;
  document.cookie = cname + '=' + cvalue + domainCookie + expires + ';path=/';
}
function deleteCookie(name) {
  const domain = getDomain();
  const domainCookie = 'domain=' + domain;
  document.cookie =
    name +
    '=;' +
    domainCookie +
    ';Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  document.cookie =
    name +
    '=;domain=sheroes.com;Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}
function getDomain() {
  const domainName = isLocalhost ? 'localhost' : setDomainName();
  return domainName;
}
function setDomainName() {
  if (
    document.location.hostname === 'm.sheroes.com' ||
    document.location.hostname === 'sheroes.com'
  ) {
    return '.sheroes.com';
  } else {
    return document.location.hostname;
  }
}

export { getCookie, setCookie, deleteCookie, getDomain };
