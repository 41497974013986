import React, { useState } from 'react';
import OptionsMenu from './optionsMenu';
import UserAvatar from '../common/userAvatar';
import { Typography, Box, CardHeader } from '@material-ui/core';
import imgParams from '../../utils/imageUtil';
import AdapterLink from '../common/adapterLink';
import ChampionIcon from '../../img/championIcon.svg';
import PostActionButton from './postActionButton';
import LikeIcon from '../../img/heart.svg';
import LikedIcon from '../../img/heart-liked.svg';
import { useLikeOnComment } from '../../hooks/postHooks';

const CommentItem = ({
  comment,
  handleDeleteComment,
  postId,
  postEntityId,
  handleClick,
  shouldTruncate,
  commentIndex,
  post,
  isPostDetail,
  isArticleDetail,
}) => {
  const isDetails = isPostDetail || isArticleDetail;

  const { isLiked, likesCount, likeComment } = useLikeOnComment(
    postId,
    commentIndex,
    isDetails
  );

  const [likeStatus, setLikeStatus] = useState();

  async function likeOnComment(e, commentId) {
    e.stopPropagation();
    likeComment({
      postId: post.id,
      commentIndex: commentIndex,
      postEntityId: post.parentId,
      commentId: commentId,
      likeStatus: !isLiked,
      isPostDetail: isPostDetail,
    });
    setLikeStatus(!isLiked);
  }

  return (
    <React.Fragment>
      {comment && comment.description && (
        <Box>
          <CardHeader
            title={
              <Box
                onClick={handleClick}
                to={comment.authorDeepLinkUrl || ''}
                component={AdapterLink}
                className="removeUnderline"
                display="inline-block"
              >
                <Typography
                  gutterBottom
                  variant="body2"
                  color="textPrimary"
                  component="span"
                >
                  {comment.authorName}
                </Typography>
              </Box>
            }
            avatar={
              <Box display="inline-block" position="relative">
                <UserAvatar
                  userImgUrl={imgParams(
                    comment && comment.authorImageUrl,
                    40,
                    40,
                    'auto'
                  )}
                  comment={comment}
                  onClick={(e) => e.stopPropagation()}
                />
                {comment.isAuthorMentor && (
                  <img
                    src={ChampionIcon}
                    alt="Champion Icon"
                    className="championIconStyle"
                  />
                )}

                {comment.profileBadgeUrl && comment.showProfileBadge && (
                  <img
                    className="postShareIcon"
                    src={comment.profileBadgeUrl}
                    alt="she badge"
                  />
                )}
              </Box>
            }
            action={
              <OptionsMenu
                handleDeleteComment={handleDeleteComment}
                comment={comment}
                postId={postId}
                postEntityId={postEntityId}
              />
            }
            subheader={
              <React.Fragment>
                <Box whiteSpace="pre-line">
                  <Typography
                    gutterBottom
                    variant="body1"
                    color="textPrimary"
                    component="p"
                    className="wordBreak"
                    dangerouslySetInnerHTML={{
                      __html: comment.description,
                    }}
                  ></Typography>
                  <Typography
                    variant="caption"
                    color="textSecondary"
                    component="p"
                  >
                    {comment.createdAt}
                  </Typography>

                  <PostActionButton
                    text={likesCount > 0 ? likesCount : ''}
                    icon={
                      isLiked ? (
                        <img
                          src={LikedIcon}
                          alt="LikedIcon"
                          width="14px"
                          height="12px"
                        />
                      ) : (
                        <img
                          src={LikeIcon}
                          alt="LikeIcon"
                          width="14px"
                          height="12px"
                        />
                      )
                    }
                    handleAction={(e) =>
                      likeOnComment(e, comment.commentId, likeStatus)
                    }
                    activeStatus={isLiked}
                  />
                </Box>
              </React.Fragment>
            }
          />
        </Box>
      )}
    </React.Fragment>
  );
};

export default CommentItem;
