import moment from 'moment';
const DAY_FIRST_FORMAT = 'DD MMM YYYY HH:mm';
const YEAR_FIRST_FORMAT = 'YYYY-MM-DDThh:mm:ss';

function convertDaysToGMTString(days) {
  var date = new Date();
  // Get unix milliseconds at current time plus number of days
  date.setTime(+date + days * 86400000); //24 * 60 * 60 * 1000

  return date.toGMTString();
}
function convertDateToString(date) {
  return moment(date).format('YYYY-MM-DD');
}
function convertDateToUTC(date) {
  const iSODate = moment(date).toISOString();
  return iSODate;
}
function getTodayDate(format) {
  return moment().format(format);
}
function getDateFormatByValue(date, format) {
  return moment(date).format(format);
}
export {
  convertDaysToGMTString,
  convertDateToString,
  convertDateToUTC,
  getTodayDate,
  getDateFormatByValue,
  DAY_FIRST_FORMAT,
  YEAR_FIRST_FORMAT,
};
