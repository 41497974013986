const imgParams = (imgName, width, height, fit) => {
  let fullImageName = '?tr=',
    widthparam = '',
    heightparam = '',
    fitparam = '',
    concatImage;
  if (imgName === '') return;
  if (width && typeof width === 'number') {
    widthparam = 'w-' + width;
  }
  if (height && typeof height === 'number') {
    heightparam = ',h-' + height;
  }
  if (fit && typeof fit === 'string') {
    fitparam = ',fo-' + fit;
  }

  concatImage = imgName + fullImageName + widthparam + heightparam + fitparam;

  return concatImage;
};
export default imgParams;
