import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import CustomButton from '../common/customButton';
import { useMobile, useTablet } from '../../hooks/utilHooks';
import { useStoreActions } from 'easy-peasy';

const SignUpCommunitiesCard = ({ image, text, buttonText, redirectUrl }) => {
  const classes = useStyles();
  const { userLoginModal } = useStoreActions((actions) => actions.user);
  const isMobile = useMobile();
  const isTablet = useTablet();

  const handleClick = (url) => {
    userLoginModal({ showModal: true, referrerUrl: url });
  };

  return (
    <React.Fragment>
      <Box
        mb={10}
        px={isMobile ? 0 : 6}
        display="inline-block"
        align="center"
        width={isMobile ? '100%' : isTablet ? '50%' : '33.3%'}
      >
        <Box
          height={isMobile ? 'auto' : '196px'}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <img src={image} alt="Inspiration" />
        </Box>
        <Box height={isMobile ? 'auto' : '56px'}>
          <Typography variant="subtitle2">{text}</Typography>
        </Box>
        <CustomButton
          className={classes.button}
          variant="outlined"
          text={buttonText}
          onClick={() => handleClick(redirectUrl)}
        />
      </Box>
    </React.Fragment>
  );
};
export default SignUpCommunitiesCard;

const useStyles = makeStyles((theme) => ({
  button: {
    width: '130px',
    height: '36px',
    padding: theme.spacing(0),
    marginTop: theme.spacing(2),
  },
}));
