import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleLogin } from 'react-google-login';
import auth from '../../services/authService';
import googleBtn from '../../img/onboarding/google-round.svg';
import { Typography, Button, Box } from '@material-ui/core';

const GoogleLoginButton = ({ setIsLoading }) => {
  const classes = useStyles();
  async function responseGoogle(response) {
    setIsLoading(true);
    const accessToken = response.accessToken;
    await auth.socialLogin(accessToken, 'google');
  }
  const responseGoogleFailure = () => {};
  const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;

  return (
    <React.Fragment>
      <GoogleLogin
        clientId={clientId}
        autoLoad={false}
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            className={classes.socialLoginButton}
          >
            <Box width={20}>
              <img
                src={googleBtn}
                alt="Continue with Google"
                className={classes.socialIcon}
              />
            </Box>

            <Typography
              variant="body2"
              component="span"
              className={classes.buttonTitle}
            >
              Continue with Google
            </Typography>
          </Button>
        )}
        scope="profile email"
        onSuccess={responseGoogle}
        onFailure={responseGoogleFailure}
        cookiePolicy={'single_host_origin'}
      />
    </React.Fragment>
  );
};
export default GoogleLoginButton;
const useStyles = makeStyles((theme) => ({
  socialLoginButton: {
    marginBottom: theme.spacing(3),
    background: '#dd4b39',
    padding: theme.spacing(2),
    margin: theme.spacing(0),
    color: ' #fff',
    textTransform: 'inherit',
    width: '75%',
    justifyContent: 'left',
    '&:hover': {
      background: '#dd4b39',
    },
  },
  socialIcon: {
    verticalAlign: 'middle',
  },
  buttonTitle: {
    paddingLeft: theme.spacing(4),
  },
}));
