const CleverTap = {
  initialize: function (clevertapAccountId) {
    window.clevertap = {
      event: [],
      profile: [],
      account: [],
      onUserLogin: [],
      notifications: [],
    };
    window.clevertap.account.push({ id: clevertapAccountId });
    (function () {
      var wzrk = document.createElement('script');
      wzrk.type = 'text/javascript';
      wzrk.async = true;
      wzrk.src =
        ('https:' === document.location.protocol
          ? 'https://d2r1yp2w7bby2u.cloudfront.net'
          : 'http://static.clevertap.com') + '/js/a.js';
      var s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(wzrk, s);
    })();
  },

  event: function (eventName, eventData) {
    eventData &&
      window.clevertap &&
      window.clevertap.event.push(eventName, eventData);
  },

  registerUser: function (userData) {
    window.clevertap.profile.push(userData);
  },
};

export default CleverTap;
