import { useLayoutEffect, useState, useCallback } from 'react';
import { scrollToTop, scrollToBottom } from '../utils/postUtil';
import { useMediaQuery } from '@material-ui/core';

function useScrollToTop(shouldScroll = true) {
  useLayoutEffect(() => {
    shouldScroll && scrollToTop();
  }, [shouldScroll]);
}
function useScrollToBottom(shouldScroll = true) {
  useLayoutEffect(() => {
    shouldScroll && scrollToBottom();
  }, [shouldScroll]);
}
function useMobile() {
  return useMediaQuery('(max-width:480px)');
}
function useTablet() {
  return useMediaQuery('(max-width:1024px)');
}

function useDimensions() {
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const ref = useCallback((node) => {
    if (node !== null) {
      setWidth(Math.floor(node.getBoundingClientRect().width));
      setHeight(Math.floor(node.getBoundingClientRect().height));
    }
  }, []);
  return { width, height, ref };
}

export {
  useScrollToBottom,
  useScrollToTop,
  useMobile,
  useTablet,
  useDimensions,
};
