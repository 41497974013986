import React, { useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, TextField } from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../img/search-white.svg';
import CancelIcon from '@material-ui/icons/Cancel';
import { useTablet } from '../../hooks/utilHooks';

const SearchBar = ({
  handleSubmit,
  searchValue: defaultValue,
  fixRight = false,
}) => {
  const classes = useStyles({ fixRight });
  const isTablet = useTablet();
  const ref = useRef();
  if (ref.current && defaultValue) ref.current.value = defaultValue;
  return (
    <React.Fragment>
      <form
        onSubmit={(e) => {
          ref.current.blur();
          e.preventDefault();
          handleSubmit(ref.current.value);
        }}
      >
        <Box className={classes.main} width={isTablet ? 0.75 : 0.43}>
          <SearchIcon />
          <TextField
            type="text"
            inputRef={ref}
            defaultValue={defaultValue}
            placeholder="Search"
            // autoFocus
            className={classes.inputSet}
          />
          {defaultValue && defaultValue.length > 0 && (
            <CancelIcon
              className={classes.closeIcon}
              onClick={(e) => {
                ref.current.value = '';
                ref.current.blur();
                handleSubmit(null);
              }}
            />
          )}
        </Box>
      </form>
    </React.Fragment>
  );
};

export default SearchBar;

const useStyles = makeStyles((theme) => ({
  main: {
    height: '28px',
    background: 'rgba(0,0,0,0.3)',
    position: (props) => (props.fixRight ? null : 'fixed'),
    width: (props) => (props.fixRight ? '180px' : null),
    left: theme.spacing(0),
    right: theme.spacing(0),
    top: theme.spacing(3.0),
    zIndex: '5000',
    marginLeft: 'auto',
    marginRight: 'auto',
    padding: theme.spacing(0, 2),
    borderRadius: theme.spacing(2.5),
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  inputSet: {
    color: 'white',
    width: '100%',
    padding: theme.spacing(0, 2),
    '& div': {
      color: 'white',
      height: theme.spacing(10),
      '&:before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
    },
  },
  closeIcon: {
    color: 'white',
    width: '20px',
  },
}));
