import React, { useEffect } from 'react';
import useReactRouter from 'use-react-router';
import { Box, Grid } from '@material-ui/core';
import auth from '../services/authService';
import { useNavBar } from '../hooks/navBarHooks';
import { urlMap } from '../utils/constants';
import { useScreenOpenTracker } from '../hooks/analyticsHook';
import { useTablet } from '../hooks/utilHooks';
import { getQueryParam } from '../utils/routeUtils';
import CommunitiesInterests from '../components/home/communitiesInterests';
import Footer from '../components/home/footer';
import AppDownload from '../components/home/appDownload';
import Testimonial from '../components/home/testimonial';
import SignUpCommunitiesCard from '../components/home/signUpCommunitiesCard';
import SocialSignUp from '../components/home/socialSignUp';
import womenGroup from '../img/home/women-group.svg';
import { signUpType } from '../utils/homeUtils';
import Header from '../components/home/header';

const Home = () => {
  useNavBar({
    hideNavBar: true,
    disableCustomSideBarGrid: true,
  });

  const { history } = useReactRouter();
  const isTablet = useTablet();

  useScreenOpenTracker({
    screenName: 'Login Screen',
  });

  useEffect(() => {
    //Set redirect url to be used after signup
    const redirectUrl = getQueryParam('redirectUrl');
    if (redirectUrl) {
      auth.setReferrerUrl(redirectUrl);
    }
  }, []);

  if (auth.getCurrentUser()) {
    history.push(urlMap.FEED);
    return null;
  }

  return (
    <React.Fragment>
      <Header />
      <Box bgcolor="white" mt={17}>
        <Box className={isTablet ? 'width100' : 'mxAuto homeContainer'}>
          <Grid container>
            <Grid item xs={12} sm={7} md={6}>
              <SocialSignUp history={history} />
            </Grid>

            <Grid item xs={12} sm={5} md={6}>
              <Box align="center" mt={10} px={4}>
                <img src={womenGroup} alt="Women Group" width="100%" />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <CommunitiesInterests />

        <Box className={isTablet ? 'width100' : 'mxAuto homeContainer'}>
          <Box pt={10} px={isTablet ? 0 : 9} align="center">
            {signUpType &&
              signUpType.map((item, index) => (
                <SignUpCommunitiesCard
                  key={index}
                  image={item.imgUrl}
                  text={item.text}
                  buttonText={item.buttonText}
                  redirectUrl={item.redirectUrl}
                />
              ))}
          </Box>
        </Box>

        <Testimonial />
        <AppDownload />
        <Footer />
      </Box>
    </React.Fragment>
  );
};
export default Home;
