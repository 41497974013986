import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import FacebookLoginButton from '../common/facebookLoginButton';
import GoogleLoginButton from '../common/googleLoginButton';
import { useTablet } from '../../hooks/utilHooks';
import Loader from '../common/loader';

const SocialSignUp = ({ history }) => {
  const classes = useStyles();
  const isTablet = useTablet();

  const [isLoading, setIsLoading] = useState(false);

  return (
    <React.Fragment>
      <Box align={isTablet ? 'center' : 'left'}>
        {isLoading && (
          <Box
            bgcolor="rgba(0,0,0,0.4)"
            position="fixed"
            top="0"
            height={1}
            zIndex="1"
            left="0"
            width={isTablet ? '100%' : '100%'}
          >
            <Box position="relative" top="25%">
              <Loader key={1} />
            </Box>
          </Box>
        )}
        <Box
          className={isTablet ? classes.topHeading : classes.isDesktopHeading}
          pt={10}
          pb={7}
        >
          <Typography>Join the largest</Typography>
          <Typography>social network for women</Typography>
        </Box>
        <FacebookLoginButton history={history} setIsLoading={setIsLoading} />
        <GoogleLoginButton history={history} setIsLoading={setIsLoading} />
      </Box>
    </React.Fragment>
  );
};
export default SocialSignUp;

const useStyles = makeStyles((theme) => ({
  topHeading: {
    '& p': {
      fontSize: '22px',
      letterSpacing: '0.5px',
      lineHeight: '30px',
      fontWeight: 'bold',
    },
  },
  isDesktopHeading: {
    '& p': {
      fontSize: theme.spacing(9),
      letterSpacing: '0.5px',
      lineHeight: '48px',
      fontWeight: '600',
    },
  },
}));
