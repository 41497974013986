import apiUrl from '../utils/apiUrl';
import thirdPartyService from './thirdPartyService';
const branchKey = process.env.REACT_APP_BRANCH_KEY;

async function parseBranchLink(link) {
  const branchGetApi =
    apiUrl.GET_BRANCH_LINK_DATA + link + '&branch_key=' + branchKey;
  const { data } = await thirdPartyService.get(branchGetApi);
  return data;
}
export default { parseBranchLink };
