import React, { useState } from 'react';
import { Box, IconButton, Menu, MenuItem } from '@material-ui/core';
import { ReactComponent as MenuVertIcon } from '../../img/menu-kebab.svg';

const ActionToggle = ({ options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 40;

  function handleClick(event) {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  }
  function handleClose(e) {
    e.stopPropagation();
    setAnchorEl();
  }
  function handleCloseMenu(e) {
    e.stopPropagation();
    setAnchorEl();
  }
  return (
    <div>
      <IconButton
        aria-label="More"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MenuVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleCloseMenu}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: 'auto',
          },
        }}
      >
        <Box>
          {options &&
            options.map((option) => (
              <Box key={option.name}>
                {option && option.access && (
                  <MenuItem onClick={(e) => handleClose(e, option)}>
                    <Box width={1} onClick={option.action}>
                      {option.name}
                    </Box>
                  </MenuItem>
                )}
              </Box>
            ))}
        </Box>
      </Menu>
    </div>
  );
};

export default ActionToggle;
