import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import {
  getLinkOrPath,
  isRouteSupported,
  isBranchLink,
} from '../../utils/routeUtils';
import branchService from '../../services/branchService';
import useReactRouter from 'use-react-router';
import AdapterLink from './adapterLink';

const AnyLink = ({ to, children, className }) => {
  const classes = useStyles();
  const url = getLinkOrPath(to);
  const { history } = useReactRouter();
  const isBranchUrl = isBranchLink(to);

  async function handleBranchLink(url, e) {
    e.stopPropagation();
    const { data } = await branchService.parseBranchLink(url);
    if (data && data.deep_link_url) {
      const path = getLinkOrPath(data.deep_link_url);
      isRouteSupported(path)
        ? history.push(path)
        : window.open(data.deep_link_url, '_blank');
    }
  }

  const isInternal = isRouteSupported(url);
  return (
    <React.Fragment>
      {isInternal && !isBranchUrl && (
        <Box
          onClick={(e) => {
            e.stopPropagation();
          }}
          className={`${'blackLink'} ${className}`}
          to={url}
          component={AdapterLink}
        >
          {children}
        </Box>
      )}
      {isBranchUrl && (
        <Box className={className} onClick={(e) => handleBranchLink(url, e)}>
          {children}
        </Box>
      )}
      {!isInternal && !isBranchUrl && (
        <a
          onClick={(e) => {
            e.stopPropagation();
          }}
          rel="noopener noreferrer"
          target="_blank"
          className={classes.linkSet}
          href={url}
        >
          {children}
        </a>
      )}
    </React.Fragment>
  );
};
export default AnyLink;
const useStyles = makeStyles((theme) => ({
  linkSet: {
    color: theme.palette.link.main,
    textDecoration: 'none',
    '&:hover': { textDecoration: 'none' },
  },
}));
