import googleAnalytics from 'react-ga';
import authService from './authService';
import CleverTap from './clevertapService';
import { eventNames, eventProps } from '../utils/eventConstants';
import { default as _values } from 'lodash/values';
import logService from './logService';
import * as Sentry from '@sentry/browser';

const analytics = {
  registerUser: (userData, signupType) => {
    const clevertapUserObj = {
      Site: {
        Name: userData.name, // User's name
        Email: userData.emailId,
        Identity: userData.id,
        'Signup Type': signupType,
        'User Type': userData.userType,
        'User Id': userData.id,
        'Date of Birth': userData.dateOfBirth,
        'Created Date': userData.createdDate,
        'Mobile Number': userData.mobileNumber,
      },
    };
    CleverTap.registerUser(clevertapUserObj);
  },

  track: (eventName, eventData) => {
    if (validateEvent(eventName, eventData)) {
      CleverTap.event(eventName, eventData);
    }
  },

  pageView: (pathname) => {
    const user = authService.getCurrentUser();
    googleAnalytics.set({ userId: user ? user.id : 'Not_logged_in' });
    googleAnalytics.pageview(pathname);
  },
  reset: () => {
    Sentry.setUser(null);
  },
};

//Validate whether the eventName & eventData arguments are valid screen names/properties.
const validateEvent = (eventName, eventData) => {
  //Iterate over the values of eventNames obj (constant) and check whether the given input is valid.
  const isEventNameIncorrect = _values(eventNames).indexOf(eventName) < 0;
  let arePropsIncorrect = false;
  //Iterate over given eventData props & check if they do exist in the list of eventProps obj (constant).
  for (let prop in eventData) {
    //Iterate over the values of eventProps obj & check if the prop name is valid.
    if (_values(eventProps).indexOf(prop) < 0) {
      arePropsIncorrect = true;
      break;
    }
  }

  if (isEventNameIncorrect || arePropsIncorrect) {
    //If either of them are invalid, stop firing event & log into Sentry.
    const error = new Error('Event tracking FAILED');
    logService.error(error, {
      eventName,
      eventData,
    });
    return false;
  }

  return true;
};

export default analytics;
