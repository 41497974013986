import { onboardingSteps } from '../utils/constants';

const userGenderKey = 'userGender';
const onboardingStatusKey = 'onboardingStatus';
const completedStepKey = 'onboardingCompletedTillStep';

export function setOnboardingStatus(isOnboardingDone) {
  localStorage.setItem(onboardingStatusKey, isOnboardingDone);
  if (!isOnboardingDone) {
    localStorage.setItem(completedStepKey, -1);
  }
}

export function getOnboardingStatus() {
  try {
    const onboardingStr = localStorage.getItem(onboardingStatusKey);
    return JSON.parse(onboardingStr);
  } catch (ex) {
    return null;
  }
}
export function setUserGender(response) {
  localStorage.setItem(userGenderKey, response);
}
export function getUserGender() {
  const userGender = localStorage.getItem(userGenderKey);
  return userGender;
}

export function getCompletedStepIndex() {
  const completedStep = localStorage.getItem(completedStepKey) || -1;
  return parseInt(completedStep);
}

export function moveToNextStep(history) {
  const completedStep = getCompletedStepIndex() + 1;
  localStorage.setItem(completedStepKey, completedStep);

  const nextStep = completedStep + 1;
  if (nextStep === onboardingSteps.length - 1) {
    setOnboardingStatus(true);
  }
  history.push(onboardingSteps[nextStep]);
  return null;
}

export default {
  setOnboardingStatus,
  getOnboardingStatus,
  setUserGender,
  getUserGender,
  moveToNextStep,
  getCompletedStepIndex,
};
