import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  themeName: 'Sheroes theme',
  spacing: 4,
  palette: {
    background: {
      default: '#f5f5f5',
      secondary: '#f5f5f5',
    },

    primary: {
      main: '#DC4541',
      light: '#FB5555',
    },
    secondary: {
      main: '#878787',
      light: '#6bba28',
    },
    grey: {
      50: '#90949c',
      100: '#dcdcdc',
      200: '#c7c7c7',
      300: '#aaaaaa',
      400: '#878787',
      500: '#3c3c3c',
      600: '#979797',
      700: '#f2f2f2',
      800: '#ececec',
      900: '#f4f4f4',
    },
    link: {
      main: '#2793e7',
    },
    error: {
      main: red.A400,
    },

    text: {
      primary: '#3c3c3c',
      secondary: '#878787',
    },
  },
  typography: {
    fontFamily: 'noto-sans',
    h6: {
      fontSize: 18,
      fontWeight: 600,
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      lineHeight: '22px',
    },
    subtitle2: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '22px',
    },
    body1: {
      fontSize: 14,
      lineHeight: '19px',
    },
    body2: {
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '19px',
    },
    button: {
      fontSize: 16,
      fontWeight: 600,
      textTransform: 'inherit',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      lineHeight: '17px',
    },
    overline: {
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '17px',
      textTransform: 'capitalize',
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application 💣!
      disableTouchRipple: true,
    },
  },
  overrides: {
    MuiCardHeader: {
      avatar: {
        marginRight: '8px',
      },
      root: {
        alignItems: 'flex-start',
        padding: '16px 16px 8px 16px',
      },
    },
    MuiCardContent: {
      root: {
        padding: '0 16px 0px 16px',
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
      },
    },
    MuiDialog: {
      paperFullScreen: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'black',
      },
      paper: {
        margin: '16px',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'rgba(0, 0, 0, 0.77)',
      },
    },
    MuiSwitch: {
      track: {
        backgroundColor: '#dcdcdc',
      },
      switchBase: {
        color: '#dcdcdc',
      },
    },
    MuiTab: {
      wrapper: {
        minHeight: '12px',
      },
      root: {
        minHeight: '24px',
      },
    },
    MuiTabs: {
      scrollButtonsDesktop: {
        color: '#fff',
      },
    },
  },
});

export default theme;
