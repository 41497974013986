import React from 'react';
import { findComponentForRoute } from './routeUtils';

/**
 * Source: https://medium.com/maxime-heckel/react-lazy-a-take-on-preloading-views-cc90be869f14
 * @param {*} importStatement
 */
function lazyPreload(importStatement) {
  const Component = React.lazy(importStatement);
  Component.preload = importStatement;
  return Component;
}

function preloadRouteComponent(path) {
  if (!path) {
    return;
  }
  const component = findComponentForRoute(path);
  if (component && component.preload) {
    component.preload();
  }
}

export { lazyPreload, preloadRouteComponent };
