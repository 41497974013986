import { default as _each } from 'lodash/each';
import { stripDomain } from '../utils/postUtil';
import { hiddenCommunityTabs } from '../utils/constants';
import PostToFollower from '../img/post-to-followers.svg';

function communityCategoryListMap(doc) {
  const lists = doc.solr_ignore_list_of_base_or_participant_model;

  return lists && lists.map((list) => communityItemMap(list));
}
function communityItemMap(item) {
  return {
    id: item.id_of_entity_or_participant,
    thumbnailImageUrl: item.thumbnail_image_url,
    numberOfMembers: item.solr_ignore_no_of_members,
    name: item.name,
    logoAltText: item.logo_alt_text_s,
    description: item.list_short_description,
    isJoined: item.solr_ignore_is_member || item.solr_ignore_is_owner,
    totalNewPostNumber:
      item.solr_ignore_total_no_of_new_posts_after_last_access,
    communitySlug: stripDomain(
      item.solr_ignore_deep_link_url || item.sheroes_deep_link_s
    ),
  };
}

function communityCategoryMap(doc) {
  const endUrl = doc.endPointUrl;
  return {
    title: doc.title,
    endPointUrl: doc.endPointUrl,
    communities: communityCategoryListMap(doc),
    categorySlug: getCategorySlug(endUrl),
  };
}

function getCategorySlug(url) {
  if (!url) return;
  let splitUrl = url.split('=');
  let categoryId = splitUrl[2];
  let categorySlug = '/communities/interests/' + categoryId;
  return categorySlug;
}

function filterTabs(communityTabs) {
  communityTabs = communityTabs.filter((tab) => {
    return !(hiddenCommunityTabs.indexOf(tab.key) > -1);
  });
  return communityTabs;
}

function setCommunityDefaultInfo(communityTabs, defaultTabKey) {
  let defaultActiveTab,
    defaultStreamUrl,
    feedStreamUrl,
    defaultTabIndex,
    feedTabIndex,
    defaultTabType,
    feedTabType,
    communityDescription,
    defaultActiveTabTitle;
  _each(communityTabs, function (tab, index) {
    // IF default tab key exists.
    if (defaultTabKey === tab.key) {
      defaultActiveTab = tab.key;
      defaultActiveTabTitle = tab.title;
      defaultTabIndex = index;
      defaultStreamUrl = tab.dataUrl;
      defaultTabType = tab.type;
    }

    if (tab.key === 'feed') {
      feedStreamUrl = tab.dataUrl;
      feedTabIndex = index;
      feedTabType = tab.type;
    }
    // To set the community description
    if (tab.key === 'about') {
      communityDescription = tab.htmlData;
    }
  });
  // IF default key is undefined, making feed as default tab.
  if (!defaultTabKey) {
    defaultStreamUrl = feedStreamUrl;
    defaultActiveTab = 'feed';
    defaultActiveTabTitle = 'Feed';
    defaultTabIndex = feedTabIndex;
    defaultTabType = feedTabType;
  }
  return {
    defaultStreamUrl,
    defaultTabIndex,
    defaultTabType,
    defaultActiveTab,
    communityDescription,
    defaultActiveTabTitle,
  };
}

function communityDetailMap(community) {
  let communityTabs =
    community.solr_ignore_community_tabs &&
    community.solr_ignore_community_tabs.map(communityTabMap);
  communityTabs = filterTabs(communityTabs);
  const defaultTabKey = community.solr_ignore_default_tab_key;

  return {
    ...setCommunityDefaultInfo(communityTabs, defaultTabKey),
    communityTabs: communityTabs,
    name: community.name,
    isMember: community.solr_ignore_is_member,
    isOwner: community.solr_ignore_is_owner,
    id: community.id_of_entity_or_participant,
    deepLinkUrl: community.solr_ignore_deep_link_url,
    description: community.description,
    subType: community.sub_type,
    isIndexed: community.is_index_b,
    isMemberOrOwner:
      community.solr_ignore_is_member || community.solr_ignore_is_owner,
  };
}

function communityTabMap(tab) {
  return {
    type: tab.type_s,
    key: tab.key_s,
    title: tab.title_s,
    data: tab.data_html_s,
    dataUrl: tab.data_url_s,
    showFab: tab.show_fab_b,
    htmlData: tab.data_html_s,
  };
}
function themeItemMap(item) {
  return {
    id: item.id,
    isDefault: item.is_default,
    themeImageUrl: item.theme_image_url,
    themeTextCharLength: item.theme_text_char_length,
    themeTextColor: item.theme_text_color,
    themeTextLineCount: item.theme_text_line_count,
  };
}
const postToFollowerObj = [
  {
    id: -1,
    name: 'Post to Followers',
    thumbnailImageUrl: PostToFollower,
  },
];

export {
  communityDetailMap,
  communityCategoryMap,
  communityItemMap,
  themeItemMap,
  postToFollowerObj,
};
