import React, { Component } from 'react';
import Editor from 'draft-js-plugins-editor';
import { EditorState, convertFromRaw, ContentState } from 'draft-js';
import userService from '../services/userService';
import createMentionPlugin from 'draft-js-mention-plugin';
import { getCurrentUser } from '../services/authService';
import { createEditorState, stripHtml } from '../utils/editorUtil';

class CustomMentionEditor extends Component {
  constructor(props) {
    super(props);
    props.context === 'POST'
      ? (this.mentionPlugin = createMentionPlugin())
      : (this.mentionPlugin = createMentionPlugin({
          //set Position on add comment
          positionSuggestions: (settings) => {
            return {
              display: 'block',
              transform: ' translateY(0px)', // transition popover on the value of its height
              transition: 'all 0.25s cubic-bezier(0.3, 1.2, 0.2, 1)',
              top: '-250px',
              height: '250px',
              overflowY: 'auto',
            };
          },
        }));
    this.onSearchChange = this.onSearchChange.bind(this);
    this.resetEditor = this.resetEditor.bind(this);
    let editorState;
    //set Editor State when edit
    if (props.isEditable) {
      let description = stripHtml(props.postDescription);
      description = description.replace(/@/g, '');
      description = description.replace(/&nbsp;/g, ' ');
      //create editor state on edit post
      const { blocks, entityMaps, linkMaps } = createEditorState(
        description,
        props.userMentions
      );
      const editorObj = {
        blocks: [...blocks],
        entityMap: [...entityMaps, linkMaps],
      };
      linkMaps && linkMaps.data.url && props.renderLinkUrl(linkMaps.data.url);
      const contentState = convertFromRaw(editorObj);
      editorState = EditorState.createWithContent(contentState);
    } else {
      //set empty editor state when create post
      editorState = EditorState.createEmpty();
    }
    this.state = {
      editorState: editorState,
      suggestions: [],
      currentUserId: '',
    };
  }
  //handle onchange function for getting request text and tagged user
  onChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.handleSetEditorState(editorState, this.props.context);
    if (this.props.isCommentSubmit) {
      this.resetEditor(editorState);
    }
  };
  //Reset editor after create post or comment
  resetEditor = (eState) => {
    const editorState = EditorState.push(
      eState,
      ContentState.createFromText('')
    );
    this.setState({ editorState });
  };
  //handle onSearch Change function and generate user Suggestion
  async onSearchChange({ value }) {
    const reqObj = {
      user_mention_context: this.props.context,
      search_text: value,
      community_id: this.props.communityId,
      post_author_user_id: this.state.currentUserId,
      post_entity_id: this.props.entityId || '',
    };
    const data = await userService.getMentionData(reqObj);
    this.setState({
      suggestions: data.slice(0, 10),
    });
  }
  render() {
    const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [this.mentionPlugin];
    const { placeholder } = this.props;
    if (this.props.isCommentSubmit) {
      this.resetEditor(this.state.editorState);
      this.props.setIsCommentSubmit(false);
    }
    const user = getCurrentUser();
    !this.state.currentUserId && this.setState({ currentUserId: user.id });
    return (
      <React.Fragment>
        <Editor
          editorState={this.state.editorState}
          onChange={this.onChange}
          plugins={plugins}
          ref={(element) => {
            this.editor = element;
          }}
          placeholder={placeholder}
        />
        <MentionSuggestions
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
        />
      </React.Fragment>
    );
  }
}
export default CustomMentionEditor;
