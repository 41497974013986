import React from 'react';
import { Typography, IconButton, Box, makeStyles } from '@material-ui/core';

import CommentItem from './commentItem';
import { ReactComponent as LoadMoreIcon } from '../../img/load-more.svg';
import { pageSize } from '../../utils/constants';
import { useStoreActions, useStoreState } from 'easy-peasy';
const CommentList = React.memo(
  ({
    handleDeleteComment,
    handleLoadMore,
    post,
    isPostDetail,
    isArticleDetail,
  }) => {
    const postComments = useStoreState(
      (state) => state.posts.postsObj[post.id].comments
    ); // store.

    const comments =
      !isPostDetail && !isArticleDetail ? post.lastComment : postComments;

    const setScrollPostId = useStoreActions(
      (actions) => actions.feed.setScrollPostId
    );
    function handleClick(e) {
      e && e.stopPropagation();
      if (!isPostDetail) {
        setScrollPostId(post.id);
      }
    }
    const classes = useStyles();
    const hasMore =
      comments.length > pageSize - 1 && comments.length >= post.commentsCount
        ? false
        : true;
    const shouldTruncateText = !isPostDetail && !isArticleDetail;
    return (
      <Box className={isPostDetail ? classes.minHeightBox : ''}>
        {hasMore &&
          post.commentsCount > pageSize - 1 &&
          (isPostDetail || isArticleDetail) && (
            <Box
              display="flex"
              alignItems="center"
              p={1}
              onClick={handleLoadMore}
            >
              <IconButton>
                <LoadMoreIcon width="24px" heigh="24px" />
              </IconButton>
              <Typography
                variant="body2"
                component="span"
                color="textSecondary"
              >
                Load more comments
              </Typography>
            </Box>
          )}

        {comments &&
          comments.map((comment, index) => (
            <CommentItem
              handleClick={handleClick}
              shouldTruncate={shouldTruncateText}
              handleDeleteComment={handleDeleteComment}
              key={index}
              comment={comment}
              postId={post.id}
              postEntityId={post.parentId}
              post={post}
              commentIndex={index}
              isPostDetail={isPostDetail}
              isArticleDetail={isArticleDetail}
            />
          ))}
      </Box>
    );
  }
);
const useStyles = makeStyles({
  minHeightBox: {
    minHeight: '150px',
  },
});

export default CommentList;
