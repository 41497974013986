import { lazyPreload } from '../utils/lazyUtil';
const Gender = lazyPreload(() => import('../components/onboarding/gender'));
const MaleRestricted = lazyPreload(() =>
  import('../components/onboarding/maleRestricted')
);
const Interest = lazyPreload(() => import('../components/onboarding/interest'));
const Birthday = lazyPreload(() => import('../components/onboarding/birthday'));
const OnboardingRoute = lazyPreload(() =>
  import('../components/common/onboardingRoute')
);

export default [
  {
    route: OnboardingRoute,
    path: '/onboarding/gender',
    component: Gender,
  },
  {
    route: OnboardingRoute,
    path: '/onboarding/interest',
    component: Interest,
  },
  {
    route: OnboardingRoute,
    path: '/onboarding/birthday',
    component: Birthday,
  },
  {
    path: '/onboarding/male-restricted',
    component: MaleRestricted,
  },
];
