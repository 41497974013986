import React from 'react';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { imageUrls } from '../../utils/constants';
import AdapterLink from '../common/adapterLink';

const useStyles = makeStyles((theme) => ({
  smallAvatar: {
    width: 32,
    height: 32,
    borderRadius: theme.spacing(2),
  },
  avtarAlignItems: {
    alignItems: 'inherit',
  },
}));
const UserAvatar = ({ userImgUrl, comment }) => {
  const classes = useStyles();
  return (
    <Avatar
      src={userImgUrl || imageUrls.DEFAULT_USER_IMG}
      className={classes.smallAvatar}
      onClick={(e) => e.stopPropagation()}
      to={(comment && comment.authorDeepLinkUrl) || ''}
      component={AdapterLink}
    />
  );
};

export default UserAvatar;
