import React, { useState } from 'react';
import {
  Dialog,
  // Grid,
  Box,
  RadioGroup,
  Radio,
  FormControl,
  List,
  ListItem,
  DialogTitle,
  Typography,
  FormControlLabel,
} from '@material-ui/core';
import CustomButton from './customButton';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/styles';
import { useStoreState, useStoreActions } from 'easy-peasy';
import postService from '../../services/postService';
import { useSnackBar } from '../../hooks/snackBarHooks';
import { getPostObjForEventTracking } from '../../utils/postUtil';
import analytics from '../../services/analyticsService';
import { eventNames } from '../../utils/eventConstants';

const ReportSpam = () => {
  const classes = useStyles();
  const setToastMessage = useSnackBar('');
  const { user } = useStoreState((state) => state.user);
  const { showSpamModal, spamReasons, contentType, docInfo } = useStoreState(
    (state) => state.reportSpam
  );
  const postObj = useStoreState(
    (state) => state.posts.postsObj[docInfo && docInfo.docId]
  );

  const { updateSpamState } = useStoreActions((actions) => actions.reportSpam);
  const fetchSpamReasons = useStoreActions(
    (actions) => actions.reportSpam.fetchSpamReasons
  );
  spamReasons || fetchSpamReasons();

  const [spamInfo, setSpamInfo] = useState({
    score: 0,
    reason: '',
  });

  function handleChange(reason) {
    setSpamInfo({ reason: reason.reason, score: reason.score });
  }
  async function handleSubmit() {
    const eventPropsObj = postObj && getPostObjForEventTracking(postObj);
    setSpamInfo({ score: 0, reason: '' });
    setToastMessage('Your report has been submitted successfully.');
    try {
      await postService.reportSpam(docInfo, spamInfo, user.id, contentType);

      analytics.track(eventNames.postReported, eventPropsObj);
      updateSpamState({ showSpamModal: false });
    } catch (error) {
      setToastMessage('Please try again after sometime.');
      updateSpamState({ showSpamModal: false });
    }
  }

  return (
    <Dialog
      className={classes.dialogBox}
      disableBackdropClick={false}
      open={showSpamModal}
      onClose={() => {
        updateSpamState({ showSpamModal: false });
      }}
    >
      <Box className={classes.closeDialogBox} justifyContent="flex-end" p={6}>
        <CustomButton
          onClick={() => {
            updateSpamState({ showSpamModal: false });
          }}
          variant="iconButton"
          icon={<CloseIcon style={{ color: 'white', fontSize: '26px' }} />}
        />
      </Box>
      <FormControl component="fieldset">
        <DialogTitle
          className={classes.dialogTitleBox}
          id="simple-dialog-title"
        >
          <Typography variant="body2">Help us Understand</Typography>
          <Typography variant="body1">Why is it inappropriate?</Typography>
        </DialogTitle>
        <Box className={classes.reasonsList}>
          <List>
            {spamReasons &&
              contentType &&
              spamReasons[contentType].map((reason, index) => (
                <ListItem key={index}>
                  <RadioGroup
                    className={classes.group}
                    value={spamInfo.reason}
                    onChange={() => handleChange(reason)}
                  >
                    <FormControlLabel
                      value={reason.reason}
                      control={<Radio color="primary" />}
                      label={reason.label}
                    />
                  </RadioGroup>
                </ListItem>
              ))}
          </List>
        </Box>
        <CustomButton
          fullWidth={true}
          variant="primary"
          onClick={handleSubmit}
          text=" Report"
          size="regular"
          disabled={spamInfo && spamInfo.reason ? false : true}
        ></CustomButton>
      </FormControl>
    </Dialog>
  );
};

const useStyles = makeStyles((theme) => ({
  dialogTitleBox: {
    backgroundColor: theme.palette.grey['700'],
  },
  closeDialogBox: {
    top: '2%',
    right: 0,
    position: 'fixed',
  },
}));

export default ReportSpam;
