import axios from 'axios';
import axiosUtil from '../utils/axiosUtil';

const internalAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
internalAxios.interceptors.response.use(axiosUtil.responseInterceptor);
const selectedLanguage = localStorage.getItem('postLanguage') || 'en';
internalAxios.interceptors.request.use(
  (config) => {
    config.headers.common['X-Language'] = selectedLanguage;
    return config;
  },
  (error) => Promise.reject(error)
);
function setUserToken(token) {
  internalAxios.defaults.headers.common['Authorization'] = token;
}

export default {
  get: internalAxios.get,
  post: internalAxios.post,
  put: internalAxios.put,
  delete: internalAxios.delete,
  setUserToken,
};
