import http from './httpService';
import APIURL from '../utils/apiUrl';
import {
  communityDetailMap,
  communityItemMap,
  communityCategoryMap,
  themeItemMap,
} from '../mappers/communityMapper';

async function getCommunityInterest() {
  const { data } = await http.get(APIURL.GET_COMMUNITY_INTEREST);
  return data.tags;
}
async function joinCommunityInterest(interestTags) {
  const { data } = await http.post(APIURL.JOIN_COMMUNITY_INTEREST, {
    source: 'web',
    tag_ids: interestTags,
    interest_type: 'COMMUNITY_JOIN',
    is_invited_to_join: true,
  });
  return data;
}

async function getCommunityFromServices() {
  const requestBody = { page_no: 1, page_size: 60 };
  const { data } = await http.post(APIURL.MY_COMMUNITIES, requestBody);
  localStorage.setItem(
    'communityList',
    JSON.stringify(data.docs ? data.docs.map(communityItemMap) : [])
  );
  return data.docs.map(communityItemMap);
}

function getCommunityList() {
  return JSON.parse(localStorage.getItem('communityList'));
}

async function getCommunityDetail(communityId) {
  const requestBody = {
    id_of_entity_or_participant: communityId,
    sub_type: 'C',
  };
  const { data } = await http.post(APIURL.GET_POST, requestBody);
  return data.docs && data.docs.length > 0 && communityDetailMap(data.docs[0]);
}
async function getCommunityCategory() {
  const { data } = await http.post(APIURL.COMMUNITY_CATEGORY, {});
  return data.docs.map(communityCategoryMap);
}

async function requestBody(communityId, userId, toJoin) {
  if (toJoin) {
    return {
      apiUrl: APIURL.JOIN_COMMUNITY,
      requestBody: { community_id: communityId, user_ids: [userId] },
    };
  } else {
    return {
      apiUrl: APIURL.UNJOIN_COMMUNITY,
      requestBody: { community_id: communityId, user_id: userId },
    };
  }
}

async function joinCommunity(communityId, userId, toJoin) {
  const reqData = await requestBody(communityId, userId, toJoin);
  const { data } = await http.post(reqData.apiUrl, reqData.requestBody);
  return data;
}

/**
 * Function for set theme object response in local storage
 */
async function getThemesFromServices() {
  const { data } = await http.get(APIURL.GET_ALL_THEME);
  localStorage.setItem('themeList', JSON.stringify(data.theme_object_list));
}
/**
 * Function for get theme object from local storage
 */
function getThemesFromLocalStorage() {
  return JSON.parse(localStorage.getItem('themeList'));
}
/**
 * Function for merge all theme object with selected community theme obj and then return one object
 */
function mergeAllThemesObj(communityId) {
  const selectedThemeObj = getSelectedCommunityThemesObj(communityId);
  const themeObj = getThemesFromLocalStorage();
  const defaultThemeObj = themeObj[0].theme_list[0];
  const commonThemes = getCommonThemesObj();
  var allThemeObj = [];
  allThemeObj.push(defaultThemeObj);
  if (selectedThemeObj) {
    allThemeObj = allThemeObj.concat(selectedThemeObj);
  }
  allThemeObj = allThemeObj.concat(commonThemes);
  return allThemeObj;
}
/**
 * Function get common theme and remove default theme form list
 */
function getCommonThemesObj() {
  const themeObj = getThemesFromLocalStorage();
  themeObj[0].theme_list.splice(0, 1);
  return themeObj[0].theme_list;
}
/**
 *  Function for get theme by community id and return theme object
 * @param {*} communityId
 */
function getSelectedCommunityThemesObj(communityId) {
  const themeObj = getThemesFromLocalStorage();
  if (themeObj) {
    for (let i = 0; i < themeObj.length; i++) {
      if (themeObj[i].community_id === communityId) {
        return themeObj[i].theme_list;
      }
    }
  }
}
/**
 * function for get all theme which community id is '-1'
 * when create post open first time
 */
function getAllThemes() {
  const themObjList = getThemesFromLocalStorage();
  const commonThemeObj = themObjList[0].theme_list.map(themeItemMap);
  return commonThemeObj;
}
/**
 * function for get Theme by community id
 * @param {*} communityId
 */
function getThemesByCommunityId(communityId) {
  const getAllThemeObj = mergeAllThemesObj(communityId);
  return getAllThemeObj.map(themeItemMap);
}

export default {
  getCommunityInterest,
  joinCommunityInterest,
  getCommunityFromServices,
  getCommunityList,
  getCommunityCategory,
  getCommunityDetail,
  joinCommunity,
  getThemesFromServices,
  getThemesByCommunityId,
  getAllThemes,
};
