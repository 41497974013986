import { action, thunk } from 'easy-peasy';
import communityService from '../services/communityService';
import { sharePost } from '../utils/postUtil';

export function initCommunity() {
  return {
    communityDetails: {},
    communityId: '',
    communityName: '',
    activeTab: {},
  };
}
export default {
  communities: {},
  currentCommunity: '',
  createCommunity: action((state, communityId) => {
    state.currentCommunity = communityId;
    if (!state.communities[communityId]) {
      state.communities[communityId] = initCommunity();
    }
  }),
  setCurrentCommunity: action((state, payload) => {
    state.currentCommunity = payload;
  }),
  setTabInfo: action((state, tabInfoObj) => {
    state.communities[state.currentCommunity].activeTab = tabInfoObj;
  }),
  shareCommunity: action((state, payload) => {
    const currentCommunity = state.communities[state.currentCommunity];
    const {
      deepLinkUrl,
      name,
      description,
    } = currentCommunity.communityDetails;
    const { communityId, communityName } = currentCommunity;
    sharePost(undefined, {
      url: deepLinkUrl,
      title: name,
      text: description,
      communityId,
      communityName,
      isCommunity: true,
    });
  }),
  setCommunityDetails: action((state, communityObj) => {
    const currentCommunity = state.communities[state.currentCommunity];
    currentCommunity.communityDetails = communityObj;
    //Store community Id & name as separate fields because these are used in pre-selecting when createpost screen is opened from a community detail screen. As, the communityDetails obj is being reset on unmount to keep it clear on switching from community to community.
    currentCommunity.communityId = communityObj.id;
    currentCommunity.communityName = communityObj.name;
  }),
  fetchCommunityDetails: thunk(async (actions, communityId) => {
    actions.createCommunity(communityId);
    const communityDetails = await communityService.getCommunityDetail(
      communityId
    );
    actions.setCommunityDetails(communityDetails);
    actions.setTabInfo({
      tabKey: communityDetails.defaultActiveTab,
      tabTitle: communityDetails.defaultActiveTabTitle,
      tabIndex: communityDetails.defaultTabIndex,
      dataUrl: communityDetails.defaultStreamUrl,
      dataType: communityDetails.defaultTabType,
      communityDescription: communityDetails.communityDescription,
    });
  }),
};
