import { makeStyles } from '@material-ui/core/styles';

const globalStyles = makeStyles((theme) => ({
  //listing only classes which are very generic and used in more than 3-4 components.
  '@global': {
    '.container': {
      width: '1200px',
      maxWidth: '100%',
      margin: '0px auto',
    },
    '.stickToBottom': {
      position: 'fixed',
      bottom: 0,
      zIndex: 10,
      left: 0,
    },
    '.stickToTop': {
      position: 'fixed',
      top: 0,
      zIndex: 10,
    },
    '.boxShadowBottom': {
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.05)',
    },
    '.wordBreak': {
      wordBreak: 'break-word',
    },
    '.overflowHidden': {
      overflow: 'hidden',
    },
    '.mxAuto': {
      marginLeft: 'auto!important',
      marginRight: 'auto!important',
    },
    '.positionRelative': {
      position: 'relative',
    },
    '.minHeight100': {
      minHeight: '90vh',
      height: '100%',
    },
    '.clearfix': {
      clear: 'both',
    },
    '.communityTabActive': {
      padding: '0 4px!important',
      color: '#dc4541!important',
      height: '24px',
      marginTop: '7px',
    },
    '.removeBorderRadius': {
      borderRadius: 'inherit',
    },
    '.defaultBorder': {
      border: '1px solid #dcdcdc',
    },
    '.removeUnderline': {
      textDecoration: 'none',
    },
    '.floatLeft': {
      float: 'left',
    },
    '.floatRight': {
      float: 'right',
    },
    '.textDecorationHidden': {
      textDecoration: 'none',
    },
    '.blackLink': {
      textDecoration: 'none',
      color: '#3c3c3c',
    },
    '.championIconStyle': {
      position: 'absolute',
      bottom: '0px',
      right: '-4px',
      width: '16px',
      height: '16px',
    },
    '.postShareIcon': {
      position: 'absolute',
      bottom: '0px',
      right: '-4px',
      width: '16px',
      height: '16px',
    },
    '.width100': {
      width: '100%',
    },
    '.homeContainer': {
      width: '72%',
      paddingRight: '16px',
      paddingLeft: '16px',
    },
    '.navProfileImg': {
      width: '28px',
      borderRadius: '50%',
    },
    '.navLink a': {
      color: '#3c3c3c',
      textDecoration: 'none',
      '&:hover': {
        color: '#dc4541',
      },
    },
    '.navLink a.active': {
      color: '#dc4541',
    },
    '.removeOutline': {
      '&:focus': {
        outline: 'none',
      },
    },
    '.displayInlineBlock': {
      display: 'inline-block',
    },
    '.postSingleImg': {
      display: 'block',
      '& img': {
        borderRadius: '12px',
        width: '100%',
        height: '100%',
      },
    },
    '.editor :global(.public-DraftEditor-content)': {
      minHeight: '140px',
    },
    '.bottomNavigationAction': {
      minWidth: '70px',
    },
  },
}));

export default globalStyles;
