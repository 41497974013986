import React, { useState } from 'react';

import { useStoreState, useStoreActions } from 'easy-peasy';
import { Box, Grid } from '@material-ui/core';
import useReactRouter from 'use-react-router';
import { useScrollToBottom, useTablet } from '../../hooks/utilHooks';
import { useLikeOnPost, useRepost } from '../../hooks/postHooks';
import CommentSection from './commentSection';
import authService from '../../services/authService';
import PostEngagementSection from './postEngagementSection';

const PostEngagement = ({
  post,
  focusComment: triggerComment,
  isPostDetail,
  isArticleDetail,
}) => {
  const isTablet = useTablet();
  const userLoginModal = useStoreActions(
    (actions) => actions.user.userLoginModal
  );

  const { history } = useReactRouter();
  const [focusComment, setFocusComment] = useState(triggerComment);

  const [scrollToBottom, setScrollToBottom] = useState(false);
  useScrollToBottom(scrollToBottom);

  const postId = post.id;
  const { isLiked, likesCount, likePost } = useLikeOnPost(postId);
  const { isReposted, repostsCount, repost } = useRepost(postId);

  const commentsCount = useStoreState(
    (state) => state.posts.postsObj[postId].commentsCount
  );
  const stickEngagementDetails = isArticleDetail && !focusComment;
  const setScrollPostId = useStoreActions(
    (actions) => actions.feed.setScrollPostId
  );

  const user = authService.getCurrentUser();
  const refUrl = window.location.href;
  async function handleLike(e) {
    if (!user) {
      userLoginModal({ showModal: true, referrerUrl: refUrl });
    }
    e.stopPropagation();
    likePost({
      postId: post.id,
      postEntityId: post.parentId,
      likeStatus: !isLiked,
    });
  }

  async function handleRepost(e) {
    if (!user) {
      userLoginModal({ showModal: true, referrerUrl: refUrl });
    }
    e.stopPropagation();
    repost({
      postId: post.id,
      postEntityId: post.parentId,
      repostStatus: !isReposted,
    });
  }

  function handleCommentClick(e, focusComment = true) {
    if (!user) {
      userLoginModal({ showModal: true, referrerUrl: refUrl });
    }
    e.stopPropagation(); //to prevent going into detail screen if clicked from feed.
    if (!isPostDetail && !isArticleDetail) {
      setScrollPostId(post.id);
      history.push(
        { pathname: post.slug, focusComment: focusComment },
        { postId }
      );
    } else if (isArticleDetail) {
      setFocusComment(focusComment);
      setScrollToBottom(true);
    }
  }

  return (
    <React.Fragment>
      <Box
        width="1200px"
        maxWidth={1}
        className={stickEngagementDetails ? 'stickToBottom' : ''}
        left="auto!important"
      >
        <Grid>
          <Grid
            item
            sm={stickEngagementDetails ? 6 : 12}
            md={stickEngagementDetails ? 6 : 12}
            lg={stickEngagementDetails ? 6 : 12}
            className={!isArticleDetail ? 'mxAuto' : ''}
          >
            <Box bgcolor="white" mr={!isTablet && isArticleDetail ? 12 : ''}>
              <PostEngagementSection
                handleLike={handleLike}
                focusComment={focusComment}
                handleRepost={handleRepost}
                likesCount={likesCount}
                commentsCount={commentsCount}
                repostsCount={repostsCount}
                isLiked={isLiked}
                isReposted={isReposted}
                post={post}
                isPostDetail={isPostDetail}
                isArticleDetail={isArticleDetail}
                handleCommentClick={handleCommentClick}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <CommentSection
        isPostDetail={isPostDetail}
        focusComment={focusComment}
        post={post}
        isArticleDetail={isArticleDetail}
        setScrollToBottom={setScrollToBottom}
        handleCommentClick={handleCommentClick}
      />
    </React.Fragment>
  );
};

export default PostEngagement;
